import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SlugService {
  slugify(value: string): string {
    return value.replace(/\s+/g, '-').toLowerCase();
  }

  getSuburbFromSlug(value: string): string {
    if (value) {
      return value
        .replace(/-[0-9]+/g, '')
        .split('-')
        .map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    }
    return '';
  }

  getPostalCodeFromSlug(value: string | undefined): string {
    if (value) {
      const slugValues = value.replace(/-[a-z]+/g, '').split('-');
      return slugValues[slugValues.length - 1];
    }
  }
}
