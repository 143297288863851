import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Supplier as SupplierModel } from '@core/types/suppliers/supplier.model';

@Component({
  selector: 'apd-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss'],
})
export class SuppliersComponent {
  @Input() header: string;
  @Input() type: string;
  @Input() backgroundColor: string;
  @Input() display: boolean;
  @Input() buttonText: string;
  @Input() random?: boolean = false;
  @Input() focused?: boolean = false;
  @Input() noMargin?: boolean = false;
  @Input() hideControls?: boolean = false;
  @Input() overWriteStyle: CSSStyleDeclaration;
  @Input() suppliers: Array<SupplierModel>;

  @Output() buttonToggle = new EventEmitter<void>();
  @Output() buttonToggleFocus = new EventEmitter<void>();

  getProfileLink(supplier: SupplierModel): string {
    if (supplier.slug) {
      return `/industry-profile-details/${supplier.slug.name}`;
    }
    return '';
  }
}
