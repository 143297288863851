import { Injectable } from '@angular/core';

import { Content } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  getSeedNumber(): number {
    const length = 6;
    return Math.floor(
      Math.pow(10, length - 1) +
        Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
    );
  }
  shuffle(data: Array<Content>): Array<Content> {
    let currentIndex = data.length;
    let randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [data[currentIndex], data[randomIndex]] = [
        data[randomIndex],
        data[currentIndex],
      ];
    }
    return data;
  }
  isInternationalRoute(url: string): boolean {
    return url.includes('/international/');
  }
}
