import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { Country } from 'ngx-intl-tel-input/lib/model/country.model';

import { CustomGTMService } from '@core/helpers';
import {
  ArticleService,
  LeadService,
  LocalStorageService,
} from '@core/services';
import { SmsService } from '@core/services/modules/sms.service';
import {
  ApiResponse,
  Article as ArticleModel,
  ArticleQuery,
  Development,
  Enquirer,
  Properties,
} from '@core/types';
import { PriceRange } from '@core/types/common/price-range.model';
import { Dialog } from '@core/types/dialog/dialog.model';
import { SendSmsResponse } from '@core/types/sms/sendSmsResponse.model';
import { Sms } from '@core/types/sms/sms.model';

import { Constants } from '@common/constants';

import { DefaultSubmitDialogComponent } from './submit-dialog/default-submit-dialog.component';

@Component({
  selector: 'apd-default-lead-form',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultLeadFormsComponent implements OnInit, DoCheck {
  @ViewChild('form') form: ElementRef;

  @Input() enquirer: Enquirer;
  @Input() development: Development;
  @Input() property: Properties;
  @Input() brochureEnquiry: boolean;

  @Output() formSubmitted = new EventEmitter<boolean>();
  hideFields = false;
  isLoading = false;
  separateDialCode = true;
  addMessage = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Australia];
  isArchived = false;
  isBCI = false;
  isVerified: boolean;
  mobilePlaceholder: string;
  articles: Array<ArticleModel> = [];
  sms: Sms = {
    mobile: '',
    developmentId: '',
    email: '',
    countryCode: '',
    hasCookie: false,
  };
  dialogData: Dialog = {
    nearByDevelopments: [],
    isArchived: false,
    showRelated: '',
    articles: [],
  };

  articleParams: ArticleQuery = {
    images: true,
    tags: true,
    slugs: true,
    categories: true,
    status: 'published',
    limit: 3,
    category: 'lifestyle',
  };

  leadForm = this.formBuilder.group({
    scheduleInspection: [false],
    requestFloorPlans: [false],
    infomationKit: [false],
    priceInformation: [false],
    brochure: [false],
    similarProperties: [false],
    splash: [''],
    message: [''],
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    mobile: ['', [Validators.required, this.mobileValidator]],
    postCode: ['', [Validators.required]],
    audience: [''],
    lookingToBuy: [''],
    priceRange: [''],
    contactMethod: [''],
    selectPreApproval: [''],
  });

  audienceOptions: Array<string> = Constants.audienceOptions;
  priceRangeOptions: Array<PriceRange> = [...Constants.priceRangeOptions];
  buyingDurationOptions: Array<string> = Constants.buyingDurationOptions;
  preferredContactMethods: Array<string> = Constants.preferredContactMethods;
  preApprovalOption: Array<string> = Constants.preApprovalOption;

  developmentPhone: string;
  showPhoneNumber = false;
  selectRequired = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private SmsService: SmsService,
    private leadService: LeadService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private gtmService: CustomGTMService,
    private articleService: ArticleService,
    public dialog: MatDialog,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.leadForm.patchValue(this.enquirer);
    this.enquirer.development = this.development;
    this.isArchived = this.development.status == 'archived' ? true : false;
    this.isBCI = this.development.discr == 'bci' ? true : false;
    this.handleConfig();
    this.getArticles();
    this.filterPriceRangeOptions();
    this.mobilePlaceholder = '4xx xxx xxx';
    this.hideOptionalContnet();
  }

  onCountryChange(event: Country): void {
    this.mobilePlaceholder =
      event.dialCode && event.dialCode !== '61'
        ? 'Enter phone number'
        : '4xx xxx xxx';
  }
  getArticles(): void {
    const showRelated = this.development?.details?.enquiryConfig?.show_related;
    if (showRelated === '0') {
      this.articleService
        .getArticles(this.articleParams)
        .subscribe((res: ApiResponse) => {
          this.articles = res.data.articles;
        });
    }
  }

  hideOptionalContnet(): void {
    if (this.development?.details?.enquiryConfig?.hide_optional_fields == '1') {
      if (
        this.development?.details?.enquiryConfig?.all_fields_required == '1'
      ) {
        this.hideFields = false;
      } else {
        this.hideFields = true;
      }
    }
  }

  handleConfig(): void {
    const config = this.development?.details?.enquiryConfig;
    if (config && !this.brochureEnquiry) {
      if (config?.all_fields_required == '1') {
        this.selectRequired = true;
        this.leadForm = this.formBuilder.group({
          scheduleInspection: [false],
          requestFloorPlans: [false],
          infomationKit: [false],
          priceInformation: [false],
          brochure: [false],
          similarProperties: [false],
          splash: [''],
          message: [''],
          name: ['', Validators.required],
          email: ['', [Validators.required, Validators.email]],
          mobile: ['', [Validators.required]],
          postCode: ['', [Validators.required]],
          audience: ['', [Validators.required]],
          lookingToBuy: ['', [Validators.required]],
          priceRange: ['', [Validators.required]],
          contactMethod: [''],
          selectPreApproval: [''],
        });
      }
    }
  }

  ngDoCheck(): void {
    if (this.property) {
      this.enquireProperties(this.property);
    }

    if (this.enquirer.brochure) {
      this.leadForm.get('brochure').patchValue(true);
      this.enquirer.brochure = false;
    }

    if (this.enquirer.infomationKit) {
      this.leadForm.get('infomationKit').patchValue(true);
      this.enquirer.infomationKit = false;
    }
    if (this.enquirer.scheduleInspection) {
      this.leadForm.get('scheduleInspection').patchValue(true);
      this.enquirer.scheduleInspection = false;
    }

    this.enquirer.development = this.development;
  }

  submit(): void {
    if (this.leadForm.invalid) return;
    this.isLoading = true;
    this.mapFormDataToEnquirer();
    this.enquirer.mobile = this.mobile.value.e164Number;
    this.localStorageService.setEnquirerDetails(this.enquirer);
    this.sms = this.constructSmsObject();
    this.localStorageService.setSendSmsPayload(this.sms);
    this.sendLead();
  }
  sendLead() {
    this.leadService
      .sendEnquiry(this.enquirer, null, true)
      .subscribe(response => {
        this.isLoading = false;
        this.gtmService.pushTag(
          this.development,
          this.development.classification.toLowerCase()
        );
        this.sendSms(response);
      });
  }

  sendSms(response: ApiResponse) {
    this.sms.hasCookie = this.cookieService.check('ad-otp-form-cookie');
    this.SmsService.sendOTP(this.sms).subscribe(res => {
      const smsResponse = res.data as SendSmsResponse;
      this.isVerified = smsResponse.verified;
      if (this.isVerified) {
        this.openSubmitDialog(response.data);
        return;
      }
      this.dialogData = this.constructDialogObject(response);
      this.localStorageService.setDialogData(this.dialogData);
      const expiryTime = res.success ? smsResponse.expires_at : '';
      this.localStorageService.setExpiryTime(expiryTime);
      this.formSubmitted.emit(this.isVerified);
    });
  }

  openSubmitDialog(nearByDevelopments: Array<Development>): void {
    this.formSubmitted.emit(this.isVerified);
    this.dialog.open(DefaultSubmitDialogComponent, {
      data: {
        nearByDevelopments: nearByDevelopments,
        isArchived: this.isArchived,
        showRelated: this.development?.details?.enquiryConfig?.show_related,
        articles: this.articles,
      },
    });
  }

  enquireProperties(property: Properties): void {
    const message = `Enquiry about '${property.title}' at ${this.development.title}`,
      formMessage = this.message.value;

    this.enquirer.property = property;

    this.addMessage = true;

    if (formMessage != message) {
      if (!formMessage.includes(message)) {
        this.leadForm.get('message').patchValue(message);
      }
    }
  }

  mapFormDataToEnquirer(): void {
    this.enquirer = {
      ...this.enquirer,
      ...{
        scheduleInspection: this.scheduleInspection.value,
        requestFloorPlans: this.requestFloorPlans.value,
        infomationKit: this.infomationKit.value,
        priceInformation: this.priceInformation.value,
        brochure: this.brochure.value,
        similarProperties: this.similarProperties.value,
        splash: this.splash.value,
        message: this.message.value,
        name: this.name.value,
        email: this.email.value,
        mobile: this.mobile.value,
        postCode: this.postCode.value,
        audience: this.audience.value,
        priceRange: this.priceRange.value,
        lookingToBuy: this.lookingToBuy.value,
        contactMethod: this.contactMethod.value,
        selectPreApproval: this.selectPreApproval.value,
      },
    };
  }

  getPropertyMinPrice(): number {
    return this.development.properties && this.development.properties.length > 0
      ? Math.min(
          ...this.development.properties.map(item =>
            parseInt(item.priceFrom.replace(/,/g, ''))
          )
        )
      : 0;
  }

  filterPriceRangeOptions(): void {
    const customPriceRangeOptions = this.getCustomPriceRangeOptions();

    if (customPriceRangeOptions) {
      return;
    }

    const minPrice =
      this.development.priceSearch && this.development.priceSearch != '0'
        ? parseInt(this.development.priceSearch.replace(/,/g, ''))
        : this.getPropertyMinPrice();

    this.priceRangeOptions = [...Constants.priceRangeOptions];
    this.priceRangeOptions = this.priceRangeOptions.filter(
      option => option.limit > minPrice || option.limit == 0
    );
  }

  getCustomPriceRangeOptions(): Array<PriceRange> | boolean {
    const customPriceRangeOptions: string =
      this.development?.details?.enquiryConfig?.price_range;

    if (!customPriceRangeOptions) {
      return false;
    }

    const customPriceRangeOptionsArray: Array<string> = JSON.parse(
      customPriceRangeOptions
    );

    if (customPriceRangeOptionsArray?.length === 0) {
      return false;
    }

    this.priceRangeOptions.length = 0;
    customPriceRangeOptionsArray.forEach(value => {
      this.priceRangeOptions.push({ value: value, limit: null });
    });

    return true;
  }

  mobileValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const mobileNumber = control.value.internationalNumber;
      if (control.value.countryCode && control.value.countryCode === 'AU') {
        const mobileNumberPattern = /^[4]\d{8}$|^(\+61)[4]\d{8}$/;
        const slicedNumber = mobileNumber.replace(/\D/g, '').slice(2);

        if (!mobileNumberPattern.test(slicedNumber)) {
          return { invalidMobile: true };
        }
      }
      return null;
    }
  }

  private constructSmsObject(): Sms {
    return {
      ...this.sms,
      mobile: this.enquirer.mobile,
      email: this.enquirer.email,
      developmentId: this.development.id,
      countryCode: this.mobile.value.dialCode,
    };
  }
  private constructDialogObject(response: ApiResponse): Dialog {
    return {
      ...this.dialogData,
      nearByDevelopments: response.data,
      isArchived: this.isArchived,
      showRelated: this.development?.details?.enquiryConfig?.show_related,
      articles: this.articles,
    };
  }

  get scheduleInspection(): AbstractControl {
    return this.leadForm.get('scheduleInspection');
  }
  get requestFloorPlans(): AbstractControl {
    return this.leadForm.get('requestFloorPlans');
  }
  get infomationKit(): AbstractControl {
    return this.leadForm.get('infomationKit');
  }
  get priceInformation(): AbstractControl {
    return this.leadForm.get('priceInformation');
  }
  get brochure(): AbstractControl {
    return this.leadForm.get('brochure');
  }
  get similarProperties(): AbstractControl {
    return this.leadForm.get('similarProperties');
  }
  get propertyId(): AbstractControl {
    return this.leadForm.get('propertyId');
  }
  get splash(): AbstractControl {
    return this.leadForm.get('splash');
  }
  get message(): AbstractControl {
    return this.leadForm.get('message');
  }
  get name(): AbstractControl {
    return this.leadForm.get('name');
  }
  get email(): AbstractControl {
    return this.leadForm.get('email');
  }
  get mobile(): AbstractControl {
    return this.leadForm.get('mobile');
  }
  get postCode(): AbstractControl {
    return this.leadForm.get('postCode');
  }
  get audience(): AbstractControl {
    return this.leadForm.get('audience');
  }
  get priceRange(): AbstractControl {
    return this.leadForm.get('priceRange');
  }
  get lookingToBuy(): AbstractControl {
    return this.leadForm.get('lookingToBuy');
  }
  get contactMethod(): AbstractControl {
    return this.leadForm.get('contactMethod');
  }
  get selectPreApproval(): AbstractControl {
    return this.leadForm.get('selectPreApproval');
  }
}
