import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { TabDefaultComponent } from './default.component';

@NgModule({
  declarations: [TabDefaultComponent],
  imports: [CommonModule, MatTabsModule],
  exports: [TabDefaultComponent],
})
export class TabDefaultModule {}
