import { createSelector } from '@ngrx/store';

import { SearchFilter } from '@core/types';

import { AppState } from '@state/app.state';

export const selectSearchFilter = createSelector(
  (state: AppState) => state.searchFilter,
  (searchFilter: SearchFilter) => searchFilter
);
