import { Pipe, PipeTransform } from '@angular/core';

import { StateService } from '@core/helpers';
import { Development } from '@core/types';

@Pipe({
  name: 'developmentAdUrl',
})
export class DevelopmentAdUrlPipe implements PipeTransform {
  constructor(public stateService: StateService) {}

  transform(development: Development, state: string, details = false): string {
    return this.generateDevelopmentLink(development, state, details);
  }

  generateDevelopmentLink(
    //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    development,
    state: string,
    details: boolean
  ): string {
    const shortState = this.stateService.getShortValue(state);
    const stateSlug = this.cleanStateSlug(
      shortState,
      !details ? development.slug : development.address.slug
    );
    let url = '/new-apartments-developments/' + shortState + '/' + stateSlug;

    if (details) {
      url += '/' + development.slug.name;
    }

    return url;
  }

  cleanStateSlug(shortState: string, slug: string): string {
    const needle = '-' + shortState + '-';
    const cleanedSlug = slug.replace(needle, '-');

    return cleanedSlug;
  }
}
