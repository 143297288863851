import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(
    //TODO: explicit-module-boundary-types
    //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    value: any
  ): any {
    const keys: any[] = [];

    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        keys.push({
          key: key,
          value: value[key],
        });
      }
    }

    return keys;
  }
}
