import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daySuffix',
})
export class DaySuffixPipe implements PipeTransform {
  transform(day: string): string {
    const iDay = parseInt(day);
    switch (iDay % 10) {
      case 1:
        return iDay + 'st';
      case 2:
        return iDay + 'nd';
      case 3:
        return iDay + 'rd';
      default:
        return iDay + 'th';
    }
  }
}
