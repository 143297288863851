<section
  class="cta-subscribe position-relative"
  [class]="formLocation"
  *ngIf="!formOnly; else formBlock">
  <img
    [src]="
      cdnBasePath +
        '/highmont/gallery/image/3james-v03-living_small__1200x550.jpg'
        | optimizeImage
    "
    loading="lazy"
    alt="Highmont"
    class="bg--cover" />
  <div class="overlay w-100 h-100 position-relative py-200 px-15 py-sm-60">
    <div class="home-container">
      <h3 class="h2 header" *ngIf="!submitText">
        <ng-template
          [ngIf]="incentivesHeader"
          [ngIfElse]="elseIncentivesBlock"
          >{{ incentivesHeader }}</ng-template
        >
        <ng-template #elseIncentivesBlock>
          {{ 'HOME.TITLES.SUBSCRIPTION.MAIN.DEFAULT' | translate }}
        </ng-template>
      </h3>
      <p *ngIf="!submitText">
        <ng-template
          [ngIf]="incentivesSubHeader"
          [ngIfElse]="elseIncentivesSubBlock"
          >{{ incentivesSubHeader }}</ng-template
        >
        <ng-template #elseIncentivesSubBlock>
          {{ 'HOME.TEXT.PROPERTY_BUYING_DESCRIPTION' | translate }}
        </ng-template>
      </p>
      <h5 class="h4 form-title" *ngIf="!submitText">
        <ng-template
          [ngIf]="subscriptionHeader"
          [ngIfElse]="elseSubscriptionBlock"
          >{{ subscriptionHeader }}</ng-template
        >
        <ng-template #elseSubscriptionBlock>
          {{ 'HOME.HEADER.GET_LATEST_OFFERS' | translate }}
        </ng-template>
      </h5>
      <form
        (submit)="subscribe($event)"
        class="subscribe"
        [class]="formLocation">
        <input
          #email="ngModel"
          [ngClass]="{ error: email.invalid && email.touched }"
          [(ngModel)]="subscriber.email"
          name="email"
          type="email"
          placeholder="{{ '_FORM.PLACEHOLDER.EMAIL_ADDRESS' | translate }}"
          required
          email />
        <input type="submit" value="" [disabled]="email.invalid" />
      </form>
    </div>
  </div>
</section>

<ng-template #formBlock>
  <form
    (submit)="subscribe($event)"
    class="subscribe"
    [class]="formLocation"
    [id]="'newsletter-' + formLocation">
    <input
      #email="ngModel"
      [ngClass]="{ error: email.invalid && email.touched }"
      [(ngModel)]="subscriber.email"
      name="email"
      type="email"
      placeholder="{{ '_FORM.PLACEHOLDER.EMAIL_ADDRESS' | translate }}"
      required
      email />
    <input type="submit" value="{{ submitText }}" [disabled]="email.invalid" />
  </form>
</ng-template>
