<div #searchBar class="search-bar">
  <ng-container *ngIf="showSearchBar && mode != 'simple'">
    <form>
      <input
        #search
        type="text"
        (keyup.enter)="triggerSearch()"
        (focus)="autocomplete()"
        (keyup)="autocomplete()"
        [(ngModel)]="keyword.value"
        class="search"
        placeholder="{{ '_FORM.PLACEHOLDER.SEARCH' | translate }}"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        [ngModelOptions]="{ standalone: true }" />
      <div class="wrapper open-filters">
        <a (click)="toggleSearchFilterPopup()">{{
          '_FORM.FILTERS' | translate
        }}</a>
      </div>
      <div class="wrapper submit">
        <i
          class="search-icon"
          [ngClass]="{ disabled: isSearchButtonDisabled() }"
          (click)="
            isSearchButtonDisabled()
              ? $event.stopPropagation()
              : triggerSearch()
          "></i>
      </div>
      <div
        class="search-drop-down"
        [ngStyle]="{ width: dropdownWidth }"
        *ngIf="searchResults.length > 0">
        <ng-container *ngFor="let results of searchResults">
          <ng-container *ngIf="results.type == 'place'">
            <div (click)="searchPlace(results)" class="search-items">
              <img src="/assets/icons/types/location.svg" />
              <div>
                {{ results.description }}
                <span *ngIf="!isEmpty(results.postalCode)">
                  , {{ results.postalCode }}
                </span>
                <span *ngIf="results.isRegion" class="region-span">
                  - Region
                </span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="results.classification">
            <div class="search-items" (click)="goToProjectLink(results)">
              <img [src]="handleSearchIcon(results.classification)" />
              <div>{{ results.title }}</div>
              <span class="view-project-item">View project</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </ng-container>
  <div class="show-map-container-search" *ngIf="isSearch">
    <apd-map-toggle [isMobileView]="true"></apd-map-toggle>
  </div>
</div>
<apd-search-filter
  (toggleMobileFilter)="toggleMobileFilter.emit($event)"></apd-search-filter>
