
  export const environment = {
    production: true,
    hmr : false,
    ipAddress: "undefined",
    apiBasePath: "https://api.staging.a-d.com.au",
    appBasePath: "https://www.staging.a-d.com.au",
    cdnBasePath: "https://d126h31mg8tzcs.cloudfront.net",
    apiRequestTimeout: 100000,
    whiteLabelConfig: {
      id: "undefined",
      application_key: "undefined"
    },
    GOOGLE_API_CLIENT_ID: "AIzaSyAQR19yYzit_eL6g0j-F7B9I7MblI3AL10",
    GOOGLE_ANALYTICS_TRACKING_CODE: "G-HY05FPWKKC",
    GOOGLE_TAG_MANAGER_ID: "GTM-W8XKDF",
    defaultTemplate: "Showcase-Default",
    trinityAudioSrc: "https://trinitymedia.ai/player/trinity/2900006197/?poweredby=0&&voiceId=en-AU-OliviaNeural&FAB=t&pageURL=$$PAGE_URL$$",
    stateSearchRadius: 700000,
    effiClientId: "undefined",
    effiSrc: "/effi/bUjnlE1AER8l5mahFBl9np6vQpvtqFvJoKZoUAhBZdLk6DwAIouDYDXOwG3dRAwQ/build",
    bci:  undefined
  };
