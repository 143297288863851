<a
  mat-stroked-button
  [id]="id"
  [fxFlex.md]="mdFlex"
  [fxFlex.sm]="smFlex"
  [fxFlex.xs]="xsFlex"
  [ngClass]="{
    defaultClass: defaultClass,
    fullWidth: fullWidth,
    fullHeight: fullHeight,
    hidden: hidden
  }"
  [ngClass.md]="mdClass"
  [ngClass.sm]="smClass"
  [ngClass.xs]="xsClass"
  [disabled]="disabled"
  [hidden]="hidden"
  (click)="onClick()"
  [href]="customHref">
  {{ label }}
</a>
