import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringShortTime',
})
export class StringShortTimePipe implements PipeTransform {
  transform(time: string): Date {
    const timeTokens = time.split(':');
    const newDate = new Date(
      2000,
      0,
      1,
      parseInt(timeTokens[0]),
      parseInt(timeTokens[1])
    );

    console.log(newDate);
    return newDate;
  }
}
