import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CookieService } from 'ngx-cookie-service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { DefaultLeadFormsComponent } from '@core/layout/components/lead-forms/default/default.component';
import { DefaultSubmitDialogComponent } from '@core/layout/components/lead-forms/default/submit-dialog/default-submit-dialog.component';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [DefaultLeadFormsComponent, DefaultSubmitDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatInputModule,

    NgxIntlTelInputModule,
    SharedModule,
  ],
  providers: [CookieService],
  exports: [DefaultLeadFormsComponent],
})
export class DefaultLeadFormsModule {}
