import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { environment } from 'environments/environment';

import { StateService } from '@core/helpers';
import {
  AdministrativeArea,
  Article,
  ArticleItem,
  CategoryCode,
  CategoryCodeSet,
  Country,
  DevelopmentItem,
  Development as DevelopmentModel,
  GenericStructuredData,
  GeoCoordinates,
  ItemList,
  ListItem,
  PostalAddress,
  SearchResultsPage,
  Supplier,
} from '@core/types';
import {
  Author,
  ContainedInPlace,
  FAQItems,
  FAQListItems,
  HomePageStructuredData,
  Image,
  MainEntity,
  NewsArticle,
  Property,
  Publisher,
  TabLabel,
} from '@core/types/seo/structured-data.model';

import { DevelopmentAdUrlPipe } from '@shared/pipes';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  scriptType = 'application/ld+json';
  scriptClassName = 'structured-data';
  scriptBodyClassName = 'structured-body-data';

  searchResultsPage: SearchResultsPage = {
    '@context': 'https://schema.org',
    '@type': 'SearchResultsPage',
    mainEntity: [],
  };

  itemList: ItemList = {
    '@type': 'ItemList',
    name: '',
    itemListOrder: 'https://schema.org/ItemListUnordered',
    itemListElement: [],
  };
  properties: [];

  country: Country = {
    '@type': 'Country',
    name: '',
  };

  administrativeArea: AdministrativeArea = {
    '@type': 'AdministrativeArea',
    name: '',
  };

  postalAddress: PostalAddress = {
    '@type': 'PostalAddress',
    addressLocality: '',
    streetAddress: '',
    addressRegion: '',
    postalCode: '',
    addressCountry: '',
    areaServed: null,
  };

  geoLocation: GeoCoordinates = {
    '@type': 'GeoCoordinates',
    latitude: '',
    longitude: '',
  };

  articleItem: ArticleItem = {
    '@type': 'Article',
    name: '',
    url: '',
    identifier: '',
    description: '',
    headline: '',
  };
  categoryCodeSet: CategoryCodeSet = {
    '@context': 'https://schema.org',
    '@type': 'CategoryCodeSet',
    name: '',
    url: '',
    description: '',
    identifier: '',
    mainEntity: [],
  };

  categoryCodeItem: CategoryCode = {
    '@type': 'CategoryCode',
    inCodeSet: '',
    name: '',
    codeValue: '',
    url: '',
  };

  genericStructuredData: GenericStructuredData = {
    '@context': 'https://schema.org',
    '@type': '',
    name: '',
    url: '',
    identifier: '',
    description: '',
  };

  homePageStructuredData: HomePageStructuredData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: '',
    url: '',
    alternateName: '',
    logo: '',
    sameAs: [],
  };
  isBrowser = false;
  isServer = false;
  containsProperty: Array<Property>;

  /**
   * Constructor
   *
   * @param {Meta} meta
   */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private metaService: Meta,
    private titleService: Title,
    private stateService: StateService,
    private developmentAdUrlPipe: DevelopmentAdUrlPipe,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.isServer = isPlatformServer(platformId);
  }

  setTitle(title?: string): void {
    this.titleService.setTitle(title);
    this.setOgMeta('title', title);
    this.setTwitterMeta('title', title);
  }

  setDescription(description?: string): void {
    this.setMeta('description', description);
    this.setOgMeta('description', description);
    this.setTwitterMeta('description', description);
  }

  setMeta(metaName: string, metaContent: string): void {
    this.metaService.updateTag({ name: metaName, content: metaContent });
  }

  setOgMeta(propertyName: string, metaContent: string): void {
    this.metaService.updateTag({
      property: 'og:' + propertyName,
      content: metaContent,
    });
  }

  setTwitterMeta(metaName: string, metaContent: string): void {
    this.metaService.updateTag({
      name: 'twitter:' + metaName,
      content: metaContent,
    });
  }

  setGenericStructuredData(
    type: string,
    name: string,
    url: string,
    description?: string,
    image?: string
  ): void {
    this.genericStructuredData = {
      ...this.genericStructuredData,
      '@type': type,
      name: name,
      url: url,
      identifier: url,
    };

    if (description) {
      this.genericStructuredData.description = description;
    }

    if (image) {
      this.genericStructuredData.image = image;
    }

    this.insertStructuredDataOnHead(this.genericStructuredData);
  }

  setHomePageStructuredData(
    name: string,
    url: string,
    alternateName?: string,
    logo?: string,
    sameAs?: Array<string>
  ): void {
    this.homePageStructuredData = {
      ...this.homePageStructuredData,
      name: name,
      url: url,
    };

    if (alternateName) {
      this.homePageStructuredData.alternateName = alternateName;
    }

    if (logo) {
      this.homePageStructuredData.logo = logo;
    }
    if (sameAs) {
      this.homePageStructuredData.sameAs = sameAs;
    }

    this.insertStructuredDataOnHead(this.homePageStructuredData);
  }

  setDevelopmentSearchStructuredData(
    developments: Array<DevelopmentModel>
  ): void {
    developments.slice(0, 2).forEach((development, index) => {
      const developmentItem =
        this.processDevelopmentStructuredData(development);
      const developmentListItem: ListItem = {
        '@type': 'ListItem',
        position: '' + (index + 1),
        item: [developmentItem],
      };

      this.itemList.itemListElement.push(developmentListItem);
    });

    this.searchResultsPage.mainEntity.push(this.itemList);

    this.insertStructuredDataOnHead(this.searchResultsPage);
  }

  setCanonicalUrl(development: DevelopmentModel): void {
    const head = this._document.head;
    const developmentUrl =
      this._document.location.origin +
      '/new-apartments-developments/' +
      development.address.shortenState.toLowerCase() +
      '/' +
      development.address.slug +
      '/' +
      development.slug.name;

    const existingLink = this._document.querySelector<HTMLLinkElement>(
      'link[rel="canonical"]'
    );

    if (existingLink) {
      head.removeChild(existingLink);
    }
    if (developmentUrl !== '') {
      const link = this._document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', developmentUrl);
      head.appendChild(link);
    }
  }

  removeCanonicalLink(): void {
    let existingLink = this._document.querySelector<HTMLLinkElement>(
      'link[rel="canonical"]'
    );
    if (existingLink) {
      this._document.head.removeChild(existingLink);
      existingLink = null;
    }
  }

  setProjectMetaData(development: DevelopmentModel): void {
    const {
      title,
      galleries,
      details: { items },
      address: { area, state, thoroughfare, thoroughfareNumber, postalCode },
    } = development;

    const url = this._document.defaultView.location.href;
    const shortenState = this.setShortenState(state);
    const titlePart = title ? title : '';
    const thoroughfareNumberPart = thoroughfareNumber ? thoroughfareNumber : '';
    const thoroughfarePart = thoroughfare ? thoroughfare : '';
    const areaPart = area ? area : '';
    const imageUrl = galleries.length > 0 ? galleries[0].url : '';
    const tabTitle = `${titlePart}, ${
      thoroughfare ? `${thoroughfareNumberPart} ${thoroughfare}, ` : ''
    } ${areaPart} | ${shortenState}${items ? ` - ${items} Units` : ''}`;
    this.setTitle(tabTitle);

    const defaultDescription = `${title}, ${
      thoroughfare ? `${thoroughfareNumberPart} ${thoroughfarePart}, ` : ''
    }${area}, ${state} ${postalCode}. Get Floorplans and Brochure - New Developments offer Off-The-Plan Homes for Sale.`;
    const description = this.processDevelopmentMetaDescription(
      development,
      defaultDescription
    );

    if (imageUrl) {
      this.setOgMeta('image', imageUrl);
      this.setTwitterMeta('image', imageUrl);
    }

    this.setOgMeta('type', 'website');
    this.setOgMeta('url', url);
    this.setDescription(description);
    this.setDevelopmentStructuredData(development);
    this.processFAQData(development);
  }

  setShortenState(state: string): string {
    switch (state) {
      case 'New South Wales':
        return 'NSW';
      case 'Victoria':
        return 'VIC';
      case 'Queensland':
        return 'QLD';
      case 'South Australia':
        return 'SA';
      case 'Australian Capital Territory':
        return 'ACT';
      default:
        return state;
    }
  }

  setDevelopmentStructuredData(development: DevelopmentModel): void {
    const developmentItem = this.processDevelopmentStructuredData(development);

    this.insertStructuredDataOnHead(developmentItem);
  }

  processDevelopmentStructuredData(
    development: DevelopmentModel
  ): DevelopmentItem {
    const url = this._document.defaultView.location.href,
      developmentAddress = development.address ?? {},
      country: Country = {
        ...this.country,
        name: developmentAddress.country ?? '',
      },
      administrativeArea: AdministrativeArea = {
        ...this.administrativeArea,
        name: developmentAddress.area ?? '',
      },
      address: PostalAddress = {
        ...this.postalAddress,
        addressLocality: developmentAddress.area ?? '',
        streetAddress: [
          development.address.thoroughfare,
          development.address.thoroughfareNumber,
        ]
          .filter(Boolean)
          .join(' '),
        addressRegion: developmentAddress.shortenState ?? '',
        postalCode: developmentAddress.postalCode ?? '',
        addressCountry: country.name ?? '',
        areaServed: administrativeArea,
      },
      geoCoordinates: GeoCoordinates = {
        ...this.geoLocation,
        latitude: developmentAddress.latitude ?? '',
        longitude: developmentAddress.longitude ?? '',
      },
      containedInPlace: ContainedInPlace = {
        '@type': 'Place',
        name: development.title ?? '',
      },
      containsPlace: Array<Property> = this.addPropertyMeta(development),
      developmentItem: DevelopmentItem = {
        '@context': 'https://schema.org',
        '@type': development.classification
          ? development.classification.slice(0, -1) + 'Complex'
          : 'ApartmentComplex',
        name: development.title ?? '',
        url: url ?? '',
        image: development.image ?? '',
        description: this.processDevelopmentMetaDescription(development) ?? '',
        numberOfAccommodationUnits: development.totalProperties ?? 0,
        address: address,
        geo: geoCoordinates,
        containedInPlace: containedInPlace,
        containsPlace: containsPlace,
      };
    return developmentItem;
  }

  processFAQData(development: DevelopmentModel): void {
    const faqItems: FAQItems = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: this.processFAQuestions(development),
    };
    this.insertStructuredDataOnBody(faqItems);
  }

  processFAQuestions(development: DevelopmentModel): FAQListItems[] {
    const faqData: FAQListItems[] = [];
    const faqMappings = [
      {
        condition: development?.address ?? false,
        question: `What is the development address of ${
          development?.title ?? 'the development'
        }?`,
        answer: `${development?.title ?? 'The development'} is located at ${
          development?.address?.thoroughfare
            ? `${
                development?.address?.thoroughfareNumber
                  ? development?.address?.thoroughfareNumber
                  : ''
              } ${development?.address?.thoroughfare}, `
            : ''
        }${development?.address?.area ?? ''}, ${
          development?.address?.state ?? ''
        } ${development?.address?.postalCode ?? ''}`,
      },
      {
        condition:
          (development?.displaySuite?.address &&
            development?.displaySuite?.address?.area &&
            development?.displaySuite?.address?.state &&
            development?.displaySuite?.address?.postalCode) ??
          false,
        question: `What is the display suite location of ${
          development?.title ?? 'the development'
        }?`,
        answer: `The display suite for ${
          development?.title ?? 'the development'
        } is located at ${
          development?.displaySuite?.address?.thoroughfare
            ? `${
                development?.displaySuite?.address?.thoroughfareNumber
                  ? development?.displaySuite?.address?.thoroughfareNumber
                  : ''
              } ${development?.displaySuite?.address?.thoroughfare}, `
            : ''
        }${development?.displaySuite?.address?.area ?? ''}, ${
          development?.displaySuite?.address?.state ?? ''
        } ${development?.displaySuite?.address?.postalCode ?? ''}`,
      },
      {
        condition: development?.details?.items ?? false,
        question: `How many properties are there within ${
          development?.title ?? 'the development'
        }?`,
        answer: `There are ${development?.details?.items ?? ''} properties in ${
          development?.title ?? 'the development'
        }`,
      },
      {
        condition: development?.details?.completionDate ?? false,
        question: `When is the expected completion date of ${
          development?.title ?? 'the development'
        }?`,
        answer: `${
          development?.title ?? 'The development'
        } is expected to be completed in ${
          development?.details?.completionDate ?? ''
        }`,
      },
      {
        condition: development?.owner?.businessName ?? false,
        question: `Who is the ${development?.owner?.type ?? 'owner'} for ${
          development?.title ?? 'the development'
        }?`,
        answer: `${development?.owner?.businessName ?? ''} is the ${
          development?.owner?.type ?? 'owner'
        } for ${development?.title ?? 'the development'}`,
      },
      {
        condition:
          development?.information?.priceFrom &&
          development?.information?.priceFrom !== '0' &&
          development?.information?.priceTo &&
          development?.information?.priceTo !== '0',
        question: `What is the price range of properties in ${
          development?.title ?? 'the development'
        }?`,
        answer: `Properties in ${
          development?.title ?? 'the development'
        } range from $${development?.information?.priceFrom} to $${
          development?.information?.priceTo
        }`,
      },
    ];

    faqMappings.forEach(mapping => {
      if (mapping.condition) {
        faqData.push({
          '@type': 'Question',
          name: mapping.question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: mapping.answer,
          },
        });
      }
    });

    return faqData;
  }

  addPropertyMeta(development: DevelopmentModel): Array<Property> {
    const containsProperty: Array<Property> = [];

    if (
      !development ||
      !development.properties ||
      !Array.isArray(development.properties)
    ) {
      return containsProperty;
    }

    development.properties.forEach(unit => {
      const property: Property = {
        '@type': development.classification
          ? `${development.classification.slice(0, -1)}`
          : 'Apartment',
        name: `${unit.title}`,
        description: `${unit.description?.textProfile ?? ''}`,
        floorSize: unit.areaTotal ? `${unit.areaTotal}m²` : '',
        numberOfBedrooms: `${unit.bedrooms}`,
        numberOfBathroomsTotal: `${unit.bathrooms}`,
      };

      containsProperty.push(property);
    });

    return containsProperty;
  }

  processDevelopmentMetaDescription(
    development: DevelopmentModel,
    defaultDescription = ''
  ): string {
    const {
      priceSearch,
      classification,
      title,
      address: { area, state, thoroughfare, postalCode },
    } = development;

    let description =
      defaultDescription === ''
        ? `${title},${thoroughfare ? `${thoroughfare}, ` : ''}${area ?? ''}, ${
            state ?? ''
          } ${
            postalCode ?? ''
          }. Get Floorplans and Brochure - ${this.setClassificationForMeta(
            classification
          )} Units for Sale.`
        : defaultDescription;

    if (this.showStat(priceSearch)) {
      const updatedDescription = description.substring(
        0,
        description.length - 1
      );
      const descriptionAffix =
        defaultDescription === ''
          ? ' Investment Property & New Development.'
          : '';
      description =
        updatedDescription + ` from $${priceSearch}.` + descriptionAffix;
    }

    return description;
  }

  setClassificationForMeta(classification: string): string {
    if (classification === 'New Land Estates') {
      return 'New Land Estate';
    }
    if (classification === 'Townhouses') {
      return 'Townhouse';
    }
    if (classification === 'Apartments') {
      return 'Apartment';
    }
    if (classification === 'Penthouses') {
      return 'Penthouse';
    }
    if (classification === 'Prestige Homes') {
      return 'Prestige Homes';
    }
    if (classification === 'Villas') {
      return 'Villas';
    }
    return classification;
  }

  setClassificationForTitle(classification: string, title: string): string {
    if (
      title.toLowerCase().includes('apartment') ||
      title.toLowerCase().includes('apartments') ||
      title.toLowerCase().includes('townhouse') ||
      title.toLowerCase().includes('townhouses') ||
      title.toLowerCase().includes('townhomes') ||
      title.toLowerCase().includes('townhome')
    ) {
      return '';
    }
    return `${classification}`;
  }

  getMaxRooms(development: DevelopmentModel, attribute: string): number {
    let maxRoomCount = 0;

    if (development.properties) {
      let roomCountArray = development.properties.map(property => {
        return property[attribute] ? parseInt(property[attribute]) : 0;
      });

      roomCountArray = [...new Set(roomCountArray)];
      roomCountArray = roomCountArray.sort((a, b) => a - b);
      const lastCount = roomCountArray.pop();

      maxRoomCount = lastCount;
    }

    return maxRoomCount;
  }

  showStat(val: string): boolean {
    return val && val != '0';
  }

  removeStructuredData(): void {
    const els = [];

    ['structured-data'].forEach(c => {
      els.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });

    els.forEach(el => this._document.head.removeChild(el));

    this.resetStructuredDataAttributes();
  }

  removeStructuredBodyData(): void {
    const els = [];

    ['structured-body-data'].forEach(c => {
      els.push(...Array.from(this._document.body.getElementsByClassName(c)));
    });

    els.forEach(el => this._document.body.removeChild(el));
  }

  resetStructuredDataAttributes(): void {
    this.searchResultsPage.mainEntity = [];
    this.itemList.itemListElement = [];
  }

  insertStructuredDataOnHead(
    structuredData:
      | GenericStructuredData
      | SearchResultsPage
      | DevelopmentItem
      | ArticleItem
      | CategoryCodeSet
      | HomePageStructuredData
      | NewsArticle
  ): void {
    let script;
    let shouldAppend = false;

    if (!this.isServer) {
      if (
        this._document.head.getElementsByClassName(this.scriptClassName).length
      ) {
        script = this._document.head.getElementsByClassName(
          this.scriptClassName
        )[0];
      } else {
        script = this._document.createElement('script');
        shouldAppend = true;
      }

      script.setAttribute('class', this.scriptClassName);
      script.type = this.scriptType;
      script.text = JSON.stringify(structuredData);

      if (shouldAppend) {
        this._document.head.appendChild(script);
      }
    }
  }

  insertStructuredDataOnBody(structuredData: FAQItems): void {
    if (!this.isServer) {
      let script;
      let shouldAppend = false;

      if (
        this._document.body.getElementsByClassName(this.scriptBodyClassName)
          .length
      ) {
        script = this._document.body.getElementsByClassName(
          this.scriptBodyClassName
        )[0];
      } else {
        script = this._document.createElement('script');
        shouldAppend = true;
      }

      script.setAttribute('class', this.scriptBodyClassName);
      script.type = this.scriptType;
      script.text = JSON.stringify(structuredData);

      if (shouldAppend) {
        this._document.body.appendChild(script);
      }
    }
  }

  async setArticleDetails(article: Article): Promise<void> {
    if (article) {
      const {
        slug: { pageTitle },
        title,
        seoAttributes,
        images,
      } = article;

      const metaTitle = pageTitle ?? title;

      const articleSeoAttributes = seoAttributes.filter(function (attribute) {
        return attribute.type == 'meta';
      })[0];
      const description = articleSeoAttributes
        ? articleSeoAttributes.content ?? title
        : title;

      const metaImage = images && images.length > 0 ? images[0].url : '';
      const url = this._document.defaultView.location.href;
      let imgHeight: number,
        imgWidth: number,
        logoHeight: number,
        logoWidth: number;
      this.setTitle(metaTitle);
      this.setDescription(description);
      this.setOgMeta('type', 'article');
      this.setOgMeta('image', metaImage);
      this.setOgMeta('url', url);
      const adLogo = `${environment.cdnBasePath}/assets/images/logo-apartments_developments-black.svg`;
      if (metaImage) {
        [imgHeight, imgWidth] = await this.setDimensions(metaImage);
      }
      if (adLogo) {
        [logoHeight, logoWidth] = await this.setDimensions(adLogo);
      }

      const image: Image = {
        '@type': 'ImageObject',
        url: metaImage ?? '',
        height: imgHeight ?? 0,
        width: imgWidth ?? 0,
      };
      const logo: Image = {
        '@type': 'ImageObject',
        url: adLogo ?? '',
        height: String(logoHeight) ?? '0',
        width: String(logoWidth) ?? '0',
      };
      const publisher: Publisher = {
        '@type': 'Organization',
        '@id': 'a-d.com.au',
        name: 'Apartments & Developments',
        logo: logo,
      };
      const author: Author = {
        '@type': 'Organization',
        url: window.location.origin ?? '',
        name: 'Apartments & Developments',
      };

      const mainEntity: MainEntity = {
        '@type': 'WebPage',
        '@id': url,
      };

      const newsArticle: NewsArticle = {
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        mainEntityOfPage: mainEntity,
        headline: metaTitle,
        datePublished: this.convertDateFormat(article.publishedAtDatetime),
        dateModified: this.convertDateFormat(article.updatedAt),
        description: description ?? '',
        author: author,
        publisher: publisher,
        image: image,
      };

      this.insertStructuredDataOnHead(newsArticle);
    }
  }

  convertDateFormat(inputDate: string): string {
    if (!inputDate) {
      return '';
    }
    const date = new Date(inputDate);
    if (isNaN(date.getTime())) {
      return '';
    }

    const isoString = date.toISOString();
    const formattedString = isoString.split('.')[0] + 'Z';

    return formattedString;
  }

  setIndustryProfileDetailsMetaData(supplier: Supplier): void {
    const { businessName, type, listingText } = supplier;

    // TO-DO: Get exact Title and Description to be used for Supplier Details
    const title =
        businessName +
        ' - ' +
        type[0].toUpperCase() +
        type.slice(1) +
        ' Profiles - Apartments & Developments',
      description =
        'Learn about the Property Industry by browsing our Agent Profiles. View latest off-the-plan developments and property investment information.',
      url = this._document.defaultView.location.href.toLowerCase();

    this.setTitle(title);
    this.setDescription(description);
    this.setOgMeta('type', 'website');
    this.setOgMeta('url', url);
    this.setGenericStructuredData(
      'RealEstateAgent',
      businessName,
      url,
      listingText
    );
  }

  setArticleCategoryDetails(
    name: string,
    categories: Array<TabLabel>,
    articles: Article[]
  ): void {
    const { title, content } = this.setTitleAndContent(name);

    this.setTitle(title);
    this.setDescription(content);
    this.setOgMeta('type', 'WebSite');
    this.setOgMeta('url', window.location.href);

    this.insertStructuredDataOnHead({
      ...this.categoryCodeSet,
      name: name,
      description: content,
      url: window.location.href,
      identifier: window.location.href,
      hasCategoryCode:
        name == 'Buying & Living'
          ? this.setCategoryCodeDetails(categories)
          : [],
      mainEntity: [
        {
          '@type': 'ItemList',
          name: name + ' Articles',
          itemListOrder: 'http://schema.org/ItemListUnordered',
          itemListElement: this.setArticleListMeta(articles),
        },
      ],
    });
  }

  setTitleAndContent(name: string): { title: string; content: string } {
    let title = '';
    let content = '';

    switch (name) {
      case 'All':
        name = 'Buying & Living';
        title = `${name} - Property news, apartment living tips and guides`;
        content =
          'Your ultimate source for property news, apartment living tips, and guides. Browse the latest off-the-plan developments and property investment information.';
        break;

      case 'Industry Leaders':
        title = 'Market Insights from Property Industry Leaders';
        content =
          'Where apartment buyers and investors come for expert insights. Browse the latest Off-the-plan development and Property Insights from Market Leaders here.';
        break;

      case 'Lifestyle':
        title = 'Lifestyle | Apartment trends, living tips and guides';
        content =
          'Explore the latest property trends, apartment living tips, and insightful guides to elevate your lifestyle to new heights.';
        break;

      case 'Guides':
        title = 'Property Tips & Off-the plan-guides';
        content =
          'Discover a wealth of knowledge in our collection of property tips and Off-the-plan guides for investors and buyers.';
        break;

      default:
        title = `${name} - Property news, apartment living tips and guides`;
        content = `Where apartment buyers and investors come for property ${name.toLowerCase()}. Browse the latest off-the-plan developments and property investment information.`;
    }

    return { title, content };
  }

  setCategoryCodeDetails(categories: Array<TabLabel>): CategoryCode[] {
    const categoryList = [];

    categories.forEach(category => {
      if (category.default != 'All') {
        const url = window.location.href.substring(
          0,
          window.location.href.lastIndexOf('/')
        );
        const categoryItem: CategoryCode = {
          ...this.categoryCodeItem,
          inCodeSet: category.categorySlug,
          name: category.default,
          description: `Where apartment buyers and investors come for property ${category.default.toLowerCase()}. Browse the latest off-the-plan developments and property investment information.`,
          codeValue: category.categorySlug,
          url: url + '/' + category.categorySlug,
        };
        categoryList.push(categoryItem);
      }
    });

    return categoryList;
  }

  setArticleListMeta(articles: Array<Article>): Array<ListItem> {
    const entityList = [];

    articles.forEach(item => {
      let url = window.location.href.substring(
        0,
        window.location.href.lastIndexOf('/')
      );
      url = url + '/' + item.category.slug + '/' + item.slug.name;
      const articleItem: ArticleItem = {
        ...this.articleItem,
        '@context': 'http://schema.org',
        name: item.title,
        image: item.images && item.images.length > 0 ? item.images[0].url : '',
        url: url ?? '',
        identifier: url ?? '',
        headline: item.listingText ?? item.title,
        datePublished: item.publishedAt ?? '',
        author: {
          '@type': 'Person',
          url: environment.appBasePath,
          name: 'Apartments & Developments AU',
        },
      };
      entityList.push(articleItem);
    });

    return entityList;
  }

  clearMetaData(): void {
    this.setDescription('');
    this.removeCanonicalLink();
    this.setOgMeta('type', 'website');
    this.setOgMeta('url', '');
    this.setOgMeta('image', '');
    this.setTwitterMeta('image', '');
    this.removeStructuredData();
    this.removeStructuredBodyData();
  }

  getImageDimensions(
    imageUrl: string
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      };

      img.onerror = () => {
        reject(new Error('Could not load image at ' + imageUrl));
      };
    });
  }

  async setDimensions(image: string) {
    try {
      const dimensions = await this.getImageDimensions(image + '?format=webp');
      return [dimensions.height, dimensions.width];
    } catch (err) {
      console.error('Error loading image dimensions:', err);
    }
  }
}
