import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArticleQuery } from '@core/types';
import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class ArticleService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get articles
   *
   * @returns {Observable<ApiResponse>}
   */
  getArticles(params: ArticleQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/articles', httpParams)
      .pipe(map(response => response));
  }

  /**
   * Get article
   *
   * @param {Category} category
   * @param {Slug} slug
   * @returns {Observable<ApiResponse>}
   */
  getArticle(
    category: string,
    slug: string,
    params: ArticleQuery
  ): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get(`/article/${category}/${slug}`, httpParams)
      .pipe(map(response => response));
  }

  /**
   * Get article categories
   *
   * @param string lang
   * @returns {Observable<ApiResponse>}
   */
  getArticleCategories(lang?: string): Observable<ApiResponse> {
    let param = '';
    if (lang) {
      param = `local=${lang}&`;
    }
    return this.apiService
      .get(`/categories?${param}limit=10`)
      .pipe(map(response => response));
  }
}
