import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

import { BehaviorSubject, Observable } from 'rxjs';

import { User } from '../../types/users/user.model';

@Injectable({ providedIn: 'root' })
export class SessionService {
  private currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any) {
    //TODO: Handle any types later
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return !this.currentUserSubject.value
      ? null
      : this.currentUserSubject.value;
  }

  getAuthToken(): string {
    return !this.currentUserValue
      ? null
      : this.currentUserValue.data.access_token;
  }

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveSession(userInfo: any): void {
    this.localStorage.setItem('currentUser', JSON.stringify(userInfo));
    this.currentUserSubject.next(userInfo);
  }

  destroySession(): void {
    this.localStorage.clear();
    this.currentUserSubject.next(null);
  }
}
