<div class="container" id="enquiry_success">
  <button class="close" mat-button [mat-dialog-close]="''" cdkFocusInitial>
    <img
      loading="lazy"
      [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
      alt="Close" />
  </button>
  <div class="heading">
    <h3 id="enquiry_title_success">Thank you for your enquiry.</h3>
    <p *ngIf="showRelated">
      People also enquired about these nearby developments
    </p>
    <p *ngIf="!showRelated">Check out some of our lifestyle articles</p>
  </div>
  <div class="controls d-grid col--3" *ngIf="showRelated">
    <div
      class="card"
      *ngFor="let development of data.nearByDevelopments | slice: 1">
      <a
        [routerLink]="
          '/new-apartments-developments/' +
          (development.address.shortenState | lowercase) +
          '/' +
          development.address.slug +
          '/' +
          development.slug.name
        "
        mat-button
        [mat-dialog-close]="''"
        cdkFocusInitial>
        <div class="card-image-container">
          <ng-template
            [ngIf]="isArray(development.image)"
            [ngIfElse]="elseBlock">
            <img
              [src]="development.image[0].url | optimizeImage"
              [alt]="development.title"
              class="card-image" />
          </ng-template>

          <ng-template #elseBlock>
            <img
              [src]="development.image.url | optimizeImage"
              [alt]="development.title"
              class="card-image" />
          </ng-template>
        </div>
        <div class="card-details">
          <h3 class="card-detail-header">
            {{ development.title }}
          </h3>
          <div class="card-detail-subheader">
            <h3>
              {{
                development.address.area + ', ' + development.address.postalCode
              }}
            </h3>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="controls d-grid col--3" *ngIf="!showRelated">
    <div class="card" *ngFor="let article of data.articles">
      <a
        [routerLink]="
          '/buying-living/' + article.category.slug + '/' + article.slug.name
        "
        mat-button
        [mat-dialog-close]="''"
        cdkFocusInitial>
        <div class="card-image-container">
          <ng-template
            [ngIf]="isArray(article.images)"
            [ngIfElse]="elseArticleBlock">
            <ng-container *ngIf="article.images.length > 0">
              <img
                [src]="article.images[0].url | optimizeImage"
                [alt]="
                  article.images[0].alt ? article.images[0].alt : article.title
                "
                class="card-image" />
            </ng-container>
            <ng-container *ngIf="article.images.length == 0">
              <img
                [src]="
                  cdnBasePath + '/assets/default/default-project.jpg'
                    | optimizeImage
                "
                [alt]="article.title"
                class="card-image" />
            </ng-container>
          </ng-template>

          <ng-template #elseArticleBlock>
            <img
              [src]="
                cdnBasePath + '/assets/default/default-project.jpg'
                  | optimizeImage
              "
              [alt]="article.title"
              class="card-image" />
          </ng-template>
        </div>
        <div class="card-details">
          <h3 class="card-detail-header">
            {{ article.title }}
          </h3>
          <div class="card-detail-subheader">
            <h3>{{ article.readTime }} read</h3>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
