import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';

import { SessionService } from '../services/session/session.service';

@Injectable()
export class NoAuthGuard implements CanLoad {
  constructor(private sessionService: SessionService) {}

  canLoad(): boolean {
    return (
      this.sessionService.currentUserValue == undefined ||
      this.sessionService.currentUserValue == null
    );
  }
}
