import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Subscriber } from '@core/types';
import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class SubscriptionService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Subscribe User
   *
   * @param string email
   * @returns {Observable<ApiResponse>}
   */
  subscribeUser(subscriber: Subscriber): Observable<ApiResponse> {
    return this.apiService
      .post('/subscribe', subscriber)
      .pipe(map(response => response));
  }
}
