import { TitleCasePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { environment } from 'environments/environment';

import { StateService } from '@core/helpers';
import { LanguageService } from '@core/services';
import { Suburb as SuburbModel } from '@core/types';

@Component({
  selector: 'apd-suburb-profile-details',
  templateUrl: './suburb-profile-details.component.html',
  styleUrls: ['./suburb-profile-details.component.scss'],
})
export class SuburbProfileDetailsComponent implements OnChanges, AfterViewInit {
  @Input() providedSuburb: SuburbModel;

  suburb: SuburbModel;

  cdnBasePath = environment.cdnBasePath;

  currentLang: string;
  showMobileMenu = false;

  readMoreArticle = false;

  /**
   * Constructor
   * @param {ActivatedRoute} route
   * @param {MetaDataService} metaDataService
   * @param {LanguageService} languageService
   * @param {SuburbService} suburbService
   * @param {DevelopmentService} developmentService
   */
  constructor(
    private languageService: LanguageService,
    private stateService: StateService,
    private titleCasePipe: TitleCasePipe
  ) {}

  ngAfterViewInit(): void {
    this.currentLang = this.languageService.getCurrentLang();

    this.suburb = this.providedSuburb;
  }

  ngOnChanges(): void {
    this.suburb = this.providedSuburb;
  }
}
