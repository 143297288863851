const PriceRanges = [
  { option: '$50,000', value: '50000' },
  { option: '$70,000', value: '70000' },
  { option: '$100,000', value: '100000' },
  { option: '$125,000', value: '125000' },
  { option: '$150,000', value: '150000' },
  { option: '$200,000', value: '200000' },
  { option: '$225,000', value: '225000' },
  { option: '$250,000', value: '250000' },
  { option: '$300,000', value: '300000' },
  { option: '$325,000', value: '325000' },
  { option: '$350,000', value: '350000' },
  { option: '$375,000', value: '375000' },
  { option: '$400,000', value: '400000' },
  { option: '$425,000', value: '425000' },
  { option: '$450,000', value: '450000' },
  { option: '$475,000', value: '475000' },
  { option: '$500,000', value: '500000' },
  { option: '$550,000', value: '550000' },
  { option: '$600,000', value: '600000' },
  { option: '$650,000', value: '650000' },
  { option: '$700,000', value: '700000' },
  { option: '$750,000', value: '750000' },
  { option: '$800,000', value: '800000' },
  { option: '$850,000', value: '850000' },
  { option: '$900,000', value: '900000' },
  { option: '$950,000', value: '950000' },
  { option: '$1,000,000', value: '1000000' },
  { option: '$1,100,000', value: '1100000' },
  { option: '$1,200,000', value: '1200000' },
  { option: '$1,300,000', value: '1300000' },
  { option: '$1,400,000', value: '1400000' },
  { option: '$1,500,000', value: '1500000' },
  { option: '$1,600,000', value: '1600000' },
  { option: '$1,700,000', value: '1700000' },
  { option: '$1,800,000', value: '1800000' },
  { option: '$1,900,000', value: '1900000' },
  { option: '$2,000,000', value: '2000000' },
  { option: '$2,250,000', value: '2250000' },
  { option: '$2,500,000', value: '2500000' },
  { option: '$2,750,000', value: '2750000' },
  { option: '$3,000,000', value: '3000000' },
  { option: '$3,250,000', value: '3250000' },
  { option: '$3,500,000', value: '3500000' },
  { option: '$3,750,000', value: '3750000' },
  { option: '$4,000,000', value: '4000000' },
  { option: '$4,250,000', value: '4250000' },
  { option: '$4,500,000', value: '4500000' },
  { option: '$4,750,000', value: '4750000' },
  { option: '$5,000,000', value: '5000000' },
  { option: '$6,000,000', value: '6000000' },
  { option: '$7,000,000', value: '7000000' },
  { option: '$8,000,000', value: '8000000' },
  { option: '$9,000,000', value: '9000000' },
  { option: '$10,000,000', value: '10000000' },
  { option: '$12,000,000', value: '12000000' },
];

export default PriceRanges;
