<div class="scroll-container">
  <button class="scroll-button left" (click)="scrollTabs('left')">&lt;</button>
  <div class="tab-wrapper">
    <mat-tab-group
      [selectedIndex]="activeLabel"
      (selectedTabChange)="onTabChange($event)"
      mat-align-tabs="center">
      <mat-tab
        *ngFor="let label of labels; let index = index"
        label="{{ label.translated }}"></mat-tab>
    </mat-tab-group>
  </div>
  <button class="scroll-button right" (click)="scrollTabs('right')">
    &gt;
  </button>
</div>
