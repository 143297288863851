import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayStateTab',
})
export class DisplayStateTabPipe implements PipeTransform {
  transform(state: string, condition: string, fromSearchPage = false): boolean {
    let result = true;

    if (fromSearchPage) {
      result = state == condition;
    }

    return result;
  }
}
