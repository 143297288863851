import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class SitemapService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get Apartment Suburbs
   *
   * @returns {Observable<ApiResponse>}
   */
  getDevelopmentSuburbs(classification: string): Observable<ApiResponse> {
    return this.apiService
      .get(`/sitemap/developments/${classification}`)
      .pipe(map(response => response));
  }

  /**
   * Get LandEstates Suburbs
   *
   * @returns {Observable<ApiResponse>}
   */
  getIndustryProfiles(): Observable<ApiResponse> {
    return this.apiService
      .get('/sitemap/industry-profiles')
      .pipe(map(response => response));
  }

  /**
   * Get supplier
   * @param {Supplier} id
   * @returns {Observable<ApiResponse>}
   */
  getIndustryProfile(type: string): Observable<ApiResponse> {
    return this.apiService
      .get(`/sitemap/industry-profile/${type}`)
      .pipe(map(response => response));
  }
}
