<div class="sms-dialog-container">
  <div class="sms-dialog-content">
    <button class="sms-close" (click)="close()">
      <img
        loading="lazy"
        [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
        alt="Close" />
    </button>
    <apd-sms-dialog-form (formSubmitted)="close()"> </apd-sms-dialog-form>
  </div>
</div>
