import { MapsAPILoader } from '@agm/core';
import { isPlatformServer } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SlugService, StateService } from '@core/helpers';
import { MapToggleService } from '@core/helpers/map-toggle.service';
import { SearchFilterComponent } from '@core/layout/components/search-filter/search-filter.component';
import {
  DevelopmentService,
  LocalStorageService,
  SearchService,
} from '@core/services';
import {
  ApiResponse,
  Development as DevelopmentModel,
  DevelopmentSearchQuery,
} from '@core/types';
import { PostalCode } from '@core/types/common';
import { SuburbDefault } from '@core/types/suburbs/suburb.model';

import { AppState } from '@state/app.state';
import { updateKeyword } from '@state/search-keyword/search-keyword.actions';
import { selectSearchKeyword } from '@state/search-keyword/search-keyword.selectors';
import { updateLocation } from '@state/search-location/search-location.actions';

import { Constants } from '@common/constants';

interface Region {
  region: string;
  lat: string;
  long: string;
}

interface PlaceInterface {
  description: string;
  place_id: string;
  terms: Array<{ offset: number; value: string }>;
  type: 'place';
  isRegion: boolean;
  postalCode: string;
}

// TODO: fix no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const google: any;

@Component({
  selector: 'apd-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @ViewChild(SearchFilterComponent) searchFilterNode: SearchFilterComponent;

  @Output() filterEmit = new EventEmitter<void>();
  @Output() toggleMobileFilter = new EventEmitter<boolean>();

  @Input() trackKeyWord = false;
  @Input() mode: 'simple';
  @Input() showSearchBar = true;
  @Input() dropdownWidth = '75%';
  @Input() isSearch = false;

  @HostListener('click')
  clickInside(): void {
    this.isFocusInsideComponent = true;
    this.isComponentClicked = true;
  }

  @HostListener('document:click')
  clickout(): void {
    if (!this.isFocusInsideComponent && this.isComponentClicked) {
      this.isComponentClicked = false;
      if (this.searchResults.length > 0) {
        this.searchResults = [];
      }
    }
    this.isFocusInsideComponent = false;
  }

  keywordTimeout: Subject<void> = new Subject();
  defaultSuburbs: Array<SuburbDefault> = Constants.defaultSuburbs;

  developmentParams: DevelopmentSearchQuery = {
    classifications:
      'apartments,townhouses,new-land-estates,penthouses,prestige-homes,villas',
    address: true,
    slugs: true,
    status: 'published',
    limit: 5,
  };

  searchKeyword$ = this.store.pipe(select(selectSearchKeyword));

  keyword = { value: '' };
  suburbSlug: string;
  postalCode: string;
  stateSlug: string;
  shortenState: string;
  isFocusInsideComponent = false;
  isComponentClicked = false;
  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  location: any;
  isFromSearchPage = false;
  isServer: boolean;
  postalCodes: PostalCode[] = [];
  regionList: Region[] = [];
  isSearching = false;
  isMapShown = true;

  searchResults: Array<PlaceInterface | DevelopmentModel | SuburbDefault> = [];

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private router: Router,
    private mapsApiLoader: MapsAPILoader,
    private ngZone: NgZone,
    private slugService: SlugService,
    private stateService: StateService,
    private developmentService: DevelopmentService,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private searchService: SearchService,
    private mapToggleService: MapToggleService
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  ngOnInit(): void {
    if (!this.isServer) {
      this.mapsApiLoader.load();
    }

    if (this.trackKeyWord) {
      this.searchKeyword$.subscribe(store => (this.keyword.value = store));
    }

    // Get postal codes lookup
    this.searchService.getPostalLookup().subscribe(
      data => (this.postalCodes = data),
      error => console.log(error)
    );

    // Get region list lookup
    this.searchService.getRegionLookup().subscribe(
      data => (this.regionList = data),
      error => console.log(error)
    );

    this.isFromSearchPage = this.router.url.includes(
      '/new-apartments-developments'
    );

    this.keywordTimeout.pipe(debounceTime(250)).subscribe(() => {
      this.placesAutoComplete();
      this.getSearchResults();
    });

    this.mapToggleService.isMapShown$.subscribe(
      isShown => (this.isMapShown = isShown)
    );
  }

  toggleSearchFilterPopup(event: Event = null): void {
    this.searchResults = [];
    return this.searchFilterNode.togglePopup(event);
  }

  triggerSearch(): void {
    if (this.searchResults.length > 0 && this.keyword.value) {
      const searchItem: DevelopmentModel | PlaceInterface | SuburbDefault =
        this.searchResults[0];

      if (this.instanceOfPlaceInterface(searchItem)) {
        this.searchPlace(searchItem);
      } else if (this.instanceOfDevelopmentModel(searchItem)) {
        this.goToProjectLink(searchItem);
      }
    } else {
      this.router.navigate(['/new-apartments-developments']);
    }
  }

  instanceOfPlaceInterface(data): data is PlaceInterface {
    return data.type == 'place';
  }

  instanceOfDevelopmentModel(data): data is DevelopmentModel {
    return 'featuredWeight' in data;
  }

  isSearchButtonDisabled(): boolean {
    return (
      this.searchResults.length === 0 || this.isSearching || !this.keyword.value
    );
  }

  autocomplete(): void {
    if (this.keyword.value) {
      this.keywordTimeout.next();
    } else {
      this.searchResults = this.defaultSuburbs;
    }
  }

  getPostalCodeOnLookup(name: string, state: string): string {
    const index = this.postalCodes.findIndex(
      i => i.name == name && i.state == state
    );
    if (index !== -1) return this.postalCodes[index].postalCode;
    return '';
  }

  checkRegionExists(name: string): boolean {
    return this.regionList.findIndex(i => i.region == name) !== -1;
  }

  placesAutoComplete(): void {
    if (typeof google != 'undefined') {
      const autocomplete = new google.maps.places.AutocompleteService();

      const places: Array<PlaceInterface> = [];
      autocomplete.getPlacePredictions(
        {
          input: this.keyword.value,
          types: ['(cities)'],
          componentRestrictions: { country: 'au' },
        },
        // TODO: fix no-explicit-any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (predictions: any) => {
          if (google.maps.places.PlacesServiceStatus.OK && predictions) {
            this.searchResults = this.searchResults.filter(
              (res: PlaceInterface) => {
                return !res.type;
              }
            );
            predictions.forEach(({ description, place_id, terms, types }) => {
              const hasRegion = this.checkRegionExists(terms[0].value);
              const postalCode =
                terms.length > 0
                  ? this.getPostalCodeOnLookup(terms[0].value, terms[1].value)
                  : '';

              places.push({
                description,
                place_id,
                terms,
                type: 'place',
                isRegion: types.includes('administrative_area_level_1'),
                postalCode: postalCode,
              });

              if (hasRegion) {
                places.push({
                  description,
                  place_id,
                  terms,
                  type: 'place',
                  isRegion: true,
                  postalCode: '',
                });
              }
            });
            this.searchResults = [...places, ...this.searchResults];
          }
        }
      );
    }
  }

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchPlace(location: any): void {
    const geocoder = new google.maps.Geocoder();
    let postalCode = '';

    geocoder.geocode(
      {
        address: location.description,
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          const place = results[0];
          if (place) {
            this.location = place.geometry.location;
            place.address_components.forEach(res => {
              if (res.types.includes('administrative_area_level_1')) {
                this.stateSlug = this.slugService.slugify(res.long_name);
                this.shortenState = this.stateService.getShortValue(
                  this.stateSlug
                );
              }
              if (res.types.includes('postal_code')) {
                postalCode = res.long_name;
              }
              if (
                res.types.includes('colloquial_area') ||
                res.types.includes('locality')
              ) {
                this.suburbSlug = this.slugService.slugify(res.long_name);
                this.keyword.value = res.long_name;
              }
            });

            // Get suburb and shorten state if location is available
            if (location) {
              if (location.terms != undefined && location.terms.length > 0) {
                this.suburbSlug = this.slugService.slugify(
                  location.terms[0].value
                );
                this.shortenState = location.terms[1].value.toLowerCase();
              } else if (location.suburbName) {
                this.suburbSlug = this.slugService.slugify(location.suburbName);
                this.shortenState = location.shortenState;
                this.keyword.value = location.suburbName;
              }
            }

            if (postalCode) {
              this.postalCode = postalCode;
              this.searchRedirect();
            } else {
              geocoder.geocode(
                {
                  address: `${this.location.lat()},${this.location.lng()}`,
                },
                (results, status) => {
                  if (status == google.maps.GeocoderStatus.OK) {
                    if (results.length > 0) {
                      results[0].address_components.forEach(res => {
                        if (res.types.includes('postal_code')) {
                          this.postalCode = res.long_name;
                        }
                      });
                    }
                    this.searchRedirect();
                  }
                }
              );
            }
          }
        }
      }
    );
  }

  getSearchResults(): void {
    if (this.keyword.value) {
      this.isSearching = true;
      this.developmentService
        .developmentSearch({
          search: this.keyword.value,
          ...this.developmentParams,
        })
        .subscribe((response: ApiResponse) => {
          this.searchResults = this.searchResults.filter(
            (res: DevelopmentModel) => {
              return !res.classification;
            }
          );
          const { developments } = response.data;
          this.searchResults = [...this.searchResults, ...developments];

          this.isSearching = false;
        });
    }
  }

  handleSearchIcon(classification: string): string {
    return `/assets/icons/types/${this.slugService.slugify(
      classification
    )}.svg`;
  }

  searchRedirect(): void {
    this.searchResults = [];
    this.store.dispatch(updateKeyword({ searchKeyword: this.keyword.value }));

    if (this.location) {
      const searchLocation = JSON.parse(JSON.stringify(this.location));
      this.store.dispatch(updateLocation({ searchLocation: searchLocation }));
      this.localStorageService.setSearchLocation(searchLocation);
    }

    this.ngZone.run(() =>
      this.router.navigate([
        `/new-apartments-developments/${this.shortenState}/${this.suburbSlug}-${this.postalCode}`,
      ])
    );
  }

  goToProjectLink({ address, slug }: DevelopmentModel): void {
    this.searchResults = [];
    this.ngZone.run(() =>
      this.router.navigate([
        `/new-apartments-developments/${address.shortenState.toLowerCase()}/${
          address.slug
        }/${slug.name}`,
      ])
    );
  }

  isEmpty(postalCode: string): boolean {
    return postalCode == '' || postalCode === undefined;
  }
  showMapToggle(): void {
    this.isMapShown = !this.isMapShown;
    this.mapToggleService.setIsMapShown(this.isMapShown);
  }
}
