import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxPaginationModule } from 'ngx-pagination';

import { PaginatorComponent } from '@core/layout/components/paginator/paginator.component';

@NgModule({
  declarations: [PaginatorComponent],
  imports: [CommonModule, RouterModule, NgxPaginationModule],
  exports: [PaginatorComponent],
})
export class PaginatorModule {}
