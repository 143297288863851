import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@core/services';
import { ApiResponse, PageVisit } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  constructor(private apiService: ApiService, private router: Router) {}

  postPageVisit(session: string, urlPath: string): Observable<ApiResponse> {
    const params: PageVisit = {
      sessionId: session,
      url: urlPath,
    };

    return this.apiService
      .post('/page-visit', params)
      .pipe(map(response => response));
  }
}
