import { Pipe, PipeTransform } from '@angular/core';

import { environment } from 'environments/environment';

import { SlugService } from '@core/helpers';

@Pipe({
  name: 'projectDefaultImage',
})
export class ProjectDefaultImagePipe implements PipeTransform {
  cdnBasePath = environment.cdnBasePath;

  constructor(public slugService: SlugService) {}

  transform(classification: string): string {
    const developmentType = this.slugService.slugify(classification);
    return `${this.cdnBasePath}/assets/default/${developmentType}.jpg`;
  }
}
