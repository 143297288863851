<section
  class="featured"
  [class]="{
    showcase: type == 'showcase',
    search: type == 'search',
    mobile: isMobile
  }">
  <span>
    <strong class="featured-header" *ngIf="type != 'showcase' || isMobile"
      >Developer verified listing.</strong
    >
    All project information, including images, description, pricing and video
    has been verified by the Developer.
  </span>
</section>
