import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { SmsDialogFormModule } from '../sms-dialog-form/sms-dialog-form.module';
import {
  SmsCustomDialogComponent,
  SmsDialogComponent,
} from './sms-dialog.component';

@NgModule({
  declarations: [SmsCustomDialogComponent, SmsDialogComponent],
  imports: [CommonModule, SmsDialogFormModule, SharedModule],
  exports: [SmsCustomDialogComponent],
})
export class SmsDialogModule {}
