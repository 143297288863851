import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MapToggleModule } from '@core/layout/components/map-toggle/map-toggle.module';
import { AnchorStrokedModule } from '@core/layout/components/material/anchors/stroked/stroked.module';

import { SharedModule } from '@shared/shared.module';

import { FilterBarComponent } from './filter-bar.component';

@NgModule({
  declarations: [FilterBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
    SharedModule,
    AnchorStrokedModule,
    MapToggleModule,
  ],
  exports: [FilterBarComponent],
})
export class FilterBarModule {}
