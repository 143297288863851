import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DevelopmentsComponent } from '@core/layout/components/developments/developments.component';
import { DevelopmentFeaturedModule } from '@core/layout/components/featured/featured.module';
import { LeadsDialogModule } from '@core/layout/components/leads-dialog/leads-dialog.module';
import { ButtonDefaultModule } from '@core/layout/components/material/buttons/default/default.module';

import { SharedModule } from '@shared/shared.module';

import { SmsDialogModule } from '../sms-dialog/sms-dialog.module';

@NgModule({
  declarations: [DevelopmentsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
    SharedModule,
    MatDialogModule,
    LeadsDialogModule,
    ButtonDefaultModule,
    DevelopmentFeaturedModule,
    SmsDialogModule,
  ],
  exports: [DevelopmentsComponent],
})
export class DevelopmentsModule {}
