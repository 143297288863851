import { Injectable } from '@angular/core';
import { PRIMARY_OUTLET, Router } from '@angular/router';

import { Constants } from '@common/constants';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  allowedLanguages = Constants.allowedLanguages;

  constructor(private router: Router) {}

  getCurrentLang(): string {
    let langParam = '';
    const tree = this.router.parseUrl(this.router.url);
    const primary = tree.root.children[PRIMARY_OUTLET];

    if (primary) {
      const segment = primary.segments[0].path;
      langParam = this.allowedLanguages.includes(segment) ? `/${segment}` : '';
    }

    return langParam;
  }
}
