import { Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from 'environments/environment';

import { SlugService } from '@core/helpers';
import { Development as DevelopmentModel } from '@core/types';

@Component({
  selector: 'apd-completed-projects',
  templateUrl: './completed-projects.component.html',
  styleUrls: ['./completed-projects.component.scss'],
})
export class CompletedProjectsComponent {
  @Input() header: string;
  @Input() buttonText: string;
  @Input() noTrigger: boolean;
  @Input() developments: Array<DevelopmentModel>;
  @Output() setFilterLimit = new EventEmitter<Event>();

  cdnBasePath = environment.cdnBasePath;

  constructor(private slugService: SlugService) {}

  setDefaultImage(classification: string): string {
    const developmentType = this.slugService.slugify(classification);
    return 'assets/media/ad-group/default-assets/' + developmentType + '.jpg';
  }

  setDevelopmentLink(development: DevelopmentModel): string {
    const { address, slug } = development;
    return `/new-apartments-developments/${address.shortenState.toLowerCase()}/${
      address.slug
    }/${slug.name}`;
  }
}
