/* eslint-disable @typescript-eslint/no-explicit-any */
//TODO: replace "any" with a more specific type
import { isPlatformServer } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

import { Observable, throwError } from 'rxjs';
import { catchError, retry, switchMap, timeout } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {
  isServer: boolean;

  constructor(
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(REQUEST) private httpRequest: any,
    @Inject(PLATFORM_ID) platformId: string,
    private http: HttpClient,
    private tokenService: HttpXsrfTokenExtractor
  ) {
    this.isServer = isPlatformServer(platformId);
  }
  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private formatErrors(error: any) {
    return throwError(error.error);
  }

  setHttpParams(
    //TODO: eslint/ban-types
    //eslint-disable-next-line @typescript-eslint/ban-types
    data: {}
  ) {
    let httpParams = new HttpParams();
    Object.keys(data).forEach(function (key) {
      if (data[key]) {
        httpParams = httpParams.append(key, data[key]);
      }
    });
    return httpParams;
  }

  getTest(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    const options = { params };

    return this.http
      .get(`${environment.apiBasePath}` + `${path}`, options)
      .pipe(
        retry(0),
        timeout(environment.apiRequestTimeout),
        catchError(this.formatErrors)
      );
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    const options = { params };

    return this.http
      .get(`${environment.apiBasePath}` + `${path}`, options)
      .pipe(
        retry(0),
        timeout(environment.apiRequestTimeout),
        catchError(this.formatErrors)
      );
  }

  put(
    path: string,
    //TODO: eslint/ban-types
    //eslint-disable-next-line @typescript-eslint/ban-types
    body: {}
  ): Observable<any> {
    return this.http
      .put(`${environment.apiBasePath}` + `${path}`, JSON.stringify(body))
      .pipe(
        retry(0),
        timeout(environment.apiRequestTimeout),
        catchError(this.formatErrors)
      );
  }

  post(
    path: string,
    //TODO: eslint/ban-types
    //eslint-disable-next-line @typescript-eslint/ban-types
    body: {},
    //eslint-disable-next-line @typescript-eslint/ban-types
    httpHeaders: Record<string, any> = {}
  ): Observable<any> {
    const headers = new HttpHeaders();

    const token = this.tokenService.getToken();

    if (token !== null) {
      headers.set('X-XSRF-TOKEN', token);
    }

    if (Object.keys(httpHeaders).length > 0) {
      for (const [key, value] of Object.entries(httpHeaders)) {
        headers.set(key, value);
      }
    }

    return this.http
      .post(`${environment.apiBasePath}` + `${path}`, JSON.stringify(body), {
        headers,
      })
      .pipe(
        retry(0),
        timeout(environment.apiRequestTimeout),
        catchError(this.formatErrors)
      );
  }

  delete(
    //TODO: explicit-module-boundary-types
    //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    path: any
  ): Observable<any> {
    return this.http
      .delete(`${environment.apiBasePath}` + `${path}`)
      .pipe(
        retry(0),
        timeout(environment.apiRequestTimeout),
        catchError(this.formatErrors)
      );
  }

  csrfPost(
    path: string,
    //TODO: eslint/ban-types
    //eslint-disable-next-line @typescript-eslint/ban-types
    body: {}
  ): Observable<any> {
    return this.getCsrf().pipe(
      switchMap(() => {
        const headers = new HttpHeaders();

        return this.http
          .post(
            `${environment.apiBasePath}` + `${path}`,
            JSON.stringify(body),
            {
              withCredentials: true,
              headers,
            }
          )
          .pipe(retry(0), catchError(this.formatErrors));
      })
    );
  }

  getCsrf(): Observable<any> {
    return this.http
      .get(`${environment.apiBasePath}` + '/sanctum/csrf-cookie', {
        observe: 'response',
        withCredentials: false,
      })
      .pipe(retry(0), catchError(this.formatErrors));
  }

  private getSSRCookie(name: string): string {
    const cookies = {};

    if (this.httpRequest.headers.cookie) {
      const cookieList = this.httpRequest.headers.cookie;

      cookieList.split(';').forEach(cookie => {
        const parts = cookie.split('=');
        const cookieName = parts[0].trim();
        const cookieValue = (parts[1] || '').trim();

        cookies[cookieName] = cookieValue;
      });
    }
    return cookies[name];
  }
}
