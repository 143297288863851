import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RelatedArticlesComponent } from '@core/layout/components/related-articles/related-articles.component';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [RelatedArticlesComponent],
  imports: [
    CommonModule,
    RouterModule,

    TranslateModule.forChild(),
    SharedModule,
  ],
  exports: [RelatedArticlesComponent],
})
export class RelatedArticlesModule {}
