import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RelatedProfilesComponent } from '@core/layout/components/related-profiles/related-profiles.component';

@NgModule({
  declarations: [RelatedProfilesComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
  ],
  exports: [RelatedProfilesComponent],
})
export class RelatedProfilesModule {}
