<section
  fxLayout.xs="column"
  [ngClass]="'home-container py-90'"
  [ngClass.md]="'px-15 py-md-60'"
  [ngClass.sm]="'px-15 py-sm-60'"
  [ngClass.xs]="'px-15 py-sm-60'">
  <div class="home-container">
    <h2 *ngIf="header">{{ header }}</h2>
  </div>

  <ul *ngIf="filter === 'state'" class="result-tabs list-inline mt-20">
    <li [class.active]="filterValue === null">
      <a (click)="setFilter(null)">All</a>
    </li>
    <li [class.active]="filterValue === 'victoria'">
      <a (click)="setFilter('victoria')">VIC</a>
    </li>
    <li [class.active]="filterValue === 'queensland'">
      <a (click)="setFilter('queensland')">QLD</a>
    </li>
    <li [class.active]="filterValue === 'new-south-wales'">
      <a (click)="setFilter('new-south-wales')">NSW</a>
    </li>
    <li [class.active]="filterValue === 'australian-capital-territory'">
      <a (click)="setFilter('australian-capital-territory')">ACT</a>
    </li>
    <li [class.active]="filterValue === 'south-australia'">
      <a (click)="setFilter('south-australia')">SA</a>
    </li>
  </ul>

  <ul *ngIf="filter === 'bedroom'" class="result-tabs list-inline mt-20">
    <li [class.active]="filterValue === 1">
      <a (click)="setFilter(1)">1 Bedroom</a>
    </li>
    <li [class.active]="filterValue === 2">
      <a (click)="setFilter(2)">2 Bedroom</a>
    </li>
    <li [class.active]="filterValue === 3">
      <a (click)="setFilter(3)">3 Bedroom</a>
    </li>
    <li [class.active]="filterValue === 4">
      <a (click)="setFilter(4)">4 Bedroom</a>
    </li>
  </ul>
  <div *ngIf="isLoading" class="development-spinner">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!developments" class="development-no-results">
    <label>Sorry, no results matched.</label>
  </div>
  <div
    *ngIf="!isLoading && developments"
    class="property-results step lg d-grid col--2 col--sm-1 my-60 my-sm-20 pb-40">
    <article
      *ngFor="let development of developments"
      aria-label="{{ development.title }}">
      <a
        [routerLink]="
          development | developmentAdUrl: development.address.state:true
        ">
        <picture class="cover d-block w-101">
          <div class="gallery-card-wrapper">
            <div class="gallery-card">
              <img
                loading="lazy"
                [src]="cdnBasePath + '/assets/icon/photo.svg' | optimizeImage"
                alt="Gallery Icon"
                class="gallery-icon" />
              <span class="image-count">
                {{
                  development.galleries ? development.galleries.length : 0
                }}</span
              >
            </div>
          </div>
          <ng-container *ngIf="development.image">
            <img
              loading="lazy"
              [src]="development.image | optimizeImage"
              class="w-100 h-100 object-fit-cover object-fit-center"
              [alt]="development.title" />
          </ng-container>
          <ng-container *ngIf="development.image === null">
            <img
              loading="lazy"
              [src]="setDefaultImage(development.classification)"
              class="w-100 h-100 object-fit-cover object-fit-center"
              [alt]="development.title" />
          </ng-container>
        </picture>
      </a>
      <div class="title d-grid align-items-center">
        <a
          [routerLink]="
            development | developmentAdUrl: development.address.state:true
          ">
          <h4 class="name">
            {{ development.title }}
          </h4>
        </a>
        <ng-container
          *ngIf="
            showStat(development.priceSearch) &&
            showStat(development.priceDisplay) &&
            !isArchived(development.status)
          ">
          <div
            class="title-price"
            style="
              text-decoration: underline;
              text-underline-offset: 2px;
              direction: rtl;
            ">
            From ${{ development.priceSearch }}
          </div></ng-container
        >
      </div>
      <a
        [routerLink]="
          development | developmentAdUrl: development.address.state:true
        ">
        <div class="details align-items-center">
          <div class="location">
            {{ development.address.area }},
            {{ development.address.state }}
            {{ development.address.postalCode }}
          </div>
          <ul class="configurations list-inline">
            <ng-container *ngIf="checkValue(development.bedrooms)">
              <li aria-label="4 bedrooms">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/icon-bed.svg'"
                  width="35.5"
                  height="20"
                  class="icon"
                  alt="Bed" />
                <span>{{ development.bedrooms }}</span>
              </li>
            </ng-container>
            <ng-container *ngIf="checkValue(development.bathrooms)">
              <li aria-label="2 bathrooms">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/icon-bath.svg'"
                  width="35.5"
                  height="20"
                  class="icon"
                  alt="Bath" />
                <span>{{ development.bathrooms }}</span>
              </li>
            </ng-container>
            <ng-container *ngIf="checkValue(development.carSpaces)">
              <li aria-label="2 car spaces">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/icon-car.svg'"
                  width="35.5"
                  height="20"
                  class="icon"
                  alt="Car" />
                <span>{{ development.carSpaces }}</span>
              </li>
            </ng-container>

            <ng-container *ngIf="checkValue(development.studyroom)">
              <li aria-label="2 car spaces">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/study-room.svg'"
                  width="35.5"
                  height="20"
                  class="icon"
                  alt="Study" />
                <span>{{ development.studyroom }}</span>
              </li>
            </ng-container>
            <ng-container *ngIf="checkValue(development.private_pool)">
              <li aria-label="2 car spaces">
                <img
                  loading="lazy"
                  [src]="cdnBasePath + '/assets/icon/swimming-pool.svg'"
                  width="35.5"
                  height="20"
                  class="icon"
                  alt="Pool" />
                <span>{{ development.private_pool }}</span>
              </li>
            </ng-container>
          </ul>
          <ng-container
            *ngIf="
              showStat(development.priceSearch) &&
              showStat(development.priceDisplay) &&
              !isArchived(development.status)
            ">
            <div
              class="price"
              style="text-decoration: underline; text-underline-offset: 3px">
              From ${{ development.priceSearch }}
            </div>
          </ng-container>
        </div>
      </a>
    </article>
  </div>
  <ng-container *ngIf="!isLoading && developments">
    <div *ngIf="developments.length > 0" class="text-center pt-sm-10">
      <a
        *ngIf="!noTrigger"
        (click)="setFilterLimit.emit()"
        class="btn btn--one btn--lg"
        >{{ buttonText }}</a
      >
    </div>
  </ng-container>
</section>
