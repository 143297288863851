import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SuburbQuery, SuburbSearchQuery } from '@core/types';
import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class SuburbService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get suburbs
   *
   * @returns {Observable<ApiResponse>}
   */
  getSuburbs(params: SuburbQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/suburbs', httpParams)
      .pipe(map(response => response));
  }

  /**
   * Get suburbs
   *
   * @returns {Observable<ApiResponse>}
   */
  getSuburbBySearch(params: SuburbSearchQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/suburbs/search', httpParams)
      .pipe(map(response => response));
  }

  /**
   * Get suburb by state and slug
   */
  getSuburb(
    shortState: string,
    suburbSlug: string,
    params: SuburbQuery
  ): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get(`/suburb/${shortState}/${suburbSlug}`, httpParams)
      .pipe(map(response => response));
  }

  getSuburbByPostalCode(postalCode: string): Observable<ApiResponse> {
    return this.apiService
      .get(`/suburbs/postcode/${postalCode}`)
      .pipe(map(response => response));
  }
}
