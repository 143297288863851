import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'apd-button-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonDefaultComponent {
  // custom props
  @Input() label: string;

  // flexLayout props
  @Input() mdFlex: string;
  @Input() smFlex: string;
  @Input() xsFlex: string;
  @Input() defaultClass: string;
  @Input() mdClass: string;
  @Input() smClass: string;
  @Input() type = 'text';
  @Input() fullWidth = false;
  @Input() fullHeight = false;
  @Input() noLineHeight = false;
  @Input() xsClass: string;

  // material props
  @Input() disabled: boolean;
  @Output() clickEventHandler = new EventEmitter<MouseEvent>();

  onClick($event: MouseEvent): void {
    this.clickEventHandler.emit($event);
  }
}
