import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SupplierQuery } from '@core/types';
import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class SupplierService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get suppliers
   *
   * @returns {Observable<ApiResponse>}
   */
  getSuppliers(params: SupplierQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/suppliers', httpParams)
      .pipe(map(response => response));
  }

  /**
   * Get supplier
   * @param {Supplier} id
   * @returns {Observable<ApiResponse>}
   */
  getSupplier(id: string, params: SupplierQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get(`/supplier/${id}`, httpParams)
      .pipe(map(response => response));
  }
}
