import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CompletedProjectsComponent } from '@core/layout/components/completed-projects/completed-projects.component';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [CompletedProjectsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    SharedModule,
  ],
  exports: [CompletedProjectsComponent],
})
export class CompletedProjectsModule {}
