import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { FuseSharedModule } from './@fuse/shared.module';
import { CalendarDateRangeComponent } from './components/date-range-picker/date-range-picker.component';
import { TableComponent } from './components/table/table.component';
import {
  AddressPipe,
  AdUrlPipe,
  CleanPricePipe,
  DaySuffixPipe,
  DevelopmentAdUrlPipe,
  OptimizeImagePipe,
  ProjectDefaultImagePipe,
  StringShortTimePipe,
  UcFirstPipe,
  UrlEndingPipe,
} from './pipes';

const MaterialModules = [
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatCardModule,
  MatDividerModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatButtonToggleModule,
  MatSelectModule,
  MatTabsModule,
  MatProgressBarModule,
  A11yModule,
  CdkStepperModule,
  CdkTableModule,
];

const pipes = [
  AdUrlPipe,
  DevelopmentAdUrlPipe,
  UcFirstPipe,
  UrlEndingPipe,
  CleanPricePipe,
  DaySuffixPipe,
  ProjectDefaultImagePipe,
  AddressPipe,
  OptimizeImagePipe,
  StringShortTimePipe,
];

@NgModule({
  declarations: [TableComponent, CalendarDateRangeComponent, ...pipes],
  imports: [FuseSharedModule, ...MaterialModules, NgxDaterangepickerMd],
  exports: [TableComponent, CalendarDateRangeComponent, ...pipes],
})
export class SharedModule {}
