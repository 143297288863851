import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

import { DevelopmentsModule } from '@core/layout/components/developments/developments.module';
import { FooterModule } from '@core/layout/components/footer/footer.module';
import { HeaderModule } from '@core/layout/components/header/header.module';
import { RelatedArticlesModule } from '@core/layout/components/related-articles/related-articles.module';
import { SubscribeModule } from '@core/layout/components/subscribe/subscribe.module';

import { SuburbProfileDetailsComponent } from './suburb-profile-details.component';

@NgModule({
  declarations: [SuburbProfileDetailsComponent],
  imports: [
    CommonModule,
    TranslateModule,

    HeaderModule,
    FooterModule,
    DevelopmentsModule,
    RelatedArticlesModule,
    SubscribeModule,

    MatProgressBarModule,
  ],
  exports: [SuburbProfileDetailsComponent],
  providers: [TitleCasePipe],
})
export class SuburbProfileDetailsModule {}
