<div class="leads-dialog-container">
  <div class="leads-dialog-content">
    <button class="leads-close" (click)="dialogRef.close()">
      <img
        loading="lazy"
        [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
        alt="Close" />
    </button>
    <apd-default-lead-form
      (formSubmitted)="close($event)"
      [enquirer]="enquirer"
      [development]="development"
      [property]="property"
      [brochureEnquiry]="brochureEnquiry">
    </apd-default-lead-form>
  </div>
</div>
