import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, IncentiveQuery } from '@core/types';

import { ApiService } from '../api.service';

@Injectable()
export class IncentiveService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get buyer incentives
   *
   * @returns {Observable<ApiResponse>}
   */
  getBuyerIncentives(params: IncentiveQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/developments/incentives', httpParams)
      .pipe(map(response => response));
  }
}
