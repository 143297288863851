import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

import { AnchorStrokedComponent } from './stroked.component';

@NgModule({
  declarations: [AnchorStrokedComponent],
  imports: [FlexLayoutModule, MatButtonModule],
  exports: [AnchorStrokedComponent],
})
export class AnchorStrokedModule {}
