import { createAction, props } from '@ngrx/store';

const UPDATE_KEYWORD = 'UPDATE KEYWORD';
const RESET_KEYWORD = 'RESET KEYWORD';

export const updateKeyword = createAction(
  UPDATE_KEYWORD,
  props<{ searchKeyword: string }>()
);

export const resetKeyword = createAction(RESET_KEYWORD);
