import { createReducer, on } from '@ngrx/store';

import {
  resetMobileFilterToggle,
  updateMobileFilterToggle,
} from './mobile-search-filter.actions';

export const initialState = false;

export const mobileFilterToggleReducer = createReducer(
  initialState,
  on(
    updateMobileFilterToggle,
    (state, { toggleFromMapFilter }) => toggleFromMapFilter
  ),
  on(resetMobileFilterToggle, () => initialState)
);
