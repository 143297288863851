import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private translateService: TranslateService) {}

  ACTION = {
    EDIT: 'edit',
    CREATE: 'create',
    REMOVE: 'remove',
    DENY: 'deny',
  };

  BUTTON_COLOR = {
    BLUE: '#3085d6',
    RED: '#d33',
    GREEN: '#2dba6a',
  };

  generic(text: string, icon = '', timer = 2500): void {
    Swal.fire({
      icon,
      position: 'top-end',
      text,
      showConfirmButton: false,
      timer,
    });
  }

  genericCenter(text: string, icon = '', timer = 2500): void {
    Swal.fire({
      icon,
      position: 'center',
      text,
      showConfirmButton: false,
      timer,
    });
  }
}
