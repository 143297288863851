import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ucfirst',
})
export class UcFirstPipe implements PipeTransform {
  transform(string: string): string {
    const first = string.substr(0, 1).toUpperCase();
    return first + string.substr(1);
  }
}
