<section class="apointment" #form>
  <form
    [formGroup]="leadForm"
    class="enquirer-form"
    name="enquirerFrom"
    (ngSubmit)="submit()">
    <h2 *ngIf="!isArchived && !isBCI; else archivedHeader">
      <ng-container *ngIf="!brochureEnquiry; else brochureHeader">
        Enquire now about {{ development.title }}
      </ng-container>
      <ng-template #brochureHeader>
        Download Brochure for {{ development.title }}
      </ng-template>
    </h2>
    <ng-template #archivedHeader>
      <h2>
        Register Interest for updates on this project and other similar projects
      </h2>
    </ng-template>
    <div class="input-contianer">
      <div class="input-row" fxLayout="row">
        <div class="form-control">
          <div class="input-labels">
            <label>Name <span>(required)</span></label>
          </div>
          <input
            matInput
            [ngClass]="{
              error: !name?.valid && (name?.dirty || name?.touched)
            }"
            formControlName="name"
            name="name"
            type="text"
            required />
        </div>
        <div class="form-control">
          <div class="input-labels">
            <label>Email <span>(required)</span></label>
          </div>
          <input
            matInput
            [ngClass]="{
              error: !email?.valid && (email?.dirty || email?.touched)
            }"
            formControlName="email"
            name="email"
            type="text"
            required />
        </div>
      </div>
      <div class="input-row" fxLayout="row">
        <div class="form-control">
          <div class="input-labels">
            <label>Phone <span>(required)</span></label>
          </div>
          <ngx-intl-tel-input
            [ngClass]="'mobile-input'"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Australia"
            [maxLength]="15"
            [phoneValidation]="true"
            [separateDialCode]="true"
            [numberFormat]="PhoneNumberFormat.National"
            name="contact"
            formControlName="mobile"
            [customPlaceholder]="mobilePlaceholder"
            (countryChange)="onCountryChange($event)">
          </ngx-intl-tel-input>
        </div>
        <div class="form-control">
          <div class="input-labels">
            <label>Post Code <span>(required)</span></label>
          </div>
          <input
            matInput
            [ngClass]="{
              error: !postCode?.valid && (postCode?.dirty || postCode?.touched)
            }"
            formControlName="postCode"
            name="postCode"
            type="text"
            required />
        </div>
      </div>
      <div class="input-row" fxLayout="row" *ngIf="!hideFields">
        <div class="form-control">
          <div class="input-labels">
            <label
              >Which describes you best?
              <span *ngIf="selectRequired">(required)</span></label
            >
          </div>
          <mat-form-field class="enquirer-select">
            <select
              matInput
              name="buyerType"
              [ngClass]="{
                error:
                  !audience?.valid && (audience?.dirty || audience?.touched)
              }"
              matNativeControl
              formControlName="audience">
              <option value="" disabled>Select one</option>
              <option *ngFor="let audienceOption of audienceOptions">
                {{ audienceOption }}
              </option>
            </select>
          </mat-form-field>
        </div>
        <div class="form-control" *ngIf="!hideFields">
          <div class="input-labels">
            <label
              >What is your price range?
              <span *ngIf="selectRequired">(required)</span></label
            >
          </div>
          <mat-form-field class="enquirer-select">
            <select
              matInput
              name="priceRange"
              [ngClass]="{
                error:
                  !priceRange?.valid &&
                  (priceRange?.dirty || priceRange?.touched)
              }"
              matNativeControl
              formControlName="priceRange">
              <option value="" disabled>Select One</option>
              <option
                *ngFor="let priceRangeOption of priceRangeOptions"
                value="{{ priceRangeOption.value }}">
                {{ priceRangeOption.value }}
              </option>
            </select>
          </mat-form-field>
        </div>
      </div>
      <div class="input-row" fxLayout="row">
        <div class="form-control" *ngIf="!hideFields">
          <div class="input-labels">
            <label
              >When are you looking to buy?
              <span *ngIf="selectRequired">(required)</span></label
            >
          </div>
          <mat-form-field class="enquirer-select">
            <select
              matInput
              name="buyingDuration"
              [ngClass]="{
                error:
                  !lookingToBuy?.valid &&
                  (lookingToBuy?.dirty || lookingToBuy?.touched)
              }"
              matNativeControl
              formControlName="lookingToBuy">
              <option value="" disabled>Select One</option>
              <option
                *ngFor="let buyingDurationOption of buyingDurationOptions">
                {{ buyingDurationOption }}
              </option>
            </select>
          </mat-form-field>
        </div>
        <div class="form-control">
          <div class="input-labels">
            <label>Preferred Contact Method?</label>
          </div>
          <mat-form-field class="enquirer-select">
            <select
              matInput
              name="contactMethod"
              [ngClass]="{
                error:
                  !contactMethod?.valid &&
                  (contactMethod?.dirty || contactMethod?.touched)
              }"
              matNativeControl
              formControlName="contactMethod">
              <option value="" disabled>Select One</option>
              <option *ngFor="let contactMethod of preferredContactMethods">
                {{ contactMethod }}
              </option>
            </select>
          </mat-form-field>
        </div>
      </div>
      <div class="input-row" fxLayout="row" *ngIf="!hideFields">
        <div class="form-control">
          <div class="input-labels">
            <label>Do you have finance pre-approval? </label>
          </div>
          <mat-form-field class="enquirer-select">
            <select
              matInput
              name="selectPreApproval"
              [ngClass]="{
                error:
                  !selectPreApproval?.valid &&
                  (selectPreApproval?.dirty || selectPreApproval?.touched)
              }"
              matNativeControl
              formControlName="selectPreApproval">
              <option value="" disabled>Select One</option>
              <option *ngFor="let selectPreApproval of preApprovalOption">
                {{ selectPreApproval }}
              </option>
            </select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="checkbox-container" *ngIf="!isArchived">
      <div class="checkbox-row" fxLayout.md="row" fxLayout.xl="row">
        <div class="checkbox-column" fxLayout="column">
          <section>
            <input
              type="checkbox"
              name="scheduleInspection"
              formControlName="scheduleInspection" />
            <span>Scheduling an inspection</span>
          </section>
          <section>
            <input
              type="checkbox"
              name="requestFloorPlans"
              formControlName="requestFloorPlans" />
            <span>Request floorplans</span>
          </section>
        </div>
        <div class="checkbox-column" fxLayout="column">
          <section>
            <input
              type="checkbox"
              name="priceInformation"
              formControlName="priceInformation" />
            <span>Price Information</span>
          </section>
          <section>
            <input type="checkbox" name="brochure" formControlName="brochure" />
            <span>Brochure</span>
          </section>
        </div>
      </div>
    </div>
    <div class="input-contianer">
      <div class="text-area-row">
        <label
          [ngClass]="{ 'add-message-toggle-inactive': !addMessage }"
          class="add-message-toggle"
          (click)="addMessage = !addMessage"
          >Add a message (optional)</label
        >
        <textarea
          [ngStyle]="{ display: addMessage ? 'block' : 'none' }"
          matInput
          [ngClass]="{
            error: !message?.valid && (message?.dirty || message?.touched)
          }"
          formControlName="message"
          name="message"
          type="text"></textarea>
      </div>
    </div>
    <div class="submit-container">
      <button class="enquiry-submit" [disabled]="!leadForm.valid" type="submit">
        <ng-container *ngIf="!isLoading">{{
          !isArchived && !isBCI
            ? !brochureEnquiry
              ? 'Submit Enquiry'
              : 'Submit'
            : 'Register Interest'
        }}</ng-container>
        <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
      </button>
    </div>
    <div class="lead-disclaimer">
      By submitting an enquiry, you have read, understood and agreed to our
      <a target="_blank" href="/terms-and-conditions">Terms of use</a>,
      <a target="_blank" href="/privacy-policy">Privacy policy</a> and
      <a target="_blank" href="/privacy-collection-notice" rel="nofollow"
        >Privacy collection notice</a
      >
      .
    </div>
  </form>
</section>
