import { createReducer, on } from '@ngrx/store';

import { resetLocation, updateLocation } from './search-location.actions';

export interface SearchLocationState {
  lat: number;
  lng: number;
}

export const initialState: SearchLocationState = {
  lat: 0,
  lng: 0,
};

export const searchLocationReducer = createReducer(
  initialState,
  on(updateLocation, (state, { searchLocation }) => ({
    ...state,
    ...searchLocation,
  })),
  on(resetLocation, () => initialState)
);
