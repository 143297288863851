import { Component, Input, OnInit } from '@angular/core';

import { MapToggleService } from '@core/helpers/map-toggle.service';

@Component({
  selector: 'apd-map-toggle',
  templateUrl: './map-toggle.component.html',
  styleUrls: ['./map-toggle.component.scss'],
})
export class MapToggleComponent implements OnInit {
  @Input() isMobileView: boolean;
  isMapShown = false;

  constructor(private mapToggleService: MapToggleService) {}

  ngOnInit(): void {
    this.mapToggleService.isMapShown$.subscribe(
      isShown => (this.isMapShown = isShown)
    );
  }

  onToggleMap(): void {
    this.isMapShown = !this.isMapShown;
    this.mapToggleService.setIsMapShown(this.isMapShown);
  }
}
