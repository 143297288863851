export const locale = {
  lang: 'tr',
  data: {
    // START Global Translations
    _BUYER_INCENTIVES: 'Alıcı Teşvikleri',
    _BUYING_LIVING: 'Satın Alma ve Yaşam',
    _BUYERS_GUIDE: 'Alıcılar Kılavuzu',
    _HOUSE_LAND_PACKAGES: 'Ev ve Arazi Paketleri',
    _INDUSTRY_PROFILES: 'Sanayi Profilleri',
    _SUBURB_PROFILES: 'Banliyö Profilleri',
    _DEVELOPMENT: {
      APARTMENT: {
        SINGULAR: 'Apartman',
        PLURAL: 'Daireler',
      },
      TOWNHOUSE: {
        SINGULAR: 'Şehir evi',
        PLURAL: 'Şehir evleri',
      },
      LAND_ESTATE: {
        SINGULAR: 'Arsa',
        PLURAL: 'Arsalar',
      },
      PENTHOUSE: {
        SINGULAR: 'Çatı katı',
        PLURAL: "Penthouse'lar",
      },
      PRESTIGE_HOME: {
        SINGULAR: 'Prestij Evi',
        PLURAL: 'Prestij Evleri',
      },
      VILLA: {
        SINGULAR: 'Villa',
        PLURAL: 'Villalar',
      },
    },
    _PROPERTY: {
      TYPE: 'Emlak Tipi',
      BEDROOM: {
        SINGULAR: 'Yatak odası',
        PLURAL: 'Yatak odaları',
      },
      BATHROOMS: 'Banyolar',
      CARS: 'Arabalar',

      CAR_SPACES: 'Araba Mahalleri',
      PRICE: 'Fiyat',
      DEVELOPER: 'Geliştirici',
    },
    _SUPPLIER: {
      AGENT: {
        SINGULAR: 'Ajan',
        PLURAL: 'Ajanlar',
      },
      ARCHITECT: {
        SINGULAR: 'Mimar',
        PLURAL: 'Mimarlar',
      },
      BUILDER: {
        SINGULAR: 'Oluşturucu',
        PLURAL: 'İnşaatçılar',
      },
      DEVELOPER: {
        SINGULAR: 'Geliştirici',
        PLURAL: 'Geliştiriciler',
      },
      PROJECT_MARKETER: {
        SINGULAR: 'Proje Pazarlamacısı',
        PLURAL: 'Proje Pazarlamacıları',
      },
      INTERIOR_DESIGNER: {
        SINGULAR: 'Interior Designer',
        PLURAL: 'Interior Designers',
      },
      LANDSCAPE_ARCHITECT: {
        SINGULAR: 'Landscape Architect',
        PLURAL: 'Landscape Architects',
      },
      DEVELOPER_BUILDER: {
        SINGULAR: 'Geliştirici/Oluşturucu',
        PLURAL: 'Geliştiriciler/İnşaatçılar',
      },
      PROJECT_MANAGER: {
        SINGULAR: 'Proje Müdürü',
        PLURAL: 'Proje Yöneticileri',
      },
    },
    _DEMOGRAPHICS: {
      HOUSEHOLDS: 'Hane',
      YOUNGER_PROFESSIONALS: 'Genç Profesyoneller',
      FIRST_HOME_BUYERS: 'İlk Ev Alıcıları',
      EARLY_CAREERS: 'Erken erişim',
    },
    _PRICES: {
      MIN: 'Min',
      MAX: 'Max',
      AVERAGE_HOUSE: 'Ortalama Ev Fiyatları',
    },
    _FORM: {
      PLACEHOLDER: {
        SEARCH: 'Banliyö veya gelişime göre ara',
        NAME: 'İsim Soyisim',
        PHONE_NUMBER: 'Telefon numarası',
        EMAIL_ADDRESS: 'E-posta Adresi',
        ANY: 'Hiç',
      },
      FILTERS: 'Filtreler',
      CLEAR_FILTERS: 'Filtreleri temizle',
      SHOW_FILTER_RESULTS: '{{ total }} sonucu göster',
      CLEAR: 'Berrak',
      APPLY_FILTERS: 'Filtreleri Uygula',
      APPLY: 'Uygulamak',
    },
    // END Global Translations

    // START Home Page Translations
    HOME: {
      HEADER: {
        FIND_INVESTMENT_PROPERTY: 'Yatırım amaçlı gayrimenkulünüzü bulun',
        INVESTMENT_PROPERTY_OPPORTUNITIES:
          "Alıcıların ve yatırımcıların Avustralya'nın en iyi banliyölerinden satılık en iyi daireleri, şehir evlerini ve arsaları buldukları yatırım amaçlı gayrimenkul fırsatları.",
        FEATURED_INVESTMENT: 'Öne çıkan yatırım daireleri ve şehir evleri',
        AUSTRALIA_BEST_INVESTMENT:
          "Avustralya'nın en iyi yatırım amaçlı gayrimenkul fırsatları",
        PROPERTY_BUYING_OFFERS: 'Emlak satın alma teklifleri ve teşvikler',
        GET_LATEST_OFFERS: 'En son teklifleri doğrudan gelen kutunuza alın',
        TOP_INVESTMENT_SUBURBS:
          'Plan dışı alıcılar için en iyi yatırım banliyöleri',
        OFF_THE_PLAN_BUYING_GUIDE: 'Plan dışı satın alma rehberiniz',
        BUYING_LIVING_INVESTMENT_PROPERTIES:
          "Avustralya'da yatırım amaçlı gayrimenkul satın almak ve yaşamak",
      },
      PLACEHOLDER: {
        SEARCH: 'Banliyö veya gelişime göre ara',
      },
      BUTTON_TEXT: {
        MORE_FEATURED_PROPERTIES: 'Öne çıkan diğer mülkleri görün',
        MORE_BEDROOM_PROPERTIES:
          'Daha fazla {{ bedroom }} yatak odalı mülk görün',
        SEE_ALL_TOP_SUBURBS: 'Tüm en iyi banliyöleri görün',
        VIEW_BUYERS_GUIDE: 'Alıcı Kılavuzunu Görüntüle',
        SEE_ALL_ARTICLES: 'Tüm makaleleri görün',
      },
      TEXT: {
        PROPERTY_BUYING_DESCRIPTION:
          'Sidney, Melbourne, Brisbane ve daha fazlasındaki çok değerli banliyölerde emlak yatırımı hibeleri, indirimler ve indirim fırsatları!',
        OFF_THE_PLAN_BUYING_GUIDE_DESCRIPTION:
          "Kullanışlı emlak kılavuzumuzdan Avustralya'daki emlak yatırımının tüm ayrıntılarını öğrenin",
        BUYING_LIVING_INVESTMENT_PROPERTIES_DESCRIPTION:
          "Kullanışlı emlak kılavuzumuzdan Avustralya'daki emlak yatırımının tüm ayrıntılarını öğrenin",
      },
    },
    // END Home Page Translations

    // START Suburb Profile/s Page Translations
    SUBURB_PROFILE: {
      HEADER: {
        FEATURED_INVESTMENT: 'Öne çıkan yatırım daireleri ve şehir evleri',
        AUSTRALIA_BEST_INVESTMENT:
          "Avustralya'nın en iyi yatırım amaçlı gayrimenkul fırsatları",
        PROPERTY_BUYING_OFFERS: 'Emlak satın alma teklifleri ve teşvikler',
        GET_LATEST_OFFERS: 'En son teklifleri doğrudan gelen kutunuza alın',
      },
      PLACEHOLDER: {
        SEARCH: 'Mahalle veya posta koduna göre ara',
      },
      BUTTON_TEXT: {
        SHOW_ALL_PROFILES: 'Tüm {{ state }} Profillerini Göster',
        MORE_FEATURED_PROPERTIES: 'Öne çıkan diğer mülkleri görün',
        MORE_BEDROOM_PROPERTIES:
          'Daha fazla {{ bedroom }} yatak odalı mülk görün',
        SEE_ALL_ARTICLES: 'Tüm makaleleri görün',
      },
      TEXT: {
        PROPERTY_BUYING_DESCRIPTION:
          'Sidney, Melbourne, Brisbane ve daha fazlasındaki çok değerli banliyölerde emlak yatırımı hibeleri, indirimler ve indirim fırsatları!',
        OFF_THE_PLAN_BUYING_GUIDE_DESCRIPTION:
          "Kullanışlı emlak kılavuzumuzdan Avustralya'daki emlak yatırımının tüm ayrıntılarını öğrenin",
        BUYING_LIVING_INVESTMENT_PROPERTIES_DESCRIPTION:
          "Kullanışlı emlak kılavuzumuzdan Avustralya'daki emlak yatırımının tüm ayrıntılarını öğrenin",
      },
    },
    // END Suburb Profile Page Translations

    // START Industry Profile Page Translations
    INDUSTRY_PROFILE: {
      HEADER: {
        COMPLETED_PROJECTS: 'Tamamlanmış projeler',
        RELATED_SUBURB_PROFILES: 'İlgili Banliyö Profilleri',
        ALL_PROFILES: 'Tüm profiller',
      },
      PLACEHOLDER: {
        SEARCH: 'Anahtar Kelime İle Ara',
      },
      BUTTON_TEXT: {
        VIEW_ALL: 'Hepsini gör',
        VIEW_ALL_COMPLETED: 'Tamamlananların tümünü görüntüleyin',
        VIEW_ALL_PROFILES: 'Tüm {{ industry }} profillerini görüntüleyin',
      },
    },
    // END Industry Profile Page Translations

    // START Buying & Living Page Translations
    BUYING_LIVING: {
      HEADER: {
        ALL: 'Herşey',
        LATEST: 'En son',
        MARKET_INSIGHTS: 'Pazar Bilgileri',
        INDUSTRY_LEADERS: 'Sektör Liderleri',
        LIFESTYLE: 'Yaşam tarzı',
        MAIN: 'Off-the-plan property news',
        SECONDARY:
          'Read the latest new property news & real estate market insights from around Australia',
      },
      PLACEHOLDER: {
        SEARCH: 'Anahtar Kelime İle Ara',
      },
    },
    // END Buying & Living Page Translations

    // START Buyer Offers & Incentives Page Translations
    BUYER_OFFERS: {
      HEADER: {
        MAIN: 'Alıcı teklifleri ve teşvikleri',
      },
      BUTTON_TEXT: {
        NOTIFY: 'Bana bildir',
        LEARN_MORE: 'Daha fazla bilgi edin',
        ENQUIRE_NOW: 'Şimdi soruyoruz',
      },
      TEXT: {
        SHOWING_INCENTIVES: '{{ number }}/{{ total }} Teşvik Gösteriliyor',
        GET_INCENTIVES: 'Dünya lideri teşvikler alın',
      },
    },
    // END Buyer Offers & Incentives Page Translations

    // START Footer Translations
    FOOTER: {
      SIGN_UP: {
        TITLE: 'Yeni geliştirme uyarıları için kaydolun.',
        SUBTITLE: 'Gelen kutunuzdaki her yeni girişi alın',
      },
      INVESTMENT_PROPERTIES: 'yatırım mülkleri',
    },
    // END Footer Translations
  },
};
