import { Pipe, PipeTransform } from '@angular/core';

interface TypeMap {
  default: string;
  sitemap: string;
  sitemapIndustryProfile: string;
  industryProfile: string;
  industryProfileDetails: string;
}
@Pipe({
  name: 'adUrl',
})
export class AdUrlPipe implements PipeTransform {
  typeMap: TypeMap = {
    default: '/',
    sitemap: '/sitemap/',
    sitemapIndustryProfile: '/sitemap/industry-profiles/',
    industryProfile: '/industry-profiles/',
    industryProfileDetails: '/industry-profile-details/',
  };

  transform(url: string, type?: string | null): string {
    return type ? this.typeMap[type] + url : this.typeMap.default + url;
  }
}
