<section class="apointment">
  <div class="form-container">
    <form [formGroup]="otpForm" class="enquirer-form" (ngSubmit)="submit()">
      <!-- <h3>Secret pin code sent via SMS</h3> -->
      <h5>
        We've sent a verification PIN code<br />
        to {{ smsData.mobile }} <br />
        Please enter below
      </h5>

      <div class="secret-code-input">
        <input
          matInput
          [ngClass]="{
            error: !dig1?.valid && (dig1?.dirty || dig1?.touched)
          }"
          #digit1
          formControlName="dig1"
          name="dig1"
          type="text"
          pattern="[0-9]*"
          maxlength="1"
          required
          inputmode="numeric"
          (keyup)="move($event, '', digit1, digit2)"
          (paste)="onPaste($event)" />
        <input
          matInput
          [ngClass]="{
            error: !dig2?.valid && (dig2?.dirty || dig2?.touched)
          }"
          #digit2
          formControlName="dig2"
          name="dig2"
          type="text"
          pattern="[0-9]"
          maxlength="1"
          required
          inputmode="numeric"
          (keyup)="move($event, digit1, digit2, digit3)"
          (paste)="onPaste($event)" />
        <input
          matInput
          [ngClass]="{
            error: !dig3?.valid && (dig3?.dirty || dig3?.touched)
          }"
          #digit3
          formControlName="dig3"
          name="dig3"
          type="text"
          pattern="[0-9]"
          maxlength="1"
          required
          inputmode="numeric"
          (keyup)="move($event, digit2, digit3, digit4)"
          (paste)="onPaste($event)" />
        <input
          matInput
          [ngClass]="{
            error: !dig4?.valid && (dig4?.dirty || dig4?.touched)
          }"
          #digit4
          formControlName="dig4"
          name="dig4"
          type="text"
          pattern="[0-9]"
          maxlength="1"
          required
          inputmode="numeric"
          (keyup)="move($event, digit3, digit4, digit5)"
          (paste)="onPaste($event)" />
        <input
          matInput
          [ngClass]="{
            error: !dig5?.valid && (dig5?.dirty || dig5?.touched)
          }"
          #digit5
          formControlName="dig5"
          name="dig5"
          type="text"
          pattern="[0-9]"
          maxlength="1"
          required
          inputmode="numeric"
          (keyup)="move($event, digit4, digit5, '')"
          (paste)="onPaste($event)" />
      </div>
      <div class="invalid-otp">
        <p style="color: red" *ngIf="message">{{ message }}</p>
      </div>
      <div class="submit-container">
        <button
          class="enquiry-submit"
          type="submit"
          [disabled]="!otpForm.valid">
          <ng-container *ngIf="!isLoading">Submit</ng-container>
          <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
        </button>
      </div>
      <div class="sms-disclaimer mb-20">
        Haven't received the code?
        <button
          type="button"
          class="link-button"
          [disabled]="isResendOtpClicked"
          (click)="resendTimer()">
          Resend PIN Code
        </button>
        <br />
        <ng-container *ngIf="remainingTime > 0"
          >Ask for another code in {{ remainingTimeStr }}</ng-container
        >
      </div>
    </form>
  </div>
</section>
