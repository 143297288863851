import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '@core/types';

import { Sms, SmsVerify } from '../../types/sms/sms.model';
import { ApiService } from '../api.service';
import { LocalStorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService
  ) {}

  sendOTP(sms: Sms): Observable<ApiResponse> {
    return this.apiService
      .post('/leads/otp/send', sms)
      .pipe(map(response => response));
  }
  verifyOTP(verify: SmsVerify): Observable<ApiResponse> {
    return this.apiService
      .post('/leads/otp/verify', verify)
      .pipe(map(response => response));
  }
}
