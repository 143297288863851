import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { DevelopmentAdUrlPipe } from '@shared/pipes';

import { AuthGuard } from './guards/auth.guard';
import { EnsureImportedOnceGuard } from './guards/import-once.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import {
  AlertService,
  QueryService,
  SlugService,
  StateService,
} from './helpers';
import {
  ApiDefaultHeaderInterceptor,
  AuthTokenHeaderInterceptor,
  CookieInterceptor,
} from './interceptors';
import { LayoutModule } from './layout/layout.module';
import {
  ApiService,
  ArticleService,
  AuthenticationService,
  CategoryService,
  ContentService,
  DevelopmentService,
  IncentiveService,
  LanguageService,
  LeadService,
  LocalStorageService,
  SearchService,
  SessionService,
  SettingsService,
  SitemapService,
  SplashService,
  SubscriptionService,
  SuburbService,
  SupplierService,
  TagService,
} from './services';

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenHeaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiDefaultHeaderInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CookieInterceptor,
    multi: true,
  },
];

const services = [
  AuthenticationService,
  AlertService,
  SlugService,
  StateService,
  QueryService,
  ApiService,
  SessionService,
  LanguageService,
  SearchService,
  LocalStorageService,
  SupplierService,
  DevelopmentService,
  SubscriptionService,
  ArticleService,
  SuburbService,
  IncentiveService,
  TagService,
  CategoryService,
  SitemapService,
  ContentService,
  SplashService,
  LeadService,
  SettingsService,
];

const guards = [AuthGuard, NoAuthGuard];

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthTokenHeaderInterceptor,
    ApiDefaultHeaderInterceptor,
    CookieInterceptor,
    ...interceptors,
    ...services,
    ...guards,

    LayoutModule,
    DevelopmentAdUrlPipe,
  ],
  declarations: [],
})
export class CoreModule extends EnsureImportedOnceGuard {
  constructor(@SkipSelf() @Optional() parent: CoreModule) {
    super(parent);
  }
}
