<div
  *ngIf="categoryArticles['data'].length > 0 && display === true"
  class="pt-60 pb-90 px-15 pb-sm-60">
  <section>
    <div class="container lg">
      <div class="title-w-search">
        <div class="title">
          <p>
            Search results for <strong>"{{ keyword }}"</strong>
          </p>
        </div>
        <apd-article-search-bar (searchEventHandler)="onSearch($event)">
        </apd-article-search-bar>
      </div>
      <div class="spinner-container" *ngIf="categoryArticles.data.length == 0">
        <mat-spinner [value]="50"></mat-spinner>
      </div>
      <div
        class="latest-buying_living col--3 mt-40 mt-sm-20"
        *ngIf="categoryArticles.data.length > 0">
        <article
          *ngFor="
            let article of categoryArticles.data
              | paginate: categoryArticles.config
          ">
          <a
            [routerLink]="
              currentLang +
              '/buying-living/' +
              article.category.slug +
              '/' +
              article.slug.name
            ">
            <picture class="position-relative d-block">
              <ng-container *ngIf="article.images.length > 0">
                <img
                  loading="lazy"
                  [src]="article.images[0].url | optimizeImage"
                  [alt]="
                    article.images[0].altTag
                      ? article.images[0].altTag
                      : article.title
                  "
                  class="object-fit-cover object-position-center w-100 h-100" />
              </ng-container>
              <ng-container *ngIf="article.images.length == 0">
                <img
                  loading="lazy"
                  [src]="
                    cdnBasePath + '/assets/default/default-project.jpg'
                      | optimizeImage
                  "
                  [alt]="article.title"
                  class="object-fit-cover object-position-center w-100 h-100" />
              </ng-container>
            </picture>
          </a>
          <div class="wrapper">
            <a
              [routerLink]="
                currentLang + '/buying-living/' + article.category.slug
              "
              class="category d-inline-block">
              {{ category }}
            </a>
            <a href="#" class="category d-inline-block">{{ category }}</a>
            <h4 class="title">
              <a
                [routerLink]="
                  currentLang +
                  '/buying-living/' +
                  article.category.slug +
                  '/' +
                  article.slug.name
                "
                class="d-block">
                {{ article.title }}
              </a>
            </h4>
            <div class="tags">
              <a
                *ngFor="let tag of article.tags"
                (click)="searchByTag(tag.slug)">
                {{ tag.name }}
              </a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</div>

<div
  *ngIf="categoryArticles['data'].length === 0 && display === true"
  class="pt-60 pb-90 px-15 pb-sm-60">
  <section>
    <div class="container lg">
      <div class="title-w-search">
        <div class="title">
          <h4>Search results for "{{ keyword }}"</h4>
        </div>
        <apd-article-search-bar (searchEventHandler)="onSearch($event)">
        </apd-article-search-bar>
      </div>
      <div class="latest-buying_living col--3 mt-40 mt-sm-20">
        <p>No articles found.</p>
      </div>
    </div>
  </section>
</div>

<div *ngIf="categoryArticles['pagination'].totalPages > 1 && display === true">
  <apd-paginator-dark
    [config]="categoryArticles.config"
    (pageChangeEventHandler)="onPageChange($event)"></apd-paginator-dark>
</div>
