import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const minPriceValidator = (max: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const minValue = parseInt(control.value);
    const maxValue = parseInt(max);
    return (minValue > maxValue || minValue === maxValue) && max !== 'Any'
      ? { greaterThan: true }
      : null;
  };
};

export const maxPriceValidator = (min: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const maxValue = parseInt(control.value);
    const minValue = parseInt(min);
    return (maxValue < minValue || maxValue === minValue) && min !== 'Any'
      ? { lessThan: true }
      : null;
  };
};
