import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapToggleService {
  private isMapShownSubject = new BehaviorSubject<boolean>(true);
  isMapShown$ = this.isMapShownSubject.asObservable();

  setIsMapShown(value: boolean) {
    this.isMapShownSubject.next(value);
  }
}
