import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '@core/types';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: Address): string {
    const streetNumber = address.thoroughfareNumber
      ? address.thoroughfareNumber
      : '';
    return `${streetNumber} ${address.thoroughfare}, ${address.area}, ${address.state}`;
  }
}
