import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ApiResponse } from '@core/types';

import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private apiService: ApiService, private router: Router) {}

  getSettings(): Observable<ApiResponse> {
    return this.apiService.get('/settings');
  }

  getTemplateTrackingStatus(): Observable<ApiResponse> {
    return this.apiService.get('/settings/track-template');
  }
}
