import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared/shared.module';

import { SearchFilterComponent } from './search-filter.component';

@NgModule({
  declarations: [SearchFilterComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    MatProgressSpinnerModule,
    SharedModule,
  ],
  exports: [SearchFilterComponent],
})
export class SearchFilterModule {}
