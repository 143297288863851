import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';

@Pipe({
  name: 'optimizeImage',
})
export class OptimizeImagePipe implements PipeTransform {
  isBrowser: boolean;
  window: Window;
  screenWidth: number;

  sizes = {
    xs: 599,
    sm: 959,
    md: 1279,
    lg: 1919,
    xl: 5000,
  };

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.window = this.document.defaultView;
      this.screenWidth = this.window.innerWidth;
    }
  }

  transform(url: string): string {
    // TEMPORARILY COMMENTED OUT
    // let screenWidthSize = this.getScreenWidthSize(),
    //   isAsset = url.includes("assets");

    // return (
    //   url +
    //   "?format=webp" +
    //   (!isAsset ? (screenWidthSize ? `&size=${screenWidthSize}` : "") : "")
    // );

    return `${url}?format=webp`;
  }

  getScreenWidthSize(): string {
    let prevSize = 0,
      result: string;

    for (const size in this.sizes) {
      const currSize = this.sizes[size];

      if (this.screenWidth > prevSize && this.screenWidth < currSize) {
        result = size;
        break;
      } else {
        prevSize = currSize;
      }
    }

    return result;
  }
}
