import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

import { LeadsDialogComponent } from '@core/layout/components/leads-dialog/leads-dialog.component';
import { Development as DevelopmentModel } from '@core/types';

@Component({
  selector: 'apd-developments',
  templateUrl: './developments.component.html',
  styleUrls: ['./developments.component.scss'],
})
export class DevelopmentsComponent {
  dialogAttribures = {
    backdropClass: 'leads-dialog-backdrop',
    panelClass: 'leads-dialog-panel',
    scrollStrategy: new NoopScrollStrategy(),
  };

  @Input() header: string;
  @Input() isLoading: boolean;
  @Input() filter: string;
  @Input() filterValue: string | number | null;
  @Input() buttonText: string;
  @Input() noTrigger: boolean;
  @Input() currentLang: string;
  @Input() developments: Array<DevelopmentModel>;

  @Output() filterRequest = new EventEmitter<string | number | null>();
  @Output() setFilterLimit = new EventEmitter<Event>();

  cdnBasePath = environment.cdnBasePath;

  /**
   * Constructor
   *
   * @param {Router} router
   */
  constructor(private router: Router, public dialog: MatDialog) {}

  setFilter(name: string | number | null): void {
    this.filterRequest.emit(name);
  }

  setDefaultImage(classification: string): string {
    const developmentType = classification.replace(/\s+/g, '-').toLowerCase();
    return 'assets/media/ad-group/default-assets/' + developmentType + '.jpg';
  }

  setDevelopmentLink(development: DevelopmentModel): string {
    const { address, slug } = development;
    return `/new-apartments-developments/${address.shortenState.toLowerCase()}/${
      address.slug
    }/${slug.name}`;
  }

  checkValue(config: string | null): boolean {
    if (config === '0') return false;
    if (config === '-') return false;
    if (config) return true;
  }

  toggleLeadsDialog($event: MouseEvent, development: DevelopmentModel): void {
    this.dialog.open(LeadsDialogComponent, {
      ...this.dialogAttribures,
      data: {
        development: development,
        brochureEnquiry: true,
        elementClassName: ($event.target as HTMLElement).className,
      },
    });
  }

  showStat(val: string): boolean {
    return val && val != '0';
  }

  isArchived(status: string): boolean {
    return status == 'archived' ? true : false;
  }
}
