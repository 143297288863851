import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'apd-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
  @Input() config: PaginationInstance;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  onPageChange(event: number): void {
    this.pageChange.emit(event);
  }
}
