import { Component, OnDestroy, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'default-form-submit-dialog',
  templateUrl: './default-submit-dialog.component.html',
  styleUrls: ['./default-submit-dialog.component.scss'],
})
export class DefaultSubmitDialogComponent implements OnInit, OnDestroy {
  cdnBasePath: string;
  showRelated: boolean;

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    document.body.style.removeProperty('overflow-x');
    this.showNearbyDevelopments();
  }

  ngOnInit(): void {
    document.body.style.overflowX = 'visible';
    this.cdnBasePath = environment.cdnBasePath;
  }

  showNearbyDevelopments(): void {
    if (this.data.showRelated != undefined) {
      this.showRelated = this.data.showRelated == '0' ? false : true;
    } else {
      this.showRelated = true;
    }
  }

  ngOnDestroy(): void {
    document.body.style.removeProperty('overflow-x');
  }

  setShareLinks(social: string, trail = ''): string {
    const {
      address: { shortenState, slug },
      slugs: { name },
    } = this.data;
    const showcaseRoute = `https%3A//www.a-d.com.au/new-apartments-developments/${shortenState.toLowerCase()}/${slug}/${name}`;
    return `${social}${showcaseRoute}${trail}`;
  }

  setMailTo(): string {
    const {
      address: { shortenState, slug },
      slugs: { name },
    } = this.data;
    return `mailto:?subject=Just found the perfect neighbourhood for our new home – take a look&amp;body=https%3A//www.a-d.com.au/new-apartments-developments/${shortenState.toLowerCase()}/${slug}/${name}`;
  }

  isArray(
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ): boolean {
    return Array.isArray(value);
  }
}
