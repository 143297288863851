import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'environments/environment';

import { LanguageService } from '@core/services/language/language.service';
import { Article as ArticleModel } from '@core/types/articles/article.model';

@Component({
  selector: 'apd-related-articles',
  templateUrl: './related-articles.component.html',
  styleUrls: ['./related-articles.component.scss'],
})
export class RelatedArticlesComponent implements OnInit {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() articles: Array<ArticleModel>;

  currentLang: string;

  cdnBasePath = environment.cdnBasePath;

  /**
   * Constructor
   *
   * @param {Router} router
   * @param {LanguageService} languageService
   */
  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.languageService.getCurrentLang();

    /**
     * Redirect to new page and show top contents
     */
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
