import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  Resolve,
  Router,
} from '@angular/router';

import { Observable } from 'rxjs';
import { of } from 'rxjs';

import {
  LocalStorageService,
  PageService,
  SettingsService,
} from '@core/services';

@Injectable()
export class RootRouteResolver implements Resolve<unknown> {
  allowedKeys: Array<string> = [
    'utm_source',
    'utmSource',
    'utm_medium',
    'utmMedium',
    'utm_term',
    'utmTerm',
    'utm_content',
    'utmContent',
    'utm_campaign',
    'utmCampaign',
    'utm_gclid',
    'utmGclid',
    'referrer',
    'gclid',
    'fbclid',
  ];

  isBrowser = false;
  window: Window;

  /**
   * Constructor
   * @param {Router} router
   * @param {LocalStorageService} localStorageService
   */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private localStorageService: LocalStorageService,
    private settingsService: SettingsService,
    private pageService: PageService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  resolve(route: ActivatedRouteSnapshot): Observable<unknown> {
    const paramMap = route.queryParamMap;
    const existingParams = {};

    if (this.isBrowser) {
      this.window = this.document.defaultView;
      const referrer = this.window.document.referrer;

      if (referrer) {
        existingParams['referrer'] = referrer;
      }

      const trackTemplateSettings =
        this.localStorageService.getTrackTemplateSettings();

      if (trackTemplateSettings === null) {
        this.settingsService.getSettings().subscribe(res => {
          const {
              data: { settings },
            } = res,
            jsonSettings = JSON.parse(settings);

          this.localStorageService.setTrackTemplateSettings(jsonSettings);
        });
      } else {
        this.settingsService.getTemplateTrackingStatus().subscribe(res => {
          const {
            data: { trackTemplate },
          } = res;

          if (!trackTemplate) {
            this.localStorageService.removeTrackTemplateSettings();
          }
        });
      }
    }

    // Post Page Visits
    if (this.isBrowser) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.postPageVisit();
        }
      });
    }

    this.allowedKeys.forEach(value => {
      if (paramMap.has(value)) {
        existingParams[value] = paramMap.get(value);
      }
    });

    if (Object.keys(existingParams).length !== 0) {
      this.localStorageService.setUtms(existingParams, this.isBrowser);
    }
    return of(null);
  }

  /**
   * Post Page Visits
   *
   */
  postPageVisit(): void {
    this.window = this.document.defaultView;
    const location = this.window.location.href;

    // Check sessionId and set if not available
    this.localStorageService.setSessionId(this.isBrowser);
    const session = this.localStorageService.getSessionId();

    this.pageService.postPageVisit(session.id, location).subscribe();
  }
}
