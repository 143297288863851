/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class ApiDefaultHeaderInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.headers.get('Content-Type') == undefined ||
      request.headers.get('Content-Type') == null
    ) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      });
    }

    if (
      request.headers.get('Accept') == undefined ||
      request.headers.get('Accept') == null
    ) {
      request = request.clone({
        setHeaders: {
          Accept: 'application/json',
        },
      });
    }

    return next.handle(request);
  }
}
