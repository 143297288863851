import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

import { LanguageService } from '@core/services/language/language.service';
import { Suburb as SuburbModel } from '@core/types/suburbs/suburb.model';

@Component({
  selector: 'apd-suburbs',
  templateUrl: './suburbs.component.html',
  styleUrls: ['./suburbs.component.scss'],
})
export class SuburbsComponent implements OnInit, OnChanges {
  @Input() state: string;
  @Input() isLoading: boolean;
  @Input() hideSuburbButton: boolean;
  @Input() suburbs: Array<SuburbModel>;
  @Output() showMoreEventHandler = new EventEmitter();

  currentLang: string;
  stateSuburbs: Array<SuburbModel>;
  hideButton: boolean;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.currentLang = this.languageService.getCurrentLang();
  }

  ngOnChanges(): void {
    if (this.suburbs) {
      this.stateSuburbs = this.suburbs;
    }
    if (this.hideSuburbButton) {
      this.hideButton = this.hideSuburbButton;
    }
  }

  showMore(): void {
    this.showMoreEventHandler.emit();
  }
}
