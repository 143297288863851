import { ADConfig } from './ad-config.model';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const defaultConfig: ADConfig = {
  // Color themes can be defined in src/app/app.theme.scss
  colorTheme: 'theme-default',
  customScrollbars: true,
  layout: {
    style: 'vertical-layout-1',
    width: 'fullwidth',
    logo: 'assets/media/ad-group/ad-logo.svg',
    logoFull: 'assets/media/ad-group/ad-group-logo.svg',
    head: {
      favicon: 'assets/icons/ad-logo.ico',
      title: 'Apartment & Developments',
    },
    loadScreen: {
      primaryBackground: 'blue-600',
    },
    navbar: {
      primaryBackground: 'blue-50',
      secondaryBackground: 'blue-600',
      showUserProfile: false,
      folded: false,
      hidden: false,
      position: 'left',
      variant: 'vertical-style-1',
    },
    toolbar: {
      customBackgroundColor: false,
      background: 'red',
      hidden: false,
      position: 'below-static',
    },
    footer: {
      customBackgroundColor: true,
      background: 'primary-50 primary-200-fg',
      hidden: false,
      position: 'below-fixed',
    },
    sidepanel: {
      hidden: false,
      position: 'right',
    },
  },
};
