<ng-container *ngIf="display">
  <div [class]="backgroundColor">
    <section
      [ngStyle]="overWriteStyle"
      fxLayout.xs="{'column': !overWriteStyle}"
      [ngClass]="{ 'container py-90': !overWriteStyle }"
      [ngClass.md]="{ 'px-15 py-md-60': !overWriteStyle }"
      [ngClass.sm]="{ 'px-15 py-sm-60': !overWriteStyle }"
      [ngClass.xs]="{ 'px-15 py-sm-60': !overWriteStyle }">
      <h2 *ngIf="header">{{ header }}</h2>
      <div *ngIf="suppliers.length == 0" class="supplier-no-results">
        No results found for {{ header }}.
      </div>
      <ng-container *ngIf="suppliers.length > 0">
        <div
          class="profile-tiles d-grid col--3 col--sm-1 my-sm-20"
          [ngClass]="{ 'mt-40 mb-50': !noMargin }">
          <a
            *ngFor="let supplier of suppliers"
            [routerLink]="getProfileLink(supplier)"
            class="tile position-relative">
            <ng-container *ngIf="supplier.files.thumbnail">
              <img
                loading="lazy"
                [src]="supplier.files.thumbnail[0].url | optimizeImage"
                [alt]="supplier.businessName"
                class="bg--cover" />
            </ng-container>
            <div class="overlay w-100 position-relative">
              <span *ngIf="random" class="profile-type">{{
                supplier.type | titlecase
              }}</span>
              <h4 class="suburb-name">
                {{ supplier.businessName }}
              </h4>
            </div>
          </a>
        </div>
        <ng-container *ngIf="!hideControls">
          <div
            *ngIf="!focused && suppliers.length > 5"
            [ngClass]="'text-center mt-40'"
            [ngClass.xs]="'mt-0'">
            <a (click)="buttonToggle.emit()" class="btn btn--one btn--lg">{{
              buttonText
            }}</a>
          </div>
          <div
            *ngIf="focused && suppliers.length > 5"
            [ngClass]="'text-center mt-40'"
            [ngClass.xs]="'mt-0'">
            <a (click)="buttonToggleFocus.emit()" class="btn btn--one btn--lg"
              >Show More Profiles</a
            >
          </div>
        </ng-container>
      </ng-container>
    </section>
  </div>
</ng-container>
