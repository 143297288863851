<button
  mat-stroked-button
  [id]="id"
  [fxFlex.md]="mdFlex"
  [fxFlex.sm]="smFlex"
  [fxFlex.xs]="xsFlex"
  [ngClass]="{
    defaultClass: defaultClass,
    fullWidth: fullWidth,
    fullHeight: fullHeight
  }"
  [ngClass.md]="mdClass"
  [ngClass.sm]="smClass"
  [ngClass.xs]="xsClass"
  [disabled]="disabled"
  (click)="onClick()">
  {{ label }}
</button>
