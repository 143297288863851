import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import {
  LOCAL_STORAGE,
  StorageService,
  StorageTranscoders,
} from 'ngx-webstorage-service';

import {
  Coordinates,
  Enquirer,
  EnquiryForm,
  FilterSelection,
  SearchFilter,
  Settings,
  TrackTemplates,
} from '@core/types';
import { Dialog } from '@core/types/dialog/dialog.model';
import { Sms } from '@core/types/sms/sms.model';

const STORAGE_KEY = '__AD_';

@Injectable()
export class LocalStorageService {
  private storageSubject = new Subject<string>();

  isServer: boolean;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    @Inject(PLATFORM_ID) platformId: string
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  watchStorage(): Observable<string> {
    return this.storageSubject.asObservable();
  }

  getSearchFilters(): SearchFilter | null {
    const searchFilters = this.storage.get(
      STORAGE_KEY + 'SEARCH_FILTERS',
      StorageTranscoders.STRING
    );
    return searchFilters !== undefined ? JSON.parse(searchFilters) : null;
  }

  setSearchFilters(data: SearchFilter): void {
    this.storage.set(STORAGE_KEY + 'SEARCH_FILTERS', data);
  }

  removeSearchFilters(): void {
    this.storage.remove(STORAGE_KEY + 'SEARCH_FILTERS');
  }

  getActiveFilters(): boolean {
    return this.storage.get(
      STORAGE_KEY + 'ACTIVE_FILTERS',
      StorageTranscoders.BOOLEAN
    );
  }

  enableActiveFilters(): void {
    this.storage.set(STORAGE_KEY + 'ACTIVE_FILTERS', true);
  }

  disableActiveFilters(): void {
    this.storage.set(STORAGE_KEY + 'ACTIVE_FILTERS', false);
  }

  removeActiveFilters(): void {
    this.storage.remove(STORAGE_KEY + 'ACTIVE_FILTERS');
  }

  getSearchKeyword(): string | null {
    const keyword = this.storage.get(
      STORAGE_KEY + 'SEARCH_KEYWORD',
      StorageTranscoders.STRING
    );
    return keyword !== undefined ? JSON.parse(keyword) : null;
  }

  setSearchKeyword(keyword: string): void {
    this.storage.set(STORAGE_KEY + 'SEARCH_KEYWORD', keyword);
    this.storageSubject.next('keyword_changed');
  }

  removeSearchKeyword(): void {
    this.storage.remove(STORAGE_KEY + 'SEARCH_KEYWORD');
    this.storageSubject.next('keyword_removed');
  }

  getMapRedraw(): boolean {
    return this.storage.get(
      STORAGE_KEY + 'MAP_REDRAW',
      StorageTranscoders.BOOLEAN
    );
  }

  enableMapRedraw(): void {
    this.storage.set(STORAGE_KEY + 'MAP_REDRAW', true);
  }

  disableMapRedraw(): void {
    this.storage.set(STORAGE_KEY + 'MAP_REDRAW', false);
  }

  getFilterSelections(): FilterSelection | null {
    const selections = this.storage.get(
      STORAGE_KEY + 'FILTER_SELECTIONS',
      StorageTranscoders.STRING
    );
    return selections !== undefined ? JSON.parse(selections) : null;
  }

  setFilterSelections(selections: FilterSelection): void {
    this.storage.set(STORAGE_KEY + 'FILTER_SELECTIONS', selections);
    this.storageSubject.next('filter_selection_changed');
  }

  getSuburbCoordinates(): Coordinates | null {
    const coordinates = this.storage.get(
      STORAGE_KEY + 'SUBURB_COORDINATES',
      StorageTranscoders.STRING
    );
    return coordinates !== undefined ? JSON.parse(coordinates) : null;
  }

  setSuburbCoordinates(coordinates: Coordinates): void {
    this.storage.set(STORAGE_KEY + 'SUBURB_COORDINATES', coordinates);
  }

  removeSuburbCoordinates(): void {
    this.storage.remove(STORAGE_KEY + 'SUBURB_COORDINATES');
  }

  getEnquiryForm(): EnquiryForm {
    const form = this.storage.get(
      STORAGE_KEY + 'ENQUIRY_FORM',
      StorageTranscoders.STRING
    );
    return form !== undefined ? JSON.parse(form) : null;
  }

  setEnquiryForm(form: EnquiryForm): void {
    this.storage.set(STORAGE_KEY + 'ENQUIRY_FORM', form);
  }

  removeEnquiryForm(): void {
    this.storage.remove(STORAGE_KEY + 'ENQUIRY_FORM');
  }

  getSearchHeader(): string {
    const header = this.storage.get(
      STORAGE_KEY + 'SEARCH_HEADER',
      StorageTranscoders.STRING
    );
    return header !== undefined ? JSON.parse(header) : null;
  }

  setSearchHeader(header: string): void {
    this.storage.set(STORAGE_KEY + 'SEARCH_HEADER', header);
    this.storageSubject.next('header_changed');
  }

  setEnquirerDetails(enquirer: Enquirer): void {
    const storableEnquirer = (({ name, email, mobile, postCode }) => ({
      name,
      email,
      mobile,
      postCode,
    }))(enquirer);

    this.storage.set(STORAGE_KEY + 'ENQUIRER_FORM', storableEnquirer);
  }

  getEnquirerDetails(): Enquirer {
    const storageKey = STORAGE_KEY + 'ENQUIRER_FORM';
    let params = null;

    if (this.storage.has(storageKey)) {
      params = this.storage.get(storageKey, StorageTranscoders.JSON);
    }

    return params;
  }

  removeEnquirerDetails(): void {
    this.storage.remove(STORAGE_KEY + 'ENQUIRER_FORM');
  }

  setSendSmsPayload(sms: Sms): void {
    const smsPayload = (({ mobile, developmentId, email, countryCode }) => ({
      mobile,
      developmentId,
      email,
      countryCode,
    }))(sms);

    this.storage.set(STORAGE_KEY + 'SMS_PAYLOAD', smsPayload);
  }

  getSendSmsPayload(): Sms {
    const storageKey = STORAGE_KEY + 'SMS_PAYLOAD';
    let params = null;

    if (this.storage.has(storageKey)) {
      params = this.storage.get(storageKey, StorageTranscoders.JSON);
    }

    return params;
  }

  removeSendSmsPayload(): void {
    this.storage.remove(STORAGE_KEY + 'SMS_PAYLOAD');
  }

  setDialogData(dialog: Dialog): void {
    const dialogData = (({
      nearByDevelopments,
      isArchived,
      showRelated,
      articles,
    }) => ({
      nearByDevelopments,
      isArchived,
      showRelated,
      articles,
    }))(dialog);

    this.storage.set(STORAGE_KEY + 'DIALOG_DATA', dialogData);
  }

  getDialogData(): Dialog {
    const storageKey = STORAGE_KEY + 'DIALOG_DATA';
    let params = null;

    if (this.storage.has(storageKey)) {
      params = this.storage.get(storageKey, StorageTranscoders.JSON);
    }

    return params;
  }

  removeDialogData(): void {
    this.storage.remove(STORAGE_KEY + 'DIALOG_DATA');
  }

  setExpiryTime(time: string): void {
    const expiryTime = time;

    this.storage.set(STORAGE_KEY + 'EXPIRY_TIME', expiryTime);
  }

  getExpiryTime(): string {
    const storageKey = STORAGE_KEY + 'EXPIRY_TIME';
    let params = null;

    if (this.storage.has(storageKey)) {
      params = this.storage.get(storageKey, StorageTranscoders.JSON);
    }

    return params;
  }

  removeExpiryTime(): void {
    this.storage.remove(STORAGE_KEY + 'EXPIRY_TIME');
  }

  setUtms(
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    utms: Record<string, any>,
    isBrowser: boolean
  ): void {
    const storedUtms = this.getUtms();
    const base64 = utms
      ? !isBrowser
        ? Buffer.from(JSON.stringify(utms), 'binary').toString('base64')
        : btoa(JSON.stringify(utms))
      : null;
    const dateTimeNow = Date.now();
    const dateTimeFutureObject = new Date(dateTimeNow);
    const dateTimeFuture = dateTimeFutureObject.setMinutes(
      dateTimeFutureObject.getMinutes() + 15
    );

    if (utms) {
      utms['base64'] = base64;
      utms['expiryDateTime'] = dateTimeFuture;

      if (storedUtms) {
        if (base64 != storedUtms['base64']) {
          this.storage.set(STORAGE_KEY + 'SESSION_UTMS', utms);
        } else {
          if (dateTimeNow > storedUtms['expiryDateTime']) {
            this.storage.set(STORAGE_KEY + 'SESSION_UTMS', utms);
          }
        }
      } else {
        this.storage.set(STORAGE_KEY + 'SESSION_UTMS', utms);
      }
    }
  }

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUtms(): any {
    const storageKey = STORAGE_KEY + 'SESSION_UTMS',
      dateTimeNow = Date.now();
    let params = null;

    if (this.storage.has(storageKey)) {
      params = this.storage.get(storageKey, StorageTranscoders.JSON);
      params =
        params.expiryDateTime && dateTimeNow < params.expiryDateTime
          ? params
          : null;
    }

    return params;
  }

  removeUtms(): void {
    this.storage.remove(STORAGE_KEY + 'SESSION_UTMS');
  }

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSearchLocation(): any {
    const location = this.storage.get(
      STORAGE_KEY + 'SEARCH_LOCATION',
      StorageTranscoders.JSON
    );
    return location !== undefined ? JSON.parse(location) : null;
  }

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSearchLocation(location: any): void {
    this.storage.set(STORAGE_KEY + 'SEARCH_LOCATION', JSON.stringify(location));
  }

  getTrackTemplateSettings(): TrackTemplates {
    const trackTemplate = this.storage.get(
      STORAGE_KEY + 'TRACK_TEMPLATE',
      StorageTranscoders.JSON
    );
    return trackTemplate !== undefined ? JSON.parse(trackTemplate) : null;
  }

  setTrackTemplateSettings(settings: Settings): TrackTemplates {
    const trackTemplate = settings.showcase.trackTemplates;

    if (trackTemplate.enabled) {
      this.storage.set(
        STORAGE_KEY + 'TRACK_TEMPLATE',
        JSON.stringify(trackTemplate)
      );
    } else {
      this.removeTrackTemplateSettings();
    }

    return this.getTrackTemplateSettings();
  }

  removeTrackTemplateSettings(): void {
    this.storage.remove(STORAGE_KEY + 'TRACK_TEMPLATE');
  }

  setSessionId(isBrowser: boolean): void {
    if (!this.isServer) {
      const storedSession = this.getSessionId();
      const dateTimeNow = Date.now();
      const dateTimeFutureObject = new Date(dateTimeNow);
      const dateTimeFuture = dateTimeFutureObject.setMinutes(
        dateTimeFutureObject.getMinutes() + 15
      );
      const base64 = !isBrowser
        ? Buffer.from('SESSION_ID' + dateTimeNow, 'binary').toString('base64')
        : btoa('SESSION_ID' + dateTimeNow);

      const expiryDateTime = dateTimeFuture;

      const newSessionId = {
        id: base64,
        expiryDateTime,
      };

      if (storedSession) {
        if (dateTimeNow > storedSession['expiryDateTime']) {
          this.storage.set(STORAGE_KEY + 'SESSION_ID', newSessionId);
        }
      } else {
        this.storage.set(STORAGE_KEY + 'SESSION_ID', newSessionId);
      }
    }
  }

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSessionId(): any {
    const storageKey = STORAGE_KEY + 'SESSION_ID',
      dateTimeNow = Date.now();
    let params = null;

    if (this.storage.has(storageKey)) {
      params = this.storage.get(storageKey, StorageTranscoders.JSON);
      params =
        params.expiryDateTime && dateTimeNow < params.expiryDateTime
          ? params
          : null;
    }

    return params;
  }

  removeSessionId(): void {
    this.storage.remove(STORAGE_KEY + 'SESSION_ID');
  }
}
