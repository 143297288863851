import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'apd-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss'],
})
export class AudioComponent implements AfterViewInit {
  @Input() src: string;

  @ViewChild('audioContainer') audioContainer: ElementRef<Element>;

  scriptClassName = 'audio';
  scriptType = 'text/javascript';
  isBrowser: boolean;

  /**
   * Constructor
   * @param subscriptionService SubscriptionService
   */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let script: any,
      shouldAppend = false;

    if (this.isBrowser) {
      if (
        this._document.body.getElementsByClassName(this.scriptClassName).length
      ) {
        script = this._document.body.getElementsByClassName(
          this.scriptClassName
        )[0];
      } else {
        script = this._document.createElement('script');
        shouldAppend = true;
      }

      script.setAttribute('class', this.scriptClassName);
      script.type = this.scriptType;
      script.src = this.src;

      if (shouldAppend) {
        this.audioContainer.nativeElement.appendChild(script);
      }
    }
  }
}
