import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AnchorStrokedModule } from '@core/layout/components/material/anchors/stroked/stroked.module';
import { ButtonDefaultModule } from '@core/layout/components/material/buttons/default/default.module';
import { ButtonStrokedModule } from '@core/layout/components/material/buttons/stroked/stroked.module';
import { TabDefaultModule } from '@core/layout/components/material/tabs/default/default.module';
import { SearchBarModule } from '@core/layout/components/search-bar/search-bar.module';
import { SuburbsModule } from '@core/layout/components/suburbs/suburbs.module';

import { ArticleCategoriesModule } from '@modules/buying-living/components/categories/categories.module';
import { ArticleCategoryModule } from '@modules/buying-living/components/category/category.module';
import { ArticleSearchBarModule } from '@modules/buying-living/components/search-bar/search-bar.module';
import { ArticleSearchResultsModule } from '@modules/buying-living/components/search-results/search-results.module';
import { SuppliersModule } from '@modules/industry-profiles/components/suppliers/suppliers.module';

import { FuseSidebarModule } from '@shared/@fuse/components';
import { FuseSharedModule } from '@shared/@fuse/shared.module';

import { AudioModule } from '../components/audio/audio.module';
import { BarGraphModule } from '../components/bar-graph/bar-graph.module';
import { CompletedProjectsModule } from '../components/completed-projects/completed-projects.module';
import { ContentModule } from '../components/content/content.module';
import { DevelopmentsModule } from '../components/developments/developments.module';
import { EffiModule } from '../components/effi/effi.module';
import { DevelopmentFeaturedModule } from '../components/featured/featured.module';
import { FooterModule } from '../components/footer/footer.module';
import { HeaderModule } from '../components/header/header.module';
import { DefaultLeadFormsModule } from '../components/lead-forms/default/default.module';
import { PaginatorDarkModule } from '../components/paginator/dark/dark.module';
import { PaginatorModule } from '../components/paginator/paginator.module';
import { RelatedArticlesModule } from '../components/related-articles/related-articles.module';
import { RelatedProfilesModule } from '../components/related-profiles/related-profiles.module';
import { SubscribeModule } from '../components/subscribe/subscribe.module';
import { SuburbProfileDetailsModule } from '../components/suburb-profile-details/suburb-profile-details.module';
import { BaseLayoutComponent } from './base.component';

@NgModule({
  declarations: [BaseLayoutComponent],
  imports: [
    RouterModule,

    FuseSharedModule,
    FuseSidebarModule,

    ButtonDefaultModule,
    ButtonStrokedModule,
    AnchorStrokedModule,
    TabDefaultModule,

    ContentModule,
    HeaderModule,
    FooterModule,
    BarGraphModule,
    RelatedArticlesModule,
    RelatedProfilesModule,
    CompletedProjectsModule,
    DevelopmentsModule,
    DevelopmentFeaturedModule,
    SuburbsModule,
    SuburbProfileDetailsModule,
    SubscribeModule,
    SearchBarModule,
    DefaultLeadFormsModule,
    PaginatorModule,
    PaginatorDarkModule,
    SuppliersModule,
    ArticleCategoriesModule,
    ArticleCategoryModule,
    ArticleSearchResultsModule,
    ArticleSearchBarModule,
    AudioModule,
    EffiModule,
  ],
  exports: [BaseLayoutComponent],
})
export class BaseLayoutModule {}
