import {
  Component,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'apd-article-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleSearchBarComponent {
  @Output() searchEventHandler = new EventEmitter<{ keyword: string }>();

  searchForm = new FormGroup({
    keyword: new FormControl(''),
  });

  onSearch(): void {
    this.searchEventHandler.emit({
      keyword: this.searchForm.value.keyword,
    });
  }
}
