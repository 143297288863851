import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { environment } from 'environments/environment';

@Component({
  selector: 'sms-custom-dialog',
  template: '',
})
export class SmsCustomDialogComponent {
  dialogAttributes = {
    backdropClass: 'leads-dialog-backdrop',
    panelClass: 'leads-dialog-panel',
    scrollStrategy: new NoopScrollStrategy(),
  };

  constructor(public dialog: MatDialog) {}

  @Output() formClosed = new EventEmitter<void>();

  openDialog(): void {
    const dialogRef = this.dialog.open(SmsDialogComponent, {
      ...this.dialogAttributes,
    });
    dialogRef.afterClosed().subscribe(() => this.formClosed.emit());
  }
}

@Component({
  selector: 'sms-dialog',
  templateUrl: './sms-dialog.component.html',
  styleUrls: ['./sms-dialog.component.scss'],
})
export class SmsDialogComponent {
  cdnBasePath = environment.cdnBasePath;

  constructor(public dialogRef: MatDialogRef<SmsCustomDialogComponent>) {}
  close(): void {
    this.dialogRef.close();
  }
}
