import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { RootRouteResolver } from './routing/resolvers/root-route-resolver.service';

const routes: Routes = [
  {
    path: '',
    resolve: {
      rootData: RootRouteResolver,
    },
    loadChildren: () =>
      import('./routing/root-routing.module').then(m => m.RootRouteModule),
  },
  { path: '**', redirectTo: '/errors/404', pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [RootRouteResolver],
})
export class AppRoutingModule {}
