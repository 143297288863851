import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { LocalStorageService } from '@core/services';
import { Development } from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class CustomGTMService {
  isBrowser = false;
  window: Window;
  url: string;
  pageName: string;

  //TODO: eslint/ban-types
  //eslint-disable-next-line @typescript-eslint/ban-types
  pathMap = {
    '/': 'Home',
    search: 'Search',
    showcase: 'Showcase',
    suburb: 'Suburb Profile',
    industry: 'Industry Profile',
  };

  constructor(
    private gtmService: GoogleTagManagerService,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  pageView(): void {
    this.gtmService.pushTag({ event: 'pageview' });
  }

  pushTag(development: Development, formType: string): void {
    const trackTemplate = this.localStorageService.getTrackTemplateSettings(),
      templateToUse = trackTemplate ? trackTemplate.templateToUse : null;
    let sku = `${development.featuredWeight}-${formType}-${development.status}`;

    sku = templateToUse ? sku + `-${templateToUse}` : sku;

    const tagAttributes = {
      transactionId: `${this.getTransPrefix(development.classification)}${
        development.slug.name
      }`,
      transactionTotal: 1,
      transactionProducts: [
        {
          sku: sku,
          name: development.title,
          category: `${development.address.state} - ${development.address.region} - ${development.address.area} - ${development.address.postalCode}`,
          price: 1,
          quantity: 1,
        },
      ],
      event: 'transactionComplete',
    };

    this.gtmService.pushTag(tagAttributes);
  }

  getTransPrefix(classification: string): string {
    let transPrefix = 'APD-';
    if (classification === 'new-land-estates') {
      transPrefix = 'NLE-';
    } else if (classification === 'townhouses') {
      transPrefix = 'TH-';
    }
    return transPrefix;
  }

  pushProjectClickTag(development: Development, elementClassName = ''): void {
    if (this.isBrowser) {
      this.window = this.document.defaultView;

      const pathName = this.window.location.pathname;
      let pathNameArr = pathName.split('/'),
        state = '',
        suburb = '';

      pathNameArr = [...new Set(pathNameArr)];
      pathNameArr = pathNameArr.filter(
        (x): x is string => x !== null && x != ''
      );

      this.url = this.window.location.href;

      if (pathName == '/' && pathNameArr.length == 0) {
        this.pageName = this.pathMap[pathName];
      } else {
        if (pathNameArr.length == 3) {
          state = pathNameArr[1];
          suburb = pathNameArr[2];
          if (pathNameArr[0] == 'new-developments-investment-property') {
            this.pageName = this.pathMap['suburb'];
          } else {
            this.pageName = this.pathMap['search'];
          }
        } else if (pathNameArr.length == 2) {
          if (pathNameArr[0] == 'industry-profile-details') {
            this.pageName = this.pathMap['industry'];
          }
        } else {
          this.pageName = this.pathMap['showcase'];
          state = pathNameArr[1];
          suburb = pathNameArr[2];
        }
      }

      const tagAttributes = {
        elementUrl: this.url,
        elementClasses: elementClassName,
        pageName: this.pageName,
        state: state,
        suburb: suburb,
        project: {
          name: development.title,
          status: development.status,
          featuredWeight: development.featuredWeight,
        },
        event: 'enquireNowClick',
      };

      this.gtmService.pushTag(tagAttributes);
    }
  }

  pushProjectPhotoClickTag(
    development: Development,
    order: number,
    isImageScroller = false
  ): void {
    if (this.isBrowser) {
      this.window = this.document.defaultView;

      const pathName = this.window.location.pathname;
      let pathNameArr = pathName.split('/'),
        state = '',
        suburb = '';

      pathNameArr = [...new Set(pathNameArr)];
      pathNameArr = pathNameArr.filter(
        (x): x is string => x !== null && x != ''
      );
      state = pathNameArr[1];
      suburb = pathNameArr[2];
      this.url = this.window.location.href;

      const tagAttributes = {
        isInImageScroller: isImageScroller,
        imageOrder: order,
        state: state,
        suburb: suburb,
        project: {
          name: development.title,
          status: development.status,
          featuredWeight: development.featuredWeight,
        },
        event: 'projectPhotoClick',
      };

      this.gtmService.pushTag(tagAttributes);
    }
  }
}
