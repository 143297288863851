import { ADConfig } from './ad-config.model';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const testConfig: ADConfig = {
  colorTheme: 'theme-test',
  customScrollbars: true,
  layout: {
    style: 'vertical-layout-1',
    width: 'fullwidth',
    logo: 'assets/media/test/logo.png',
    logoFull: 'assets/media/test/group-logo.svg',
    head: {
      favicon: 'assets/icons/ad-logo.ico',
      title: 'Test',
    },
    loadScreen: {
      primaryBackground: 'red-600',
    },
    navbar: {
      primaryBackground: 'red-100',
      secondaryBackground: 'red-600',
      showUserProfile: false,
      folded: false,
      hidden: false,
      position: 'left',
      variant: 'vertical-style-1',
    },
    toolbar: {
      customBackgroundColor: false,
      background: 'red',
      hidden: false,
      position: 'below-static',
    },
    footer: {
      customBackgroundColor: true,
      background: 'primary-50 primary-200-fg',
      hidden: false,
      position: 'below-fixed',
    },
    sidepanel: {
      hidden: false,
      position: 'right',
    },
  },
};
