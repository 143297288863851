import { Pipe, PipeTransform } from '@angular/core';

import { Properties } from '@core/types';

@Pipe({
  name: 'cleanPrice',
})
export class CleanPricePipe implements PipeTransform {
  transform(property: Properties): string {
    let result = '';

    if (property.priceDisplay != 'Contact for price') {
      const priceTo = parseFloat(property.priceTo.replace(/,/g, ''));

      result = property.priceDisplay;

      if (!property.priceDisplay.includes('-') && priceTo) {
        result =
          property.priceDisplay + ' - ' + ('$' + priceTo.toLocaleString());
      }
    } else {
      result = property.priceDisplay;
    }

    return result;
  }
}
