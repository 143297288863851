<div class="pagination text-center pb-60 px-15">
  <pagination-template
    #p="paginationApi"
    [id]="config['id']"
    (pageChange)="onPageChange($event)">
    <a class="prev">
      <img
        (click)="p.previous()"
        height="24"
        width="24"
        [class.disabled]="p.isFirstPage()"
        [src]="cdnBasePath + '/assets/icon/chevron.svg' | optimizeImage"
        alt="" />
    </a>
    <ng-container *ngFor="let page of p.pages">
      <a
        (click)="p.setCurrent(page.value)"
        [class.active]="p.getCurrent() === page.value">
        <span [class.disabled]="p.getCurrent() === page.value">
          {{ page.label }}
        </span>
      </a>
    </ng-container>
    <a class="next">
      <img
        (click)="p.next()"
        height="24"
        width="24"
        [class.disabled]="p.isLastPage()"
        [src]="cdnBasePath + '/assets/icon/chevron.svg' | optimizeImage"
        alt="" />
    </a>
  </pagination-template>
</div>
