import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MapToggleService } from '@core/helpers/map-toggle.service';
import { SearchFilter } from '@core/types';
import { FilterConfig } from '@core/types/common/filter-config.model';

@Component({
  selector: 'apd-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit {
  @Output() toggleMobileFilter = new EventEmitter<Event>();

  @Input() filter: SearchFilter;
  @Input() filterConfig: FilterConfig;

  isMapShown = true;
  filterOutsideMap = true;
  showFilters = false;

  constructor(private mapToggleService: MapToggleService) {}

  ngOnInit(): void {
    this.mapToggleService.isMapShown$.subscribe(
      isShown => (this.isMapShown = isShown)
    );
  }

  showMapToggle(): void {
    this.isMapShown = !this.isMapShown;
    this.mapToggleService.setIsMapShown(this.isMapShown);
  }

  toggleSearchFilterPopup(event: Event): void {
    this.showFilters = !this.showFilters;

    this.toggleMobileFilter.emit(event);
  }

  getClassIfFilterExists(type: string): string {
    let result = '';
    if (!this.filter) {
      return result;
    }

    if (this.filter[type] !== null) {
      for (const key in this.filter[type]) {
        if (type === 'propertyType' && key === 'all') {
          continue;
        }

        if (
          type == 'price' &&
          this.filter[type].min === 'Any' &&
          this.filter[type].max === 'Any'
        ) {
          continue;
        }

        if (this.filter[type][key]) {
          result = 'filters-bar-btn-selected';
        }
      }
    }

    return result;
  }

  getButtonTitle(type: string): string {
    let result = '';

    if (!this.filterConfig) {
      return result;
    }

    switch (type) {
      case 'propertyTypes':
        result =
          this.filterConfig[type] !== null
            ? this.filterConfig[type]
            : 'Property Type';
        break;
      case 'priceRange':
        result =
          this.filterConfig[type] !== null ? this.filterConfig[type] : 'Price';
        break;
      case 'bedrooms':
      case 'bathrooms':
      case 'carSpaces':
        result = this.filterConfig[type];
        break;
    }

    return result;
  }
}
