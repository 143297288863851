import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { locale as translationChinese } from '@core/translations/i18n/cn';
import { locale as translationEnglish } from '@core/translations/i18n/en';
import { locale as translationTurkish } from '@core/translations/i18n/tr';

import { navigation } from '@configs/navigation/navigation';

import { Constants } from '@common/constants';

import { FuseNavigationService } from '@shared/@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@shared/@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@shared/@fuse/services/config.service';
import { FuseSplashScreenService } from '@shared/@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@shared/@fuse/services/translation-loader.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fuseConfig: any;
  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation: any;

  pathArray: Array<string>;
  allowedLanguages = Constants.allowedLanguages;

  // Private
  private _unsubscribeAll: Subject<unknown>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) platformId: string,
    private titleService: Title,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _route: ActivatedRoute,
    private gtmService: GoogleTagManagerService
  ) {
    const isServer = isPlatformServer(platformId);

    if (!isServer && this.gtmService) {
      this.gtmService.addGtmToDom();
    }

    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this._fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this._fuseNavigationService.setCurrentNavigation('main');

    // Add languages
    this._translateService.addLangs(['cn', 'en', 'tr']);
    // Set the default language
    this._translateService.setDefaultLang('en'); // Update: add preferredLang value in DB for specific user

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(
      translationChinese,
      translationEnglish,
      translationTurkish
    );

    this.pathArray = document.location.pathname.split('/');

    this.allowedLanguages.includes(this.pathArray[1])
      ? this._translateService.use(this.pathArray[1])
      : // Set as default
        this._translateService.use('en');

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     */

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
     * setTimeout(() => {
     * this._translateService.setDefaultLang('en');
     * this._translateService.setDefaultLang('tr');
     * });
     */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.fuseConfig = config;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpen(key: string | number): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
