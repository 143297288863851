import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { CookieService } from 'ngx-cookie-service';

import { LocalStorageService } from '@core/services';
import { SmsService } from '@core/services/modules/sms.service';
import { ApiResponse, Development } from '@core/types';
import { Dialog } from '@core/types/dialog/dialog.model';
import { SendSmsResponse } from '@core/types/sms/sendSmsResponse.model';
import { Sms, SmsVerify } from '@core/types/sms/sms.model';

import { DefaultSubmitDialogComponent } from '../lead-forms/default/submit-dialog/default-submit-dialog.component';

@Component({
  selector: 'apd-sms-dialog-form',
  templateUrl: './sms-dialog-form.component.html',
  styleUrls: ['./sms-dialog-form.component.scss'],
})
export class SmsDialogFormComponent implements OnInit {
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formClose = new EventEmitter<void>();

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private localStorageService: LocalStorageService,
    private smsService: SmsService,
    private cookieService: CookieService
  ) {}

  isLoading = false;
  timerId: NodeJS.Timeout;
  remainingTimeStr: string;
  remainingTime = 0;
  message = '';
  otp: string;
  isVerified: boolean | unknown;
  isResendOtpClicked = false;
  smsData: Sms;
  verifySms: SmsVerify = {
    mobile: '',
    developmentId: '',
    email: '',
    otp_code: '',
  };
  dialogData: Dialog;
  otpForm = this.formBuilder.group({
    dig1: ['', [Validators.required, Validators.pattern('[0-9]')]],
    dig2: ['', [Validators.required, Validators.pattern('[0-9]')]],
    dig3: ['', [Validators.required, Validators.pattern('[0-9]')]],
    dig4: ['', [Validators.required, Validators.pattern('[0-9]')]],
    dig5: ['', [Validators.required, Validators.pattern('[0-9]')]],
  });

  ngOnInit(): void {
    this.smsData = this.localStorageService.getSendSmsPayload();
    this.dialogData = this.localStorageService.getDialogData();
  }

  onPaste(e: ClipboardEvent): void {
    const code =
      e.clipboardData.getData('Text') || e.clipboardData.getData('Text');

    this.otpForm.controls['dig1'].setValue(code[0]);
    this.otpForm.controls['dig2'].setValue(code[1]);
    this.otpForm.controls['dig3'].setValue(code[2]);
    this.otpForm.controls['dig4'].setValue(code[3]);
    this.otpForm.controls['dig5'].setValue(code[4]);
  }

  submit(): void {
    if (this.otpForm.invalid) {
      return;
    }
    this.smsData = this.localStorageService.getSendSmsPayload();
    this.isLoading = true;
    this.generateVerifyOtpPayload();
    this.verifyOtp();
  }
  verifyOtp(): void {
    this.smsService.verifyOTP(this.verifySms).subscribe(response => {
      this.isLoading = false;
      const verifyResponse = response.data as SendSmsResponse;
      this.isVerified = verifyResponse.verified
        ? verifyResponse.verified
        : false;
      if (!response.success) {
        this.handleErrors(response);
        return;
      }
      if (!this.isVerified) {
        this.otpForm.reset();
        this.handleDefault();
        return;
      }
      if (!this.cookieService.check('ad-otp-form-cookie')) {
        this.setFormCookie();
      }
      this.isResendOtpClicked = false;
      this.openSubmitDialog(this.dialogData.nearByDevelopments);
      this.otpForm.reset();
      this.localStorageService.removeSendSmsPayload();
    });
  }

  setFormCookie(): void {
    const cookielValue = Math.random().toString(36).substring(7);
    const expirationTime = new Date();
    expirationTime.setMinutes(expirationTime.getMinutes() + 4 * 60);
    this.cookieService.set(
      'ad-otp-form-cookie',
      cookielValue,
      expirationTime,
      '/'
    );
  }

  generateVerifyOtpPayload(): void {
    this.otp = `${this.otpForm.value.dig1}${this.otpForm.value.dig2}${this.otpForm.value.dig3}${this.otpForm.value.dig4}${this.otpForm.value.dig5}`;
    this.verifySms.developmentId = this.smsData.developmentId;
    this.verifySms.email = this.smsData.email;
    this.verifySms.mobile = this.smsData.mobile;
    this.verifySms.otp_code = this.otp;
  }

  openSubmitDialog(nearByDevelopments: Array<Development>): void {
    this.formSubmitted.emit();
    this.dialog.open(DefaultSubmitDialogComponent, {
      data: {
        nearByDevelopments: nearByDevelopments,
        isArchived: this.dialogData.isArchived,
        showRelated: this.dialogData.showRelated,
        articles: this.dialogData.articles,
      },
    });
  }

  closeForm(): void {
    this.disableTimer();
    this.otpForm.reset();
    this.message = '';
    this.formClose.emit();
  }

  resendTimer(): void {
    this.smsData = this.localStorageService.getSendSmsPayload();
    this.isResendOtpClicked = true;
    this.smsData.hasCookie = this.cookieService.check('ad-otp-form-cookie');
    this.smsService.sendOTP(this.smsData).subscribe(res => {
      if (res.success) {
        this.calculateRemainingTime(res);
      } else {
        this.handleErrors(res);
      }
    });
  }

  calculateRemainingTime(res: ApiResponse): void {
    let expiryTime = new Date(
      this.localStorageService.getExpiryTime()
    ).getTime();

    if (res.success === true) {
      expiryTime = new Date(res.data['expires_at']).getTime();
    }

    this.timerId = setInterval(() => {
      const currentTime = new Date().getTime();
      const difference = expiryTime - currentTime;
      if (difference <= 0) {
        this.disableTimer();
        return;
      }
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      this.remainingTime = difference;
      this.remainingTimeStr = `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
    }, 1000);
  }

  disableTimer(): void {
    this.remainingTimeStr = '00:00';
    this.remainingTime = 0;
    this.isResendOtpClicked = false;
    clearInterval(this.timerId);
  }

  handleErrors(response: ApiResponse): void {
    this.message = response['message'];

    switch (response['data']['errorCode']) {
      case 403:
        this.handle403(response);
        break;
      case 422:
        this.handle422(response);
        break;
      case 404:
        this.handle404(response);
        break;
      default:
        this.handleDefault();
    }

    this.isLoading = false;
    this.otpForm.reset();
  }

  handle403(response: ApiResponse): void {
    this.message = response['message'];
  }
  handle404(response: ApiResponse): void {
    const remainingDuration =
      response['data']['errors']['otp']['remaining_duration'];
    const expiryTime = new Date();
    expiryTime.setSeconds(expiryTime.getSeconds() + remainingDuration);
    this.localStorageService.setExpiryTime(expiryTime.toString());
    this.disableTimer();
  }
  handle422(response: ApiResponse): void {
    this.message = response['message'];
    if (response['data']['errors']['otp']['expires_at']) {
      this.disableTimer();
      this.isResendOtpClicked = true;
      const expiresAt = response['data']['errors']['otp']['expires_at'];
      this.localStorageService.setExpiryTime(expiresAt);
      this.calculateRemainingTime(response);
    }
  }

  handleDefault(): void {
    this.message = 'Something went wrong';
  }
  move(
    e: KeyboardEvent,
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    p: any,
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    c: any,
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    n: any
  ): void {
    const length = c.value.length;
    const maxLength = c.getAttribute('maxlength');
    if (length == maxLength) {
      if (n != '') {
        n.focus();
      }
    }
    if (e.key === 'Backspace') {
      if (p != '') {
        p.focus();
      }
    }
  }

  get dig1(): AbstractControl {
    return this.otpForm.get('dig1');
  }
  get dig2(): AbstractControl {
    return this.otpForm.get('dig2');
  }
  get dig3(): AbstractControl {
    return this.otpForm.get('dig3');
  }
  get dig4(): AbstractControl {
    return this.otpForm.get('dig4');
  }
  get dig5(): AbstractControl {
    return this.otpForm.get('dig5');
  }
}
