/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * This abstract class used for module building by extending this class
 * prevents importing the module into somewhere else than root App Module.
 * See Reference: https://levelup.gitconnected.com/where-shall-i-put-that-core-vs-shared-module-in-angular-5fdad16fcecc
 */
export abstract class EnsureImportedOnceGuard {
  protected constructor(
    //TODO: explicit-module-boundary-types
    //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    targetModule: any
  ) {
    //Handle type any later
    if (targetModule) {
      throw new Error(
        `${targetModule.constructor.name} has already been loaded. Import ${targetModule.constructor.name} modules in the Core Module only.`
      );
    }
  }
}
