<section
  fxLayout.xs="column"
  [ngClass]="'container pt-90'"
  [ngClass.md]="'px-15 pt-md-70'"
  [ngClass.sm]="'px-15 pt-sm-70'"
  [ngClass.xs]="'px-15 pt-sm-70'">
  <h2>{{ header }}</h2>

  <div class="property-results row lg my-40 my-sm-20">
    <ng-container *ngIf="developments">
      <article
        *ngFor="let development of developments"
        aria-label="{{ development.title }}">
        <a [routerLink]="setDevelopmentLink(development)">
          <picture class="cover d-block w-100">
            <ng-container *ngIf="development.image">
              <img
                loading="lazy"
                [src]="development.image | optimizeImage"
                class="w-100 h-100 object-fit-cover object-fit-center"
                [alt]="development.title" />
            </ng-container>
            <ng-container *ngIf="development.image === null">
              <img
                loading="lazy"
                [src]="setDefaultImage(development.classification)"
                class="w-100 h-100 object-fit-cover object-fit-center"
                [alt]="development.title" />
            </ng-container>
          </picture>
          <span class="wrapper">
            <h4 class="name">
              {{ development.title }}
            </h4>
            <div class="details d-grid align-items-center">
              <div class="property-type" aria-label="Apartment property type">
                {{ development.classification }}
              </div>
              <ul class="configurations list-inline text-right">
                <ng-container
                  *ngIf="
                    development.bedrooms !== '0' && development.bedrooms !== '-'
                  ">
                  <li aria-label="4 bedrooms">
                    <img
                      loading="lazy"
                      [src]="
                        cdnBasePath + '/assets/icon/icon-bed.svg'
                          | optimizeImage
                      "
                      class="icon"
                      alt="Bed" />
                    <span>{{ development.bedrooms }}</span>
                  </li>
                </ng-container>
                <ng-container
                  *ngIf="
                    development.bathrooms !== '0' &&
                    development.bathrooms !== '-'
                  ">
                  <li aria-label="2 bathrooms">
                    <img
                      loading="lazy"
                      [src]="
                        cdnBasePath + '/assets/icon/icon-bath.svg'
                          | optimizeImage
                      "
                      class="icon"
                      alt="Bath" />
                    <span>{{ development.bathrooms }}</span>
                  </li>
                </ng-container>
                <ng-container
                  *ngIf="
                    development.carSpaces !== '0' &&
                    development.carSpaces !== '-'
                  ">
                  <li aria-label="2 car spaces">
                    <img
                      loading="lazy"
                      [src]="
                        cdnBasePath + '/assets/icon/icon-car.svg'
                          | optimizeImage
                      "
                      class="icon"
                      alt="Car" />
                    <span>{{ development.carSpaces }}</span>
                  </li>
                </ng-container>
              </ul>
              <div class="location">
                {{ development.address.area }},
                {{ development.address.state }}
              </div>
              <ng-container
                *ngIf="
                  development.priceSearch != 0 &&
                  development.priceSearch !== null
                ">
                <div class="price text-right">
                  from ${{ development.priceSearch }}
                </div>
              </ng-container>
            </div>
          </span>
        </a>
      </article>
    </ng-container>
  </div>
  <div class="text-center">
    <a
      *ngIf="!noTrigger"
      (click)="setFilterLimit.emit()"
      class="btn btn--one btn--lg"
      >{{ buttonText }}</a
    >
  </div>
</section>
