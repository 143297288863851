import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

import { environment } from 'environments/environment';

@Component({
  selector: 'apd-effi',
  templateUrl: './effi.component.html',
  styleUrls: ['./effi.component.scss'],
})
export class EffiComponent implements AfterViewInit {
  @ViewChild('effiContainer') effiContainer: ElementRef;

  @Input() formState: string;
  @Input() hasNavigation: string;
  @Input() calcBrokerList: string;
  @Input() postalCode: string;
  @Input() addressState: string;
  @Input() suburb: string;
  @Input() brokerLimit: string;
  @Input() mainColor: string;
  @Input() secondaryColor: string;
  @Input() textColor: string;
  @Input() tncLink = 'https://a-d.com.au/terms-and-conditions';

  apiBasePath = environment.apiBasePath;
  effiSrc = environment.effiSrc;
  effiClientID = environment.effiClientId;
  scriptClassName = 'effi';
  scriptType = 'text/javascript';
  isBrowser: boolean;

  /**
   * Constructor
   * @param subscriptionService SubscriptionService
   */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit() {
    // TODO: fix no-explicit-any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let script: any,
      shouldAppend = false;

    if (this.isBrowser) {
      if (
        this._document.body.getElementsByClassName(this.scriptClassName).length
      ) {
        script = this._document.body.getElementsByClassName(
          this.scriptClassName
        )[0];
      } else {
        script = this._document.createElement('script');
        shouldAppend = true;
      }

      script.setAttribute('class', this.scriptClassName);
      script.setAttribute('crossOrigin', 'anonymous');
      script.type = this.scriptType;
      script.src = this.apiBasePath + this.effiSrc;

      if (shouldAppend) {
        this.effiContainer.nativeElement.appendChild(script);
        const adComponent = this._document.createElement('ad-effi-form');
        adComponent.setAttribute('clientId', this.effiClientID);
        adComponent.setAttribute('formState', this.formState);
        if (this.hasNavigation)
          adComponent.setAttribute('hasNavigation', this.hasNavigation);
        if (this.calcBrokerList)
          adComponent.setAttribute('calcBrokerList', this.calcBrokerList);
        if (this.mainColor)
          adComponent.setAttribute('mainColor', this.mainColor);
        if (this.secondaryColor)
          adComponent.setAttribute('secondaryColor', this.secondaryColor);
        if (this.postalCode)
          adComponent.setAttribute('postalCode', this.postalCode);
        if (this.addressState)
          adComponent.setAttribute('addressState', this.addressState);
        if (this.suburb) adComponent.setAttribute('suburb', this.suburb);
        if (this.textColor)
          adComponent.setAttribute('textColor', this.textColor);
        if (this.tncLink) adComponent.setAttribute('tncLink', this.tncLink);
        if (this.brokerLimit)
          adComponent.setAttribute('brokerLimit', this.brokerLimit);

        this.effiContainer.nativeElement.append(adComponent);
      }
    }
  }
}
