<header
  class="{{ theme }} main-menu"
  [ngClass]="{ 'mobile-hidden': mobileHidden }">
  <div
    class="d-grid align-items-center"
    [ngClass]="{ container: mode === 'simple' }">
    <div class="logo-container">
      <ng-container *ngIf="brandLogo && brandUrl; else mainLogo">
        <a [href]="brandUrl" target="_blank">
          <div
            class="brand-logo"
            [ngStyle]="{
              'background-image': 'url(' + brandLogo + ')'
            }"></div>
        </a>
      </ng-container>
      <ng-template #mainLogo>
        <a [routerLink]="currentLang + '/'">
          <div class="main-logo">
            <img
              loading="lazy"
              alt="down-chevron"
              [src]="
                cdnBasePath +
                  '/assets/images/logo-apartments_developments-black.svg'
                  | optimizeImage
              " />
          </div>
        </a>
      </ng-template>
    </div>

    <apd-search-bar
      class="search-bar"
      [trackKeyWord]="trackKeyWord"
      [mode]="mode"
      [showSearchBar]="showSearchBar"
      [isSearch]="isSearchPage"
      (hidePopupEventEmitter)="hidePopupEventEmitter.emit()"
      (toggleMobileFilter)="toggleMobileFilter.emit($event)">
    </apd-search-bar>

    <div class="menu text-right" *ngIf="mode != 'simple'">
      <nav>
        <ul class="list-inline">
          <li class="desktop">
            <ul>
              <li
                (click)="toggleStateDropdown($event, 'development')"
                [ngClass]="{ active: dropdowns.development }"
                class="field-select position-relative d-inline desktop">
                {{ activeState === undefined ? 'Developments' : activeState }}

                <img
                  loading="lazy"
                  alt="down-chevron"
                  [src]="
                    cdnBasePath + '/assets/icon/down-chevron.svg'
                      | optimizeImage
                  "
                  class="icon" />

                <ul
                  [@slideUpDown]="dropdowns.development ? 'down' : 'up'"
                  class="field-select_options dropdown-item-container text-left">
                  <li *ngFor="let state of menu[0].data; index as i">
                    <a
                      [routerLink]="
                        '/new-apartments-developments/' + state.shortValue
                      ">
                      {{ state.name }}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                (click)="toggleStateDropdown($event, 'news')"
                [ngClass]="{ active: dropdowns.news }"
                class="field-select position-relative d-inline desktop">
                {{ newsMenu }}
                <img
                  loading="lazy"
                  alt="down-chevron"
                  [src]="
                    cdnBasePath + '/assets/icon/down-chevron.svg'
                      | optimizeImage
                  "
                  class="icon" />

                <ul
                  [@slideUpDown]="dropdowns.news ? 'down' : 'up'"
                  class="field-select_options dropdown-item-container text-left">
                  <li *ngFor="let news of menu[1].data; index as i">
                    <a (click)="newsMenu = news.name" [routerLink]="news.url">
                      {{ news.name }}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                (click)="toggleStateDropdown($event, 'profile')"
                [ngClass]="{ active: dropdowns.profile }"
                class="field-select position-relative d-inline desktop">
                {{ industryProfilesMenu }}
                <img
                  loading="lazy"
                  alt="down-chevron"
                  [src]="
                    cdnBasePath + '/assets/icon/down-chevron.svg'
                      | optimizeImage
                  "
                  class="icon" />

                <ul
                  [@slideUpDown]="dropdowns.profile ? 'down' : 'up'"
                  class="field-select_options dropdown-item-container text-left">
                  <li *ngFor="let profile of menu[2].data; index as i">
                    <a
                      (click)="industryProfilesMenu = profile.name"
                      [routerLink]="profile.url">
                      {{ profile.name }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="mobile">
            <a (click)="toggleMobileMenu()" class="open-nav">
              <span class="icon menu">
                <img
                  loading="lazy"
                  width="46.2"
                  height="26"
                  [src]="cdnBasePath + '/assets/icon/menu.svg' | optimizeImage"
                  alt="Menu" />
              </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <!-- Mobile menu -->
  <div
    *ngIf="showMobileMenu"
    class="mobile-menu nav position-fixed w-100 h-100">
    <a (click)="toggleMobileMenu()" class="close"
      ><img
        loading="lazy"
        [src]="cdnBasePath + '/assets/icon/close.svg' | optimizeImage"
        alt="Close"
    /></a>
    <ul class="list-unstyled mobile-dropdown">
      <li (click)="toggleMobileDropdown('developments')">
        <a
          [ngClass]="{
            'dropdown-active': mobileNavtoggles.developments
          }"
          >Developments</a
        >
        <mat-icon
          [ngClass]="{
            'dropdown-active': mobileNavtoggles.developments
          }"
          aria-hidden="false"
          aria-label="expand">
          {{ mobileNavtoggles.developments ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </li>

      <li
        class="accordion"
        [ngClass]="{ 'opened-mini': mobileNavtoggles.developments }">
        <div class="nav-dropdown-items">
          <ul class="list-unstyled">
            <li
              *ngFor="let state of menu[0].data; index as i"
              [routerLink]="'/new-apartments-developments/' + state.shortValue">
              {{ state.name }}
            </li>
          </ul>
        </div>
      </li>

      <li (click)="toggleMobileDropdown('news')">
        <a
          [ngClass]="{
            'dropdown-active': mobileNavtoggles.news
          }"
          >Property News</a
        >
        <mat-icon
          [ngClass]="{
            'dropdown-active': mobileNavtoggles.news
          }"
          aria-hidden="false"
          aria-label="expand">
          {{ mobileNavtoggles.news ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </li>
      <li
        class="accordion"
        [ngClass]="{ 'opened-mini': mobileNavtoggles.news }">
        <div class="nav-dropdown-items">
          <ul class="list-unstyled">
            <li
              *ngFor="let news of menu[1].data; index as i"
              [routerLink]="news.url">
              {{ news.name }}
            </li>
          </ul>
        </div>
      </li>

      <li (click)="toggleMobileDropdown('industryProfiles')">
        <a
          [ngClass]="{
            'dropdown-active': mobileNavtoggles.industryProfiles
          }"
          >Industry Profiles</a
        >
        <mat-icon
          [ngClass]="{
            'dropdown-active': mobileNavtoggles.industryProfiles
          }"
          aria-hidden="false"
          aria-label="expand">
          {{
            mobileNavtoggles.industryProfiles ? 'expand_less' : 'expand_more'
          }}
        </mat-icon>
      </li>
      <li
        class="accordion"
        [ngClass]="{ 'opened-mini': mobileNavtoggles.industryProfiles }">
        <div class="nav-dropdown-items">
          <ul class="list-unstyled">
            <li
              *ngFor="let profile of menu[2].data; index as i"
              [routerLink]="profile.url">
              {{ profile.name }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div class="social">
      <a
        href="https://www.facebook.com/apartmentdevelopments/"
        rel="noopener"
        target="_blank">
        <img
          loading="lazy"
          [src]="cdnBasePath + '/assets/icon/facebook.svg' | optimizeImage"
          alt="Facebook" />
      </a>
      <a
        href="https://www.instagram.com/Apartments_Developments/"
        rel="noopener"
        target="_blank">
        <img
          loading="lazy"
          [src]="cdnBasePath + '/assets/icon/instagram.svg' | optimizeImage"
          alt="Instagram"
          id="instagram" />
      </a>
      <a
        href="https://www.linkedin.com/company/adgroup/"
        rel="noopener"
        target="_blank">
        <img
          loading="lazy"
          [src]="cdnBasePath + '/assets/icon/linkedin.svg' | optimizeImage"
          alt="Linkedin" />
      </a>
      <a
        href="https://www.youtube.com/channel/UC0aX7jZzYvCIFVa0yXGj0Ag"
        rel="noopener"
        target="_blank">
        <img
          loading="lazy"
          [src]="cdnBasePath + '/assets/icon/youtube.svg' | optimizeImage"
          alt="Youtube"
          id="youtube" />
      </a>
      <a
        href="https://www.pinterest.com.au/aptdevelopments/"
        rel="noopener"
        target="_blank">
        <img
          loading="lazy"
          [src]="cdnBasePath + '/assets/icon/pinterest.svg' | optimizeImage"
          alt="Pinterest" />
      </a>
    </div>
  </div>
</header>
