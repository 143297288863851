<div
  *ngIf="articles['data'].length > 0 && display === true"
  class="pt-60 pb-90 px-15 pb-sm-60 pt-sm-20">
  <section>
    <div class="container lg">
      <div class="title-w-search">
        <div class="title">
          <h2 class="h1">{{ category }}</h2>
        </div>

        <apd-article-search-bar (searchEventHandler)="onSearch($event)">
        </apd-article-search-bar>
      </div>

      <div class="latest-buying_living col--3 mt-40 mt-sm-20">
        <article
          *ngFor="let article of articles.data | paginate: articles.config">
          <a
            [routerLink]="
              currentLang +
              '/buying-living/' +
              article.category.slug +
              '/' +
              article.slug.name
            ">
            <picture class="position-relative d-block">
              <ng-container *ngIf="article.images.length > 0">
                <img
                  loading="lazy"
                  [src]="article.images[0].url | optimizeImage"
                  [alt]="
                    article.images[0].altTag
                      ? article.images[0].altTag
                      : article.title
                  "
                  class="object-fit-cover object-position-center w-100 h-100" />
              </ng-container>
              <ng-container *ngIf="article.images.length == 0">
                <img
                  loading="lazy"
                  [src]="
                    cdnBasePath + '/assets/default/default-project.jpg'
                      | optimizeImage
                  "
                  [alt]="article.title"
                  class="object-fit-cover object-position-center w-100 h-100" />
              </ng-container>
            </picture>
          </a>
          <div class="wrapper">
            <a
              [routerLink]="
                currentLang + '/buying-living/' + article.category.slug
              "
              class="category d-inline-block">
              {{ category }}
            </a>
            <h4 class="title">
              <a
                [routerLink]="
                  currentLang +
                  '/buying-living/' +
                  article.category.slug +
                  '/' +
                  article.slug.name
                "
                class="d-block">
                {{ article.title }}
              </a>
            </h4>
            <div class="tags">
              <a
                *ngFor="let tag of article.tags"
                (click)="searchByTag(tag.slug)">
                {{ tag.name }}
              </a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</div>

<div *ngIf="articles['pagination'].totalPages > 0 && display === true">
  <apd-paginator-dark
    [config]="articles.config"
    (pageChangeEventHandler)="onPageChange($event)"></apd-paginator-dark>
</div>
