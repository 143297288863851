import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

import { SessionService } from '../services/session/session.service';

@Injectable()
export class AuthGuard implements CanLoad {
  constructor(private router: Router, private sessionService: SessionService) {}

  canLoad(): boolean {
    if (this.sessionService.currentUserValue) {
      // logged in so return true
      return this.sessionService.currentUserValue != undefined;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login']);
    return false;
  }
}
