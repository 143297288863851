import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

import { ButtonStrokedComponent } from './stroked.component';

@NgModule({
  declarations: [ButtonStrokedComponent],
  imports: [FlexLayoutModule, MatButtonModule],
  exports: [ButtonStrokedComponent],
})
export class ButtonStrokedModule {}
