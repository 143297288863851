import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';

import { ApiService } from '../api.service';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private sessionService: SessionService
  ) {}
  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login(username: string, password: string): Observable<any> {
    const params = {
      data: {
        email: username,
        password: password,
      },
    };

    return this.apiService.post('/auth/login', params);
  }

  logout(): void {
    this.sessionService.destroySession();
    this.router.navigate([`/auth/login`]);
  }

  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleErrorAuth(error: any): Observable<any> {
    return throwError(error);
  }
}
