import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

import { ButtonDefaultComponent } from './default.component';

@NgModule({
  declarations: [ButtonDefaultComponent],
  imports: [FlexLayoutModule, MatButtonModule, MatRippleModule],
  exports: [ButtonDefaultComponent],
})
export class ButtonDefaultModule {}
