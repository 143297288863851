<section class="bg--two py-90 px-15 py-sm-60">
  <div class="container">
    <h2>Explore the top suburbs in {{ state }} for investment properties</h2>
    <ul class="suburb-profile-results list-inline d-grid col--4 col--sm-2">
      <li *ngFor="let suburb of stateSuburbs">
        <a
          [routerLink]="
            currentLang +
            '/new-apartments-developments/' +
            suburb.state.shortState +
            '/' +
            suburb.slug.name
          ">
          {{ suburb.title }}
        </a>
      </li>
    </ul>
    <ng-container *ngIf="isLoading">
      <div class="suburb-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </ng-container>
    <ng-container *ngIf="!hideButton">
      <div class="text-center">
        <apd-button-default
          [label]="'Show more suburbs'"
          [defaultClass]="'btn--one btn--md'"
          (clickEventHandler)="showMore()">
        </apd-button-default>
      </div>
    </ng-container>
  </div>
</section>
