import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { MapToggleModule } from '@core/layout/components/map-toggle/map-toggle.module';
import { SearchFilterModule } from '@core/layout/components/search-filter/search-filter.module';

import { FilterBarModule } from '../search-filter/bar/filter-bar.module';
import { SearchBarComponent } from './search-bar.component';

@NgModule({
  declarations: [SearchBarComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SearchFilterModule,
    FilterBarModule,
    MapToggleModule,
  ],
  exports: [SearchBarComponent],
})
export class SearchBarModule {}
