import { createAction, props } from '@ngrx/store';

import { SearchFilter } from '@core/types';

const UPDATE_FILTER = 'UPDATE FILTER';
const RESET_FILTER = 'RESET FILTER';

export const updateFilter = createAction(
  UPDATE_FILTER,
  props<{ searchFilter: SearchFilter }>()
);

export const resetFilter = createAction(RESET_FILTER);
