import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  DevelopmentBoundQuery,
  DevelopmentQuery,
  DevelopmentSearchQuery,
} from '@core/types';
import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class DevelopmentService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get developments
   *
   * @returns {Observable<ApiResponse>}
   */
  getDevelopments(params: DevelopmentQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/developments', httpParams)
      .pipe(map(response => response));
  }

  /**
   * Get developments
   *
   * @returns {Observable<ApiResponse>}
   */
  getDevelopmentByIdentifier(
    identifier: string,
    params: DevelopmentQuery = {}
  ): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get(`/development/${identifier}`, httpParams)
      .pipe(map(response => response));
  }

  /**
   * Search by development
   * @returns {Observable<ApiResponse>}
   */
  developmentSearch(params: DevelopmentSearchQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/developments/search', httpParams)
      .pipe(map(response => response));
  }

  developmentSearchByBounds(
    params: DevelopmentBoundQuery
  ): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/developments/bounds', httpParams)
      .pipe(map(response => response));
  }

  developmentBundledSearch(
    params: DevelopmentSearchQuery
  ): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/developments/bundled', httpParams)
      .pipe(map(response => response));
  }
}
