import { createAction, props } from '@ngrx/store';

const UPDATE_ACTION = 'UPDATE MOBILE FILTER TOGGLE';
const RESET_ACTION = 'RESET MOBILE FILTER TOGGLE';

export const updateMobileFilterToggle = createAction(
  UPDATE_ACTION,
  props<{ toggleFromMapFilter: boolean }>()
);

export const resetMobileFilterToggle = createAction(RESET_ACTION);
