import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { BarGraphComponent } from '@core/layout/components/bar-graph/bar-graph.component';
import { ButtonDefaultModule } from '@core/layout/components/material/buttons/default/default.module';

@NgModule({
  declarations: [BarGraphComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    FlexLayoutModule,

    ButtonDefaultModule,
  ],
  exports: [BarGraphComponent],
})
export class BarGraphModule {}
