import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'apd-anchor-stroked',
  templateUrl: './stroked.component.html',
  styleUrls: ['./stroked.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AnchorStrokedComponent {
  // custom props
  @Input() label: string;

  // flexLayout props
  @Input() mdFlex: string;
  @Input() smFlex: string;
  @Input() xsFlex: string;
  @Input() defaultClass: string;
  @Input() fullWidth = false;
  @Input() fullHeight = false;
  @Input() mdClass: string;
  @Input() smClass: string;
  @Input() xsClass: string;
  @Input() id: string;
  @Input() customHref: string;

  // material props
  @Input() disabled: boolean;
  @Input() hidden = false;
  @Output() clickEventHandler = new EventEmitter<void>();

  onClick(): void {
    this.clickEventHandler.emit();
  }
}
