import { createReducer, on } from '@ngrx/store';

import { resetKeyword, updateKeyword } from './search-keyword.actions';

export const initialState = '';

export const searchKeywordReducer = createReducer(
  initialState,
  on(updateKeyword, (state, { searchKeyword }) => searchKeyword),
  on(resetKeyword, () => initialState)
);
