import { Component, Input } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'apd-featured',
  templateUrl: './featured.component.html',
  styleUrls: ['./featured.component.scss'],
})
export class DevelopmentFeaturedComponent {
  @Input() type: string;

  isMobile = false;
  /**
   * Constructor
   * @param {Router} router
   * @param {ActivatedRoute} activatedRoute
   * @param {DevelopmentService} developmentService
   * @param {StateService} stateService
   * @param {SuburbService} suburbService
   * @param {MetaDataService} metaDataService
   * @param {LocalStorageService} localStorageService
   * @param {MatDialog} dialog
   */
  constructor(private deviceDetectorService: DeviceDetectorService) {
    this.isMobile = this.deviceDetectorService.isMobile();
  }
}
