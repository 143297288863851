import { createAction, props } from '@ngrx/store';

const UPDATE_LOCATION = 'UPDATE LOCATION';
const RESET_LOCATION = 'RESET LOCATION';

export const updateLocation = createAction(
  UPDATE_LOCATION,
  // TODO: fix no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ searchLocation: any }>()
);

export const resetLocation = createAction(RESET_LOCATION);
