import { FuseNavigation } from '@shared/@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'NAV.HOME.TITLE',
    type: 'item',
  },
  {
    id: 'search',
    title: 'Search',
    translate: 'NAV.SEARCH.TITLE',
    type: 'item',
  },
];
