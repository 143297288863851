<section class="related-buying_living-wrapper bg--three py-90 px-15 py-sm-60">
  <div class="container lg">
    <div class="top">
      <h3 class="h2 header">
        {{ header }}
      </h3>
      <div class="d-grid align-items-end col--sm-1">
        <p>
          <ng-template [ngIf]="subHeader" [ngIfElse]="elseBlock">{{
            subHeader
          }}</ng-template>
          <ng-template #elseBlock>
            {{ 'HOME.TITLES.BUYING_LIVING.SUB.DEFAULT' | translate }}
          </ng-template>
        </p>
        <div class="wrapper button d-sm-none">
          <a
            [routerLink]="currentLang + '/buying-living'"
            class="btn btn--one btn--md">
            {{ 'HOME.BUTTON_TEXT.SEE_ALL_ARTICLES' | translate }}
          </a>
        </div>
      </div>
    </div>

    <div class="related-buying_living d-grid col--3 col--sm-1">
      <article *ngFor="let article of articles">
        <a
          [routerLink]="
            currentLang +
            '/buying-living/' +
            article.category.slug +
            '/' +
            article.slug.name
          ">
          <picture class="position-relative d-block">
            <ng-container *ngIf="article.images.length > 0">
              <img
                loading="lazy"
                [src]="article.images[0].url | optimizeImage"
                [alt]="article.title"
                class="object-fit-cover object-position-center w-100 h-100" />
            </ng-container>
            <ng-container *ngIf="article.images.length == 0">
              <img
                loading="lazy"
                [src]="
                  cdnBasePath + '/assets/default/default-project.jpg'
                    | optimizeImage
                "
                alt="Green space to call your own"
                class="object-fit-cover object-position-center w-100 h-100" />
            </ng-container>
          </picture>
        </a>
        <div class="wrapper">
          <a
            [routerLink]="
              currentLang + '/buying-living/' + article.category.slug
            "
            class="category d-inline-block"
            >{{ article.category.name }}</a
          >
          <h4 class="title">
            <a
              [routerLink]="
                currentLang +
                '/buying-living/' +
                article.category.slug +
                '/' +
                article.slug.name
              "
              class="d-block">
              {{ article.title }}
            </a>
          </h4>
          <div class="tags">
            <a
              *ngFor="let tag of article.tags"
              [routerLink]="currentLang + '/buying-living/'"
              [state]="{ tag: tag }"
              >{{ tag.name }}</a
            >
          </div>
        </div>
      </article>
    </div>

    <div class="text-center mt-30 d-none d-sm-block">
      <a
        [routerLink]="currentLang + '/buying-living'"
        class="btn btn--one btn--lg">
        {{ 'HOME.BUTTON_TEXT.SEE_ALL_ARTICLES' | translate }}
      </a>
    </div>
  </div>
</section>
