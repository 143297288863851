import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class SplashService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get Splash Assets Based on Brand
   *
   * @param string email
   * @returns {Observable<ApiResponse>}
   */
  getSplashAssets(brand: string, slug: string): Observable<ApiResponse> {
    return this.apiService
      .get(`/splash/${brand}/${slug}`)
      .pipe(map(response => response));
  }
}
