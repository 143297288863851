import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DisplayStateTabPipe } from '@core/layout/components/footer/display-state-tab.pipe';
import { FooterComponent } from '@core/layout/components/footer/footer.component';
import { SubscribeModule } from '@core/layout/components/subscribe/subscribe.module';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [FooterComponent, DisplayStateTabPipe],
  imports: [
    TranslateModule.forChild(),
    RouterModule,
    FormsModule,
    CommonModule,
    SubscribeModule,

    MatProgressSpinnerModule,
    SharedModule,
  ],
  exports: [FooterComponent],
})
export class FooterModule {}
