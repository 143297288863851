<div class="search-bar">
  <form [formGroup]="searchForm" (ngSubmit)="onSearch()" novalidate>
    <input
      formControlName="keyword"
      type="text"
      class="search"
      placeholder="Search by keyword" />
    <div class="wrapper submit">
      <i class="search-icon" (click)="onSearch()"></i>
    </div>
  </form>
</div>
