import { createReducer, on } from '@ngrx/store';

import { SearchFilter } from '@core/types';

import { resetFilter, updateFilter } from './search-filter.actions';

export const initialState: SearchFilter = {
  propertyType: {
    all: true,
    apartments: false,
    townhouses: false,
    newLandEstates: false,
    penthouses: false,
    prestigeHomes: false,
    villas: false,
  },
  bedrooms: {
    oneBed: false,
    twoBeds: false,
    threeBeds: false,
    fourBeds: false,
    fivePlusBeds: false,
  },
  bathrooms: {
    oneBath: false,
    twoBath: false,
    threeBath: false,
    fourBath: false,
    fivePlusBath: false,
  },
  carSpaces: {
    oneSpace: false,
    twoSpace: false,
    threeSpace: false,
    fourSpace: false,
    fivePlusSpace: false,
  },
  price: {
    min: 'Any',
    max: 'Any',
  },
};

export const searchFilterReducer = createReducer(
  initialState,
  on(updateFilter, (state, { searchFilter }) => {
    return { ...searchFilter };
  }),
  on(resetFilter, () => initialState)
);
