import { Pipe, PipeTransform } from '@angular/core';

interface UrlObj {
  url: string;
  altTag: string;
}

@Pipe({
  name: 'urlEnding',
})
export class UrlEndingPipe implements PipeTransform {
  transform(url: string | UrlObj): string {
    let lastPart;

    if (typeof url === 'object') {
      lastPart = url.url.split('/').pop();
    } else {
      lastPart = url.split('/').pop();
    }

    const fileName = lastPart.substring(0, lastPart.lastIndexOf('.'));

    return fileName;
  }
}
