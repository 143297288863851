/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '@core/services';
import { ApiResponse, TagArticleQuery } from '@core/types';

@Injectable()
export class SearchService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService, private http: HttpClient) {}

  /**
   * Search articles
   * @returns {Observable<ApiResponse>}
   */
  searchArticles(
    queryParams?: string | TagArticleQuery
  ): Observable<ApiResponse> {
    const httpParams =
      typeof queryParams === 'object'
        ? '?' + this.apiService.setHttpParams(queryParams)
        : queryParams;

    return this.apiService.get('/articles/search' + httpParams);
  }

  /**
   * Get Postal Lookup Assets
   * @returns {Observable<any>}
   */
  getPostalLookup(): Observable<any> {
    const jsonURL = '/assets/json/postal-lookup.json';

    return this.http.get(jsonURL).pipe(catchError(this.formatErrors));
  }

  /**
   * Get Region Lookup Assets
   * @returns {Observable<any>}
   */
  getRegionLookup(): Observable<any> {
    const jsonURL = '/assets/json/region-lookup.json';

    return this.http.get(jsonURL).pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
