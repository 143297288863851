<section
  fxLayout.xs="column"
  [ngClass]="'container py-90'"
  [ngClass.md]="'px-15 pt-md-70'"
  [ngClass.sm]="'px-15 pt-sm-70'"
  [ngClass.xs]="'px-15 pt-sm-70'"
  *ngIf="viewable()">
  <h2>{{ header }}</h2>
  <ng-container *ngIf="profiles">
    <div *ngIf="profiles.length === 0" class="no-related-profiles">
      We could not find any related profiles for.
    </div>
    <ng-container *ngIf="profiles.length > 0">
      <div class="profile-tiles d-grid col--3 col--sm-1 mt-40 mb-50 my-sm-20">
        <ng-container *ngFor="let profile of profiles">
          <a
            [routerLink]="
              '/new-apartments-developments/' +
              profile.state.shortState +
              '/' +
              profile.slug.name
            "
            class="tile position-relative">
            <ng-container
              *ngFor="let image of profile.files.image; let i = index">
              <img
                loading="lazy"
                src="{{ image.url }}"
                alt="image.name"
                class="bg--cover" />
            </ng-container>
            <ng-container *ngIf="!profile.files.image">
              <img
                loading="lazy"
                [src]="cdnBasePath + '/assets/default/apartments.jpg'"
                alt="{{ profile.title }}"
                class="bg--cover" />
            </ng-container>
            <div class="overlay w-100 position-relative">
              <span class="profile-type">{{ type }}</span>
              <h4 class="suburb-name">{{ profile.title }}</h4>
            </div>
          </a>
        </ng-container>
      </div>

      <div class="text-center" *ngIf="!noTrigger">
        <a (click)="setFilterLimit.emit()" class="btn btn--one btn--lg">{{
          buttonText
        }}</a>
      </div>
    </ng-container>
  </ng-container>
</section>
