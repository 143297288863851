import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgxPaginationModule } from 'ngx-pagination';

import { SharedModule } from '@shared/shared.module';

import { PaginatorDarkComponent } from './dark.component';

@NgModule({
  declarations: [PaginatorDarkComponent],
  imports: [CommonModule, RouterModule, NgxPaginationModule, SharedModule],
  exports: [PaginatorDarkComponent],
})
export class PaginatorDarkModule {}
