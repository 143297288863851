import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'environments/environment';

import { LanguageService } from '@core/services/language/language.service';
import { Article as ArticleModel } from '@core/types/articles/article.model';

@Component({
  selector: 'apd-article-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class ArticleCategoriesComponent implements OnInit {
  @Input() display: boolean;
  @Input() category: string;
  @Input() articles: Array<ArticleModel>;
  @Output() triggerSearchByTag = new EventEmitter<string>();

  currentLang: string;

  cdnBasePath = environment.cdnBasePath;

  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.languageService.getCurrentLang();

    /**
     * Redirect to new page and show top contents
     */
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  searchByTag(tag: string): void {
    this.triggerSearchByTag.emit(tag);
  }
}
