export class FusePerfectScrollbarGeometry {
  x: number;
  y: number;

  w: number;
  h: number;

  constructor(x: number, y: number, w: number, h: number) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
  }
}

export class FusePerfectScrollbarPosition {
  x: number | 'start' | 'end';
  y: number | 'start' | 'end';

  constructor(x: number | 'start' | 'end', y: number | 'start' | 'end') {
    this.x = x;
    this.y = y;
  }
}
