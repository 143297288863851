<div class="audio-container" #audioContainer>
  <link
    href="https://trinitymedia.ai"
    rel="preconnect"
    crossorigin="anonymous" />
  <link
    href="https://vd.trinitymedia.ai"
    rel="preconnect"
    crossorigin="anonymous" />

  <script class="audio"></script>
</div>
