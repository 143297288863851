import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';

import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

import { CustomGTMService } from '@core/helpers';
import { LocalStorageService } from '@core/services';
import { Development, Enquirer, Properties } from '@core/types';

import { SmsDialogComponent } from '../sms-dialog/sms-dialog.component';

@Component({
  selector: 'leads-custom-dialog',
  template: '',
})
export class LeadsCustomDialogComponent {
  dialogAttributes = {
    backdropClass: 'leads-dialog-backdrop',
    panelClass: 'leads-dialog-panel',
    scrollStrategy: new NoopScrollStrategy(),
  };

  constructor(public dialog: MatDialog) {}

  @Input() enquirer: Enquirer;
  @Input() development: Development;
  @Input() property: Properties;
  @Output() formClosed = new EventEmitter<Event>();

  openDialog(targetClassName = ''): void {
    const dialogRef = this.dialog.open(LeadsDialogComponent, {
      ...this.dialogAttributes,
      data: {
        enquirer: this.enquirer,
        property: this.property,
        development: this.development,
        elementClassName: targetClassName,
      },
    });
    dialogRef.afterClosed().subscribe(() => this.formClosed.emit());
  }
}

@Component({
  selector: 'leads-dialog',
  templateUrl: './leads-dialog.component.html',
  styleUrls: ['./leads-dialog.component.scss'],
})
export class LeadsDialogComponent {
  enquirer: Enquirer;
  development: Development;
  property: Properties;
  brochureEnquiry = false;
  cdnBasePath = environment.cdnBasePath;

  defaultEnquirer: Enquirer = {
    scheduleInspection: false,
    requestFloorPlans: false,
    infomationKit: false,
    priceInformation: false,
    brochure: false,
    similarProperties: false,
    message: '',
    name: '',
    email: '',
    mobile: '',
    audience: '',
    priceRange: '',
    lookingToBuy: '',
    property: null,
    development: null,
  };
  dialogAttributes = {
    backdropClass: 'leads-dialog-backdrop',
    panelClass: 'leads-dialog-panel',
    scrollStrategy: new NoopScrollStrategy(),
  };

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LeadsCustomDialogComponent>,
    private localStorageService: LocalStorageService,
    private gtmService: CustomGTMService,
    private cookieService: CookieService,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      enquirer: Enquirer;
      development: Development;
      property: Properties;
      brochureEnquiry: boolean;
      elementClassName: string;
    }
  ) {
    const {
      enquirer,
      property,
      development,
      brochureEnquiry,
      elementClassName,
    } = this.data;
    this.enquirer = enquirer;
    this.development = development;
    this.property = property;
    this.brochureEnquiry = typeof brochureEnquiry != 'undefined' ? true : false;

    if (!this.enquirer) {
      this.enquirer = {
        ...this.defaultEnquirer,
      };
    }

    if (this.brochureEnquiry) {
      this.enquirer.brochure = true;
    }

    const enquirerDetails = this.localStorageService.getEnquirerDetails();

    if (enquirerDetails) {
      this.enquirer = {
        ...this.enquirer,
        ...enquirerDetails,
      };
    }

    this.gtmService.pushProjectClickTag(this.development, elementClassName);
  }
  close(isVerified: boolean): void {
    this.dialogRef.close();
    const hasCookie = this.cookieService.check('ad-otp-form-cookie');
    if (!isVerified || !hasCookie) {
      this.dialog.open(SmsDialogComponent, {
        ...this.dialogAttributes,
      });
    }
  }
}
