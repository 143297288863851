import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TagQuery } from '@core/types';
import { ApiResponse } from '@core/types/api-response.model';

import { ApiService } from '../api.service';

@Injectable()
export class TagService {
  /**
   * Constructor
   *
   * @param {ApiService} apiService
   */
  constructor(private apiService: ApiService) {}

  /**
   * Get tags
   *
   * @param {TagQuery} params
   * @returns {Observable<ApiResponse>}
   */
  getTags(params: TagQuery): Observable<ApiResponse> {
    const httpParams = this.apiService.setHttpParams(params);
    return this.apiService
      .get('/tags', httpParams)
      .pipe(map(response => response));
  }

  /**
   * Get a tag
   *
   * @param {Id} id
   * @returns {Observable<ApiResponse>}
   */
  getTag(id: string): Observable<ApiResponse> {
    return this.apiService.get(`/tags/${id}`).pipe(map(response => response));
  }
}
