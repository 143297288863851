import { Injectable } from '@angular/core';

import { StateDetail } from '@core/types/common/state-detail.model';

import { Constants } from '@common/constants';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  states: Array<StateDetail> = Constants.states;

  getName(value: string): string | null {
    const result = this.states.find(
      state =>
        state.name === value ||
        state.slug === value ||
        state.shortValue === value
    );
    return result !== undefined ? result.name : null;
  }

  getSlug(value: string): string | null {
    const result = this.states.find(
      state =>
        state.name === value ||
        state.shortValue === value ||
        state.slug === value
    );
    return result !== undefined ? result.slug : null;
  }

  getShortValue(value: string): string | null {
    const result = this.states.find(
      state =>
        state.name === value ||
        state.slug === value ||
        state.shortValue === value
    );
    return result !== undefined ? result.shortValue : null;
  }

  getAll(value: string): StateDetail | null {
    return this.states.find(
      state =>
        state.name === value ||
        state.slug === value ||
        state.shortValue === value
    );
  }
}
