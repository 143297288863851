import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonDefaultModule } from '@core/layout/components/material/buttons/default/default.module';

import { SuburbsComponent } from './suburbs.component';

@NgModule({
  declarations: [SuburbsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,

    MatProgressSpinnerModule,
    ButtonDefaultModule,
  ],
  exports: [SuburbsComponent],
})
export class SuburbsModule {}
