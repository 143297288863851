import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from 'environments/environment';

import { HeaderComponent } from '@core/layout/components/header/header.component';
import { SearchBarModule } from '@core/layout/components/search-bar/search-bar.module';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatIconModule,
    SearchBarModule,

    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_CLIENT_ID,
      libraries: ['places'],
    }),

    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
