import {
  animate,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const SlideAnimation = [
  trigger('slideUpDown', [
    state(
      'down',
      style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible',
      })
    ),

    state(
      'up',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden',
      })
    ),

    transition('up => down', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            visibility: 'visible',
          })
        ),
        animate(
          '400ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
        animate(
          '500ms ease-in-out',
          style({
            'max-height': '500px',
          })
        ),
      ]),
    ]),

    transition('down => up', [
      group([
        animate(
          '1000ms linear',
          style({
            visibility: 'hidden',
          })
        ),
        animate(
          '400ms linear',
          style({
            'max-height': '0px',
          })
        ),
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          })
        ),
      ]),
    ]),
  ]),

  trigger('slideToTop', [
    state(
      'down',
      style({
        opacity: '0',
        visibility: 'hidden',
      })
    ),

    state(
      'up',
      style({
        opacity: '1',
        visibility: 'visible',
      })
    ),

    transition('up => down', [
      group([
        animate(
          '400ms linear',
          style({
            transform: 'translateY(45vh)',
          })
        ),
      ]),
    ]),

    transition('down => up', [
      group([
        animate(
          '400ms linear',
          style({
            transform: 'translateY(250px)',
          })
        ),
      ]),
    ]),
  ]),
];
