import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
  Bathroom,
  Bedroom,
  CarSpace,
  DevelopmentSearchQuery,
  PropertyType,
  SearchFilter,
} from '@core/types';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  developmentParams: DevelopmentSearchQuery;

  defaultFilterValues = {
    all: 'apartments,townhouses,new-land-estates,penthouses,prestige-homes,villas',
    apartments: 'apartments',
    townhouses: 'townhouses',
    newLandEstates: 'new-land-estates',
    penthouses: 'penthouses',
    prestigeHomes: 'prestige-homes',
    villas: 'villas',
    oneBed: 1,
    twoBeds: 2,
    threeBeds: 3,
    fourBeds: 4,
    fivePlusBeds: 5,
    oneBath: 1,
    twoBath: 2,
    threeBath: 3,
    fourBath: 4,
    fivePlusBath: 5,
    oneSpace: 1,
    twoSpace: 2,
    threeSpace: 3,
    fourSpace: 4,
    fivePlusSpace: 5,
  };

  /**
   * Create query parameters from filters
   * @param filters
   * @returns DevelopmentSearchQuery
   */
  generateFilterParams(
    filters: FormGroup | SearchFilter
  ): DevelopmentSearchQuery {
    this.developmentParams = {};

    const development = filters instanceof FormGroup ? filters.value : filters;

    const propertyType = this.getFormattedParams(development.propertyType);
    const bedrooms = this.getFormattedParams(development.bedrooms);
    const bathrooms = this.getFormattedParams(development.bathrooms);
    const carSpaces = this.getFormattedParams(development.carSpaces);

    (this.developmentParams.classifications =
      propertyType !== ''
        ? propertyType
        : 'apartments,townhouses,new-land-estates,penthouses,prestiges-homes'),
      'villas';

    if (bedrooms !== '') {
      this.developmentParams.bedrooms = bedrooms;
    }

    if (bathrooms !== '') {
      this.developmentParams.bathrooms = bathrooms;
    }

    if (carSpaces !== '') {
      this.developmentParams.carSpaces = carSpaces;
    }

    if (development.price.min !== 'Any') {
      this.developmentParams.priceFrom = +development.price.min;
    }

    if (development.price.max !== 'Any') {
      this.developmentParams.priceTo = +development.price.max;
    }

    this.developmentParams.status = 'published';

    return this.developmentParams;
  }

  getFormattedParams(
    object: PropertyType | Bedroom | Bathroom | CarSpace
  ): string {
    const query = [];
    for (const property in object) {
      if (object[property]) {
        const value = this.defaultFilterValues[property];
        if (value) {
          query.push(value);
        }
      }
    }
    return query.length > 0 ? query.join(',') : '';
  }
}
