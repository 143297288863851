import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { environment } from 'environments/environment';
import { PaginationInstance } from 'ngx-pagination';

@Component({
  selector: 'apd-paginator-dark',
  templateUrl: './dark.component.html',
  styleUrls: ['./dark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorDarkComponent {
  @Input() config: PaginationInstance;
  @Output() pageChangeEventHandler: EventEmitter<number> =
    new EventEmitter<number>();

  cdnBasePath = environment.cdnBasePath;

  onPageChange(event: number): void {
    this.pageChangeEventHandler.emit(event);
  }
}
