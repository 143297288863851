import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

import { LocalStorageService } from '@core/services';
import { Enquirer, Lead } from '@core/types';
import { ApiResponse } from '@core/types/api-response.model';

import { Constants } from '@common/constants';

import { ApiService } from '../api.service';

@Injectable()
export class LeadService {
  allowedLanguages: Array<string> = Constants.allowedLanguages;
  defaultLeadType = 'General Enquiry';
  isBrowser: boolean;
  window: Window;

  /**
   * Constructor
   *
   * @param {ActivatedRoute} activatedRoute
   * @param {LocalStorageService} localStorageService
   * @param {ApiService} apiService
   */
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private apiService: ApiService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.window = this.document.defaultView;
    }
  }

  defaultLead: Lead = {
    firstName: '',
    lastName: '',
    name: '',
    developmentId: '',
    email: '',
    type: '',
    splash: '',
    enquireAbout: '',
    description: '',
    developmentTypeId: '',
    packageId: '',
    mobile: null,
    postCode: '',
    mobileCountryCode: '',
    preferredTime: '',
    bookingDate: '',
    message: '',
    audience: '',
    lookingToBuy: '',
    priceRange: '',
    appointmentTime: '',
    appointmentWeek: '',
    siteName: 'apd',
    isDevidAgent: false,
    meta: {},
    sessionId: '',
    otp: true,
    contactMethod: '',
    selectPreApproval: '',
  };

  lead: Lead;

  /**
   * Subscribe User
   *
   * @param string email
   * @returns {Observable<ApiResponse>}
   */
  sendEnquiry(
    enquirer: Enquirer,
    type?: string | null,
    trackTemplate = false
  ): Observable<ApiResponse> {
    this.mapParameters(enquirer, trackTemplate);

    if (type) {
      this.lead.type = type;
      this.lead.description = type;
    }

    return this.apiService
      .post('/leads', this.lead)
      .pipe(map(response => response));
  }

  mapParameters(enquirer: Enquirer, trackTemplate: boolean): void {
    this.lead = Object.assign({}, this.defaultLead);

    const utms = this.localStorageService.getUtms(),
      sessionId = this.localStorageService.getSessionId(),
      intersect = _.intersection(_.keys(this.lead), _.keys(enquirer)),
      development = enquirer.development,
      property = enquirer.property;

    this.lead.type = this.defaultLeadType;
    this.lead.description = this.defaultLeadType;
    this.lead.developmentId = development.id;
    this.lead.enquireAbout = development.classification;

    intersect.forEach(key => {
      if (enquirer[key]) {
        this.lead[key] = enquirer[key];
      }
    });

    if (utms) {
      delete utms.base64;
      delete utms.expiryDateTime;
      this.lead.meta = utms;
    }

    if (sessionId && typeof sessionId !== 'object') {
      this.lead.sessionId = sessionId.id ?? null;
    }

    if (development.status == 'archived' && development.discr != 'bci') {
      this.lead.type = 'Archived Enquiry';
      this.lead.description = 'Archived Enquiry';
    } else if (development.discr == 'bci') {
      this.lead.type = 'BCI Enquiry';
      this.lead.description = 'BCI Enquiry';
    } else if (property) {
      const propertyType = development.classification.substr(
          0,
          development.classification.length - 1
        ),
        isLandEstates = development.classification == 'New Land Estates',
        enquiryType = !isLandEstates
          ? propertyType + ' Enquiry'
          : 'Lot Enquiry';

      this.lead.type = enquiryType;
      this.lead.description = enquiryType;
      this.lead.developmentTypeId = property.id;
    } else {
      if (enquirer.brochure) {
        this.lead.type = 'Brochure Enquiry';
        this.lead.description = 'Brochure Enquiry';
        this.lead.enquireAbout = '';
      }

      if (enquirer.scheduleInspection) {
        this.lead.type = 'Display Suite Enquiry';
        this.lead.description = 'Display Suite Enquiry';
        this.lead.enquireAbout = 'Display suite';
      }
    }

    if (trackTemplate) {
      const trackTemplateSettings =
        this.localStorageService.getTrackTemplateSettings();

      if (trackTemplateSettings) {
        if (typeof this.lead.meta != 'undefined') {
          this.lead.meta = {
            ...this.lead.meta,
            ...{ template: trackTemplateSettings.templateToUse },
          };
        } else {
          this.lead.meta = {
            template: trackTemplateSettings.templateToUse,
          };
        }
      }
    }

    if (this.isBrowser) {
      this.setAdditionalMeta();
    }
  }

  setAdditionalMeta(): void {
    if (typeof this.lead.meta['referrer'] == 'undefined') {
      this.lead.meta = {
        ...this.lead.meta,
        referrer: this.window.location.href,
      };
    }
  }
}
