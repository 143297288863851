import { NgModule } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';

import { DefaultLeadFormsModule } from '@core/layout/components/lead-forms/default/default.module';

import { SharedModule } from '@shared/shared.module';

import { SmsDialogModule } from '../sms-dialog/sms-dialog.module';
import {
  LeadsCustomDialogComponent,
  LeadsDialogComponent,
} from './leads-dialog.component';

@NgModule({
  declarations: [LeadsCustomDialogComponent, LeadsDialogComponent],
  imports: [DefaultLeadFormsModule, SharedModule, SmsDialogModule],
  providers: [CookieService],
  exports: [LeadsCustomDialogComponent],
})
export class LeadsDialogModule {}
