import { Component, EventEmitter, Input, Output } from '@angular/core';

import { environment } from 'environments/environment';

import { Suburb } from '@core/types';

@Component({
  selector: 'apd-related-profiles',
  templateUrl: './related-profiles.component.html',
  styleUrls: ['./related-profiles.component.scss'],
})
export class RelatedProfilesComponent {
  @Input() header: string;
  @Input() buttonText: string;
  @Input() type: string;
  @Input() profiles: Array<Suburb>;
  @Input() noTrigger = false;
  @Output() setFilterLimit = new EventEmitter<void>();

  cdnBasePath = environment.cdnBasePath;

  viewable(): boolean {
    return typeof this.profiles != 'undefined' && this.profiles.length > 0;
  }
}
