<section>
  <ng-container *ngIf="suburb">
    <div class="about-grid container sh">
      <div class="left-column">
        <section class="about" *ngIf="suburb.suburbText">
          <div class="">
            <h2>
              About {{ suburb.title }} {{ suburb.state.state }}
              {{ suburb.postalNumber }}
            </h2>
          </div>
        </section>
        <ng-container *ngIf="suburb.suburbText">
          <section class="suburb-text">
            <div class="">
              <h5>{{ suburb.suburbText }}</h5>
            </div>
          </section>
        </ng-container>

        <ng-container *ngIf="suburb.content">
          <section class="suburb-content pt-20">
            <div
              class="layout--WYSIWYG"
              [ngClass]="{ 'article-closed': false }"
              [innerHTML]="suburb.content"></div>
          </section>
        </ng-container>

        <ng-container *ngIf="!suburb.content && suburb.profileText">
          <section class="suburb-content pt-20">
            <div
              class="layout--WYSIWYG"
              [ngClass]="{
                'article-closed':
                  !readMoreArticle && suburb.profileText.length > 450
              }"
              [innerHTML]="suburb.profileText"></div>
            <div
              *ngIf="suburb.profileText.length > 450"
              class="read-more"
              [ngClass]="{ 'read-more-gradiant': !readMoreArticle }">
              <button
                [class]="{ 'pt-20': readMoreArticle }"
                (click)="readMoreArticle = !readMoreArticle">
                {{ readMoreArticle ? 'Show less' : 'Read more' }}
              </button>
            </div>
          </section>
        </ng-container>
      </div>

      <ng-container
        class="right-column"
        *ngIf="suburb.suburbText; else altDemo">
        <section
          class="demographics"
          [class]="{
            'pt-sm-60': suburb.content || suburb.profileText
          }"
          *ngIf="suburb.houseHolds && suburb.profile.experian">
          <div class="stats">
            <div
              class="suburb-statistics text-center align-items-end col--md-1">
              <h5>
                Demographics of {{ suburb.title }}, {{ suburb.state.state }}
                {{ suburb.postalNumber }}
              </h5>
              <div class="left">
                <div class="households">
                  <span>{{ suburb.houseHolds | number }}</span>
                </div>
                <div class="label">
                  <span>{{ '_DEMOGRAPHICS.HOUSEHOLDS' | translate }}</span>
                </div>
              </div>

              <div class="right d-grid col--sm-1 align-items-start">
                <ng-container *ngIf="suburb.profile.experian">
                  <div
                    *ngFor="
                      let experian of suburb.profile.experian | slice: 0:3
                    "
                    class="col">
                    <div class="percentage">
                      <span>{{ experian.percent }}%</span>
                      <mat-progress-bar
                        color="accent"
                        [mode]="'determinate'"
                        [value]="experian.percent"></mat-progress-bar>
                    </div>
                    <div class="label">{{ experian.name }}</div>
                    <div class="description">{{ experian.description }}</div>
                  </div>
                </ng-container>
              </div>
              <div class="about">
                <small>
                  About this data - The statistics above have been sourced from
                  Mosaic demographic data that is the copyright property of
                  Experian Australia Pty Limited (or its licensors).
                </small>
              </div>
            </div>
          </div>
        </section>
      </ng-container>
      <ng-template #altDemo>
        <section
          class="demographics"
          [class]="{
            'pt-sm-60': suburb.content || suburb.profileText
          }"
          *ngIf="suburb.houseHolds && suburb.profile.experian">
          <div class="stats container sh">
            <div
              class="suburb-statistics text-center align-items-end col--md-1">
              <h5>
                Demographics of {{ suburb.title }}, {{ suburb.state.state }}
                {{ suburb.postalNumber }}
              </h5>
              <div class="left">
                <div class="households">
                  <span>{{ suburb.houseHolds | number }}</span>
                </div>
                <div class="label">
                  <span>{{ '_DEMOGRAPHICS.HOUSEHOLDS' | translate }}</span>
                </div>
              </div>

              <div class="right d-grid col--3 col--sm-1 align-items-start">
                <ng-container *ngIf="suburb.profile.experian">
                  <div
                    *ngFor="
                      let experian of suburb.profile.experian | slice: 0:3
                    "
                    class="col">
                    <div class="percentage">
                      <span>{{ experian.percent }}%</span>
                      <mat-progress-bar
                        color="accent"
                        [mode]="'determinate'"
                        [value]="experian.percent"></mat-progress-bar>
                    </div>
                    <div class="label">{{ experian.name }}</div>
                    <div class="description">{{ experian.description }}</div>
                  </div>
                </ng-container>
              </div>
              <div class="about">
                <small>
                  About this data - The statistics above have been sourced from
                  Mosaic demographic data that is the copyright property of
                  Experian Australia Pty Limited (or its licensors).
                </small>
              </div>
            </div>
          </div>
        </section>
      </ng-template>
    </div>
  </ng-container>
</section>
