/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

import { Observable } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthTokenHeaderInterceptor implements HttpInterceptor {
  constructor(
    //TODO: explicit-module-boundary-types
    //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Optional() @Inject(REQUEST) private httpRequest,
    private cookieService: CookieService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.httpRequest &&
      typeof this.httpRequest.headers.Authorization != 'undefined'
    ) {
      const authToken = this.httpRequest.headers.Authorization;

      request = request.clone({
        setHeaders: { Authorization: authToken },
        withCredentials: true,
      });
    }

    return next.handle(request);
  }
}
