/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

import { Observable } from 'rxjs';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {
  //TODO: explicit-module-boundary-types
  //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(@Optional() @Inject(REQUEST) private httpRequest) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.httpRequest &&
      (typeof this.httpRequest.headers.cookie != 'undefined' ||
        typeof this.httpRequest.cookie != 'undefined')
    ) {
      const cookies = this.getSSRCookie();

      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${cookies['apd_cookie']}`,
        },
        withCredentials: true,
      });
    }

    return next.handle(req);
  }

  private getSSRCookie(): string[] {
    const cookies = [];

    if (this.httpRequest.headers.cookie) {
      const cookieList = this.httpRequest.headers.cookie;

      cookieList.split(';').forEach(cookie => {
        const parts = cookie.split('=');
        const cookieName = parts[0].trim();
        const cookieValue = (parts[1] || '').trim();

        cookies[cookieName] = cookieValue;
      });
    }

    return cookies;
  }
}
