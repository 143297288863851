import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { environment } from 'environments/environment';

import { LanguageService } from '@core/services/language/language.service';
import { PageConfig } from '@core/types/buying-living/configs';

@Component({
  selector: 'apd-article-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class ArticleSearchResultsComponent implements OnInit, OnChanges {
  @Input() keyword: string;
  @Input() display: boolean;
  @Input() articles: PageConfig;
  @Output() setPageEventHandler = new EventEmitter<{ page: number }>();
  @Output() searchEventHandler = new EventEmitter<{ keyword: string }>();
  @Output() triggerSearchByTag = new EventEmitter<string>();

  cdnBasePath = environment.cdnBasePath;

  currentLang: string;
  categoryArticles: PageConfig;

  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.languageService.getCurrentLang();

    /**
     * Redirect to new page and show top contents
     */
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.categoryArticles = this.articles;
  }

  ngOnChanges(): void {
    if (this.articles) {
      this.categoryArticles = this.articles;
    }
  }

  onPageChange($event: number): void {
    this.setPageEventHandler.emit({ page: $event });
  }

  onSearch(event: { keyword: string }): void {
    this.searchEventHandler.emit(event);
  }

  searchByTag(tag: string): void {
    this.triggerSearchByTag.emit(tag);
  }
}
