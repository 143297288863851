<section
  *ngIf="articles['data'].length > 0 && display === true"
  class="pt-70 pt-sm-60">
  <div class="container lg">
    <h2 class="h1">{{ category }}</h2>

    <div class="latest-buying_living col--2-1 mt-40 mt-sm-20">
      <div class="col col--2">
        <ng-container *ngFor="let article of articles.data; index as i">
          <article *ngIf="i > 0 && i < 3">
            <a
              [routerLink]="
                currentLang +
                '/buying-living/' +
                article.category.slug +
                '/' +
                article.slug.name
              ">
              <picture class="position-relative d-block">
                <ng-container *ngIf="article.images.length > 0">
                  <img
                    loading="lazy"
                    [src]="article.images[0].url | optimizeImage"
                    [alt]="
                      article.images[0].altTag
                        ? article.images[0].altTag
                        : article.title
                    "
                    class="object-fit-cover object-position-center w-100 h-100" />
                </ng-container>
                <ng-container *ngIf="article.images.length == 0">
                  <img
                    loading="lazy"
                    [src]="
                      cdnBasePath + '/assets/default/default-project.jpg'
                        | optimizeImage
                    "
                    [alt]="article.title"
                    class="object-fit-cover object-position-center w-100 h-100" />
                </ng-container>
              </picture>
            </a>
            <div class="wrapper">
              <a
                [routerLink]="
                  currentLang + '/buying-living/' + article.category.slug
                "
                class="category d-inline-block">
                {{ category }}
              </a>
              <h4 class="title">
                <a
                  [routerLink]="
                    currentLang +
                    '/buying-living/' +
                    article.category.slug +
                    '/' +
                    article.slug.name
                  "
                  class="d-block">
                  {{ article.title }}
                </a>
              </h4>
              <div class="tags">
                <a
                  *ngFor="let tag of article.tags"
                  (click)="searchByTag(tag.slug)">
                  {{ tag.name }}
                </a>
              </div>
            </div>
          </article>
        </ng-container>
      </div>
      <div class="col">
        <ng-container *ngFor="let article of articles.data; index as i">
          <article *ngIf="i > 2 && i < 6">
            <a
              [routerLink]="
                currentLang +
                '/buying-living/' +
                article.category.slug +
                '/' +
                article.slug.name
              ">
              <picture class="position-relative d-block">
                <ng-container *ngIf="article.images.length > 0">
                  <img
                    loading="lazy"
                    [src]="article.images[0].url | optimizeImage"
                    [alt]="
                      article.images[0].altTag
                        ? article.images[0].altTag
                        : article.title
                    "
                    class="object-fit-cover object-position-center w-100 h-100" />
                </ng-container>
                <ng-container *ngIf="article.images.length == 0">
                  <img
                    loading="lazy"
                    [src]="
                      cdnBasePath + '/assets/default/default-project.jpg'
                        | optimizeImage
                    "
                    [alt]="article.title"
                    class="object-fit-cover object-position-center w-100 h-100" />
                </ng-container>
              </picture>
            </a>
            <div class="wrapper">
              <a
                [routerLink]="
                  currentLang + '/buying-living/' + article.category.slug
                "
                class="category d-inline-block">
                {{ category }}
              </a>
              <h4 class="title">
                <a
                  [routerLink]="
                    currentLang +
                    '/buying-living/' +
                    article.category.slug +
                    '/' +
                    article.slug.name
                  "
                  class="d-block">
                  {{ article.title }}
                </a>
              </h4>
              <div class="tags">
                <a *ngFor="let tag of article.tags" href="#">
                  {{ tag.name }}
                </a>
              </div>
            </div>
          </article>
        </ng-container>
      </div>
    </div>
  </div>
</section>
